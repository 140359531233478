import type { RouteConfigsTable } from "/#/index";

const lanmuRouter: RouteConfigsTable = {
  path: "/lanmu_root",
  meta: {
    title: "栏目",
    showLink: false
  },
  children: [
    {
      path: "/lanmu_root/:id",
      meta: {
        title: "栏目"
      },
      children: [
        {
          path: "/lanmu_root/:id/:child_id/:channel_id",
          name: "Lanmu_root",
          component: () => import("@/views/lanmu_root/index.vue"),
          meta: {
            title: "栏目列表",
            showParent: true,
            keepAlive: true
          }
        }
      ]
    }
  ]
};

export default lanmuRouter;
