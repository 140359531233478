import { loadEnv } from "@build/index";
const { VITE_APP_PAPER_IMAGE } = loadEnv();

const cnList = [
  // {
  //   id: 1,
  //   showName: "马善政毛笔楷体",
  //   fontFamily: "MaShanZheng-Regular",
  //   file: "MaShanZheng-Regular.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/1.png"
  // },
  // {
  //   id: 2,
  //   showName: "钟齐流江毛笔草体",
  //   fontFamily: "LiuJianMaoCao-Regular",
  //   file: "LiuJianMaoCao-Regular.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/2.png"
  // },
  // {
  //   id: 3,
  //   showName: "钟齐志莽行书",
  //   fontFamily: "ZhiMangXing-Regular",
  //   file: "ZhiMangXing-Regular.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/3.png"
  // },
  {
    id: 4,
    showName: "得意黑",
    fontFamily: "SmileySans-Oblique",
    file: "得意黑.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/4.png"
  },
  {
    id: 5,
    showName: "庞门正道标题体",
    fontFamily: "PangMenZhengDao",
    file: "庞门正道标题体.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/5.png"
  },
  {
    id: 6,
    showName: "站酷酷黑",
    fontFamily: "ZCOOL_KuHei",
    file: "站酷酷黑体.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/6.png"
  },
  {
    id: 7,
    showName: "站酷快乐体2016",
    fontFamily: "HappyZcool-2016",
    file: "站酷快乐体2016修订版.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/7.png"
  },
  {
    id: 9,
    showName: "站酷文艺体",
    fontFamily: "zcoolwenyiti",
    file: "站酷文艺体.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/9.png"
  },
  {
    id: 10,
    showName: "站酷小薇LOGO体",
    fontFamily: "xiaowei",
    file: "站酷小薇LOGO体.otf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/10.png"
  },
  {
    id: 11,
    showName: "字体传奇南安体",
    fontFamily: "ziticqnananti",
    file: "字体传奇南安体.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/11.png"
  },
  {
    id: 12,
    showName: "字体传奇特战体",
    fontFamily: "ziticqtezhanti",
    file: "字体传奇特战体.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/12.png"
  },
  {
    id: 13,
    showName: "字体传奇雪家黑",
    fontFamily: "ziticqxuejiahei",
    file: "字体传奇雪家黑.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/13.png"
  },
  {
    id: 58,
    showName: "优设标题体",
    fontFamily: "YouSheBiaoTiHei",
    file: "优设标题体.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/58.png"
  },
  {
    id: 14,
    showName: "思源黑体 纤细",
    fontFamily: "SourceHanSansCN-ExtraLight",
    file: "NotoSansSC-Light.otf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/14.png"
  },
  {
    id: 15,
    showName: "思源黑体 细体",
    fontFamily: "SourceHanSansCN-Light",
    file: "NotoSansSC-Thin.otf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/15.png"
  },
  {
    id: 16,
    showName: "思源黑体 常规",
    fontFamily: "SourceHanSansCN-Regular",
    file: "NotoSansSC-Regular.otf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/16.png"
  },
  {
    id: 17,
    showName: "思源黑体 中黑",
    fontFamily: "SourceHanSansCN-Bold",
    file: "NotoSansSC-Bold.otf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/17.png"
  },
  {
    id: 18,
    showName: "思源黑体 中粗",
    fontFamily: "SourceHanSansCN-Heavy",
    file: "NotoSansSC-Black.otf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/18.png"
  },
  {
    id: 19,
    showName: "思源宋体 纤细",
    fontFamily: "SourceHanSerifCN-Light",
    file: "NotoSerifSC-ExtraLight.otf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/19.png"
  },
  {
    id: 20,
    showName: "思源宋体 细体",
    fontFamily: "SourceHanSerifCN-Light",
    file: "NotoSerifSC-Light.otf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/20.png"
  },
  {
    id: 21,
    showName: "思源宋体 常规",
    fontFamily: "SourceHanSerifCN-Regular",
    file: "NotoSerifSC-Regular.otf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/21.png"
  },
  {
    id: 22,
    showName: "思源宋体 中黑",
    fontFamily: "SourceHanSerifCN-Bold",
    file: "NotoSerifSC-Medium.otf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/22.png"
  },
  {
    id: 23,
    showName: "思源宋体 中粗",
    fontFamily: "SourceHanSerifCN-Heavy",
    file: "NotoSerifSC-Bold.otf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/23.png"
  },
  {
    id: 24,
    showName: "Times New Roman 常规",
    fontFamily: "TimesNewRomanPSMT",
    file: "TimesNewRoman.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/24.png"
  },
  {
    id: 25,
    showName: "Times New Roman 斜体",
    fontFamily: "TimesNewRomanPS-ItalicMT",
    file: "TimesNewRomanItalic.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/25.png"
  },
  {
    id: 26,
    showName: "Times New Roman 粗体",
    fontFamily: "TimesNewRomanPS-BoldMT",
    file: "TimesNewRomanBold.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/26.png"
  },
  {
    id: 27,
    showName: "Times New Roman 粗斜体",
    fontFamily: "TimesNewRomanPS-BoldItalicMT",
    file: "TimesNewRomanBoldItalic.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/27.png"
  },
  {
    id: 28,
    showName: "阿里巴巴普惠体2.0 纤细",
    fontFamily: "AlibabaPuHuiTi_2_35_Thin",
    file: "阿里巴巴普惠体2.0_35_Thin.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/28.png"
  },
  {
    id: 29,
    showName: "阿里巴巴普惠体2.0 细体",
    fontFamily: "AlibabaPuHuiTi_2_45_Light",
    file: "阿里巴巴普惠体2.0_45_Light.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/29.png"
  },
  {
    id: 30,
    showName: "阿里巴巴普惠体2.0 常规",
    fontFamily: "AlibabaPuHuiTi_2_55_Regular",
    file: "阿里巴巴普惠体2.0_55_Regular.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/30.png"
  },
  {
    id: 31,
    showName: "阿里巴巴普惠体2.0 中黑",
    fontFamily: "AlibabaPuHuiTi_2_85_Bold",
    file: "阿里巴巴普惠体2.0_85_Bold.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/31.png"
  },
  {
    id: 32,
    showName: "阿里巴巴普惠体2.0 中粗",
    fontFamily: "AlibabaPuHuiTi_2_115_Black",
    file: "阿里巴巴普惠体2.0_115_Black.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/32.png"
  },
  /*{
   id: 33,
   showName: "阿里妈妈方圆体 纤细",
   fontFamily: "AlimamaFangYuanTiVF-Thin",
   file: "阿里妈妈方圆体.ttf",
   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/33.png"
   },
   {
   id: 34,
   showName: "阿里妈妈方圆体 细体",
   fontFamily: "AlimamaFangYuanTiVF-Light",
   file: "阿里妈妈方圆体.ttf",
   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/34.png"
   },
   {
   id: 35,
   showName: "阿里妈妈方圆体 常规",
   fontFamily: "AlimamaFangYuanTiVF-Regular",
   file: "阿里妈妈方圆体.ttf",
   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/35.png"
   },
   {
   id: 36,
   showName: "阿里妈妈方圆体 中黑",
   fontFamily: "AlimamaFangYuanTiVF-SemiBold",
   file: "阿里妈妈方圆体.ttf",
   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/36.png"
   },
   {
   id: 37,
   showName: "阿里妈妈方圆体 中粗",
   fontFamily: "AlimamaFangYuanTiVF-Bold",
   file: "阿里妈妈方圆体.ttf",
   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/37.png"
   },*/
  {
    id: 38,
    showName: "阿里妈妈刀隶体",
    fontFamily: "AlimamaDaoLiTi",
    file: "阿里妈妈刀隶体.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/38.png"
  },
  {
    id: 39,
    showName: "阿里妈妈数黑体",
    fontFamily: "AlimamaShuHeiTi-Bold",
    file: "阿里妈妈数黑体.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/39.png"
  },
  {
    id: 40,
    showName: "阿里妈妈东方大楷",
    fontFamily: "AlimamaDongFangDaKai-Regular",
    file: "阿里妈妈东方大楷.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/40.png"
  },
  {
    id: 41,
    showName: "仓耳舒圆体 纤细",
    fontFamily: "TsangerShuYuanT-W01",
    file: "仓耳舒圆体W01.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/41.png"
  },
  {
    id: 42,
    showName: "仓耳舒圆体 细体",
    fontFamily: "TsangerShuYuanT-W02",
    file: "仓耳舒圆体W02.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/42.png"
  },
  {
    id: 43,
    showName: "仓耳舒圆体 常规",
    fontFamily: "TsangerShuYuanT-W03",
    file: "仓耳舒圆体W03.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/43.png"
  },
  {
    id: 44,
    showName: "仓耳舒圆体 中黑",
    fontFamily: "TsangerShuYuanT-W04",
    file: "仓耳舒圆体W04.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/44.png"
  },
  {
    id: 45,
    showName: "仓耳舒圆体 中粗",
    fontFamily: "TsangerShuYuanT-W05",
    file: "仓耳舒圆体W05.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/45.png"
  },
  {
    id: 46,
    showName: "仓耳渔阳体 纤细",
    fontFamily: "TsangerYuYangT-W01",
    file: "仓耳渔阳体W01.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/46.png"
  },
  {
    id: 47,
    showName: "仓耳渔阳体 细体",
    fontFamily: "TsangerYuYangT-W02",
    file: "仓耳渔阳体W02.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/47.png"
  },
  {
    id: 48,
    showName: "仓耳渔阳体 常规",
    fontFamily: "TsangerYuYangT-W03",
    file: "仓耳渔阳体W03.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/48.png"
  },
  {
    id: 49,
    showName: "仓耳渔阳体 中黑",
    fontFamily: "TsangerYuYangT-W04",
    file: "仓耳渔阳体W04.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/49.png"
  },
  {
    id: 50,
    showName: "仓耳渔阳体 中粗",
    fontFamily: "TsangerYuYangT-W05",
    file: "仓耳渔阳体W05.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/50.png"
  },
  {
    id: 51,
    showName: "仓耳与墨 纤细",
    fontFamily: "TsangerYuMo-W01",
    file: "仓耳与墨W01.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/51.png"
  },
  {
    id: 52,
    showName: "仓耳与墨 细体",
    fontFamily: "TsangerYuMo-W02",
    file: "仓耳与墨W02.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/52.png"
  },
  {
    id: 53,
    showName: "仓耳与墨 常规",
    fontFamily: "TsangerYuMo-W03",
    file: "仓耳与墨W03.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/53.png"
  },
  {
    id: 54,
    showName: "仓耳与墨 中黑",
    fontFamily: "TsangerYuMo-W04",
    file: "仓耳与墨W04.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/54.png"
  },
  {
    id: 55,
    showName: "仓耳与墨 中粗",
    fontFamily: "TsangerYuMo-W05",
    file: "仓耳与墨W05.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/55.png"
  },
  {
    id: 56,
    showName: "仓耳小丸子",
    fontFamily: "TsangerXWZ",
    file: "仓耳小丸子.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/56.png"
  },
  {
    id: 57,
    showName: "仓耳周珂正大榜书",
    fontFamily: "TsangerZhoukeZhengdabangshu",
    file: "仓耳周珂正大榜书.ttf",
    url: VITE_APP_PAPER_IMAGE + "/cnstock/static/aiposter/font/img/57.png"
  }
  // {
  //   id: 59,
  //   showName: "方正标雅宋",
  //   fontFamily: "FZBIAOYSJW--GB1-0",
  //   file: "方正标雅宋.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/59.png"
  // },
  // {
  //   id: 60,
  //   showName: "方正粗雅宋",
  //   fontFamily: "FZCYSJW--GB1-0",
  //   file: "方正粗雅宋.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/60.png"
  // },
  // {
  //   id: 61,
  //   showName: "方正粗金陵简体",
  //   fontFamily: "FZCUJINLJW--GB1-0",
  //   file: "方正粗雅宋.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/61.png"
  // },
  // {
  //   id: 62,
  //   showName: "DIN",
  //   fontFamily: "DINAlternate-Bold",
  //   file: "DINAlternateBold.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/62.png"
  // },
  // {
  //   id: 68,
  //   showName: "方正汉真广标简体",
  //   fontFamily: "FZHZGBJW--GB1-0",
  //   file: "FZHZGBJW--GB1-0.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/68.png"
  // },
  // {
  //   id: 63,
  //   showName: "方正兰亭黑 纤细",
  //   fontFamily: "FZLTCXHJW--GB1-0",
  //   file: "FZLTCXHJW--GB1-0.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/63.png"
  // },
  // {
  //   id: 64,
  //   showName: "方正兰亭黑 细体",
  //   fontFamily: "FZLTXHJW--GB1-0",
  //   file: "FZLTXHJW--GB1-0.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/64.png"
  // },
  // {
  //   id: 65,
  //   showName: "方正兰亭黑 常规",
  //   fontFamily: "FZLTHJW--GB1-0",
  //   file: "FZLTHJW--GB1-0.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/65.png"
  // },
  // {
  //   id: 66,
  //   showName: "方正兰亭黑 中黑",
  //   fontFamily: "FZLTZHJW--GB1-0",
  //   file: "FZLTZHJW--GB1-0.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/66.png"
  // },
  // {
  //   id: 67,
  //   showName: "方正兰亭黑 特黑",
  //   fontFamily: "FZLTTHJW--GB1-0",
  //   file: "FZLTTHJW--GB1-0.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/67.png"
  // },
  // {
  //   id: 70,
  //   showName: "方正字迹-典雅楷体",
  //   fontFamily: "FZZJ-DYKTJW",
  //   file: "FZZJ-DYKTJW.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/70.png"
  // },
  // {
  //   id: 80,
  //   showName: "方正清刻本悦宋简体",
  //   fontFamily: "FZQINGKBYSS-B--GB1-0",
  //   file: "FZQINGKBYSS-B--GB1-0.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/80.png"
  // },
  // {
  //   id: 90,
  //   showName: "方正粗宋简",
  //   fontFamily: "FZCSJW--GB1-0",
  //   file: "FZCSJW--GB1-0.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/90.png"
  // },
  // {
  //   id: 100,
  //   showName: "方正小标宋简体",
  //   fontFamily: "FZXBSJW--GB1-0",
  //   file: "FZXBSJW--GB1-0.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/100.png"
  // },
  // {
  //   id: 110,
  //   showName: "方正粉丝天下简体",
  //   fontFamily: "FZFENSTXJW--GB1-0",
  //   file: "FZFENSTXJW--GB1-0.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/110.png"
  // },
  // {
  //   id: 120,
  //   showName: "方正趣宋",
  //   fontFamily: "FZQUSJW--GB1-0",
  //   file: "FZQUSJW--GB1-0.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/120.png"
  // },
  // {
  //   id: 130,
  //   showName: "方正新综艺黑简",
  //   fontFamily: "FZXINZYHS-H--GB1-0",
  //   file: "FZXINZYHS-H--GB1-0.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/130.png"
  // },
  // {
  //   id: 140,
  //   showName: "汉仪程行简",
  //   fontFamily: "HYChengXingJ",
  //   file: "HYChengXingJ.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/140.png"
  // },
  // {
  //   id: 150,
  //   showName: "汉仪雅酷黑 纤细",
  //   fontFamily: "HYYakuHei-DEW",
  //   file: "HYYakuHei-DEW.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/150.png"
  // },
  // {
  //   id: 160,
  //   showName: "汉仪雅酷黑 细体",
  //   fontFamily: "HYYakuHei-EEW",
  //   file: "HYYakuHei-EEW.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/160.png"
  // },
  // {
  //   id: 170,
  //   showName: "汉仪雅酷黑 常规",
  //   fontFamily: "HYYakuHei-FEW",
  //   file: "HYYakuHei-FEW.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/170.png"
  // },
  // {
  //   id: 180,
  //   showName: "汉仪雅酷黑 中黑",
  //   fontFamily: "HYYakuHei-HEW",
  //   file: "HYYakuHei-HEW.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/180.png"
  // },
  // {
  //   id: 190,
  //   showName: "汉仪雅酷黑 特黑",
  //   fontFamily: "HYYakuHei-IEW",
  //   file: "HYYakuHei-IEW.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/190.png"
  // },
  // {
  //   id: 200,
  //   showName: "尔雅新大黑 纤细",
  //   fontFamily: "eryaxindahei-Thin",
  //   file: "eryaxindahei-Thin.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/200.png"
  // },
  // {
  //   id: 210,
  //   showName: "尔雅新大黑 细体",
  //   fontFamily: "eryaxindahei-Light",
  //   file: "eryaxindahei-Light.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/210.png"
  // },
  // {
  //   id: 220,
  //   showName: "尔雅新大黑 常规",
  //   fontFamily: "eryaxindahei-Medium",
  //   file: "eryaxindahei-Medium.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/220.png"
  // },
  // {
  //   id: 230,
  //   showName: "尔雅新大黑 中黑",
  //   fontFamily: "eryaxindahei-Bold",
  //   file: "eryaxindahei-Bold.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/230.png"
  // },
  // {
  //   id: 240,
  //   showName: "尔雅新大黑 特黑",
  //   fontFamily: "eryaxindahei-ExtraBold",
  //   file: "eryaxindahei-ExtraBold.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/240.png"
  // },
  // {
  //   id: 250,
  //   showName: "方正宋黑",
  //   fontFamily: "FZSHJW--GB1-0",
  //   file: "FZSHJW--GB1-0.ttf",
  //   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/250.png"
  // }
  /*{
   id: 260,
   showName: "彩虹小标宋",
   fontFamily: "CHXBS",
   file: "CHXBS.ttf",
   url: VITE_APP_PAPER_IMAGE +"/cnstock/static/aiposter/font/img/260.png"
   }*/
];

const enList = [];
/*
 const cssText = cnList.map(item => {
 const type = item.file.split(".");
 return `@font-face {
 font-family: "${item.fontFamily}";
 src: url("/cnstock/static/aiposter/font/${
 item.fontFamily
 }.${type[type.length - 1]}");
 }`;
 });
 console.log(cssText.join(" "));*/

/*const cssText = new111.map((item) => {
 return {
 ...item,
 url: `/cnstock/static/aiposter/font/img/${
 item.id
 }.png`
 };
 });*/

export default [
  ...cnList.map((item, index) => {
    return {
      ...item,
      id: index + 1
    };
  }),
  ...enList
];
