import type { RouteConfigsTable } from "/#/index";

const interviewRouter: RouteConfigsTable = {
  path: "/final_root",
  name: "Final_root",
  component: () => import("@/views/final_root/index.vue"),
  meta: {
    rank: 1,
    title: "成品库",
    showLink: false,
    keepAlive: true
  }
};

export default interviewRouter;
