<template>
  <div>
    <el-dialog
      class="imgupload-dialog"
      v-if="dialogVisible"
      top="7vh"
      v-model="dialogVisible"
      :title="
        modelType === 0 ? '图片选择' : modelType === 1 ? '视频选择' : '音频选择'
      "
      width="900px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      destroy-on-close
      append-to-body
      modal-class="modal_style"
      :append-to="curAppendTo ? `#${curAppendTo}` : undefined"
    >
      <div>
        <!-- 遮照进度条 -->
        <el-dialog
          v-model="maskVisible"
          :title="null"
          width="900px"
          :show-close="false"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          modal-class="modal_style"
          :append-to="curAppendTo ? `#${curAppendTo}` : undefined"
        >
          <div class="imgupload-mask">
            <img src="./img/icon_loading_active.png" />
            <p>
              {{ modelType === 0 ? "图片上传中" : "音频上传中" }}（{{
                willUploadList.length
              }}
              / {{ uploadImgLength }}）...
            </p>
          </div>
        </el-dialog>

        <!-- 搜索区域 -->
        <div class="imgupload-search">
          <div class="imgupload-flex">
            <el-select
              v-model="searchParams.orderType"
              placeholder="请选择"
              style="width: 100px"
              :teleported="false"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <el-input
              style="margin-left: 10px; width: 300px"
              v-model="searchParams.searchKey"
              placeholder="请输入关键词搜索"
              @keyup.enter="handleEnter"
              clearable
            >
              <template #suffix>
                <el-icon class="imgupload-icon-search" @click="handleEnter"
                  ><Search
                /></el-icon>
              </template>
            </el-input>
          </div>

          <!-- 上传按钮 -->
          <MaterialUpload
            :multiple="true"
            :modelType="modelType"
            @change="onMaterialUpload"
            @getLength="getLength"
            :limit="num - checkList.length"
            v-if="modelType === 0 || modelType === 2"
          />

          <div v-if="modelType === 1">
            <el-dropdown>
              <el-button type="primary">
                本地上传
                <el-icon class="el-icon--right"><arrow-down /></el-icon>
              </el-button>

              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :disabled="checkList.length === num">
                    <el-upload
                      action=""
                      accept=".mp4,.mov,.avi,.wmv"
                      :auto-upload="false"
                      :on-change="fileChange"
                      :show-file-list="false"
                    >
                      本地上传至个人临时库
                    </el-upload>
                  </el-dropdown-item>

                  <el-dropdown-item :disabled="checkList.length === num">
                    <el-upload
                      action=""
                      accept=".mp4,.mov,.avi,.wmv"
                      :auto-upload="false"
                      :on-change="fileChange1"
                      :show-file-list="false"
                    >
                      本地上传至个人库
                    </el-upload>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>

      <!-- tab -->
      <div class="imgupload-tabs">
        <el-tabs v-model="searchParams.scope" @tab-change="handleChangeTabs">
          <el-tab-pane label="文章库" name="content" v-if="needLibrary">
            <Waterfalllist
              v-if="searchParams.scope === 'content'"
              @handleSetCheckList="handleSetCheckList"
              :checkList="checkList"
              :modelType="modelType"
              :num="num"
              :videoNum="videoNum"
              :searchParams="searchChildParams"
              fallId="content"
            />
          </el-tab-pane>
          <el-tab-pane label="媒资企业库" name="team">
            <Waterfalllist
              v-if="searchParams.scope === 'team'"
              @handleSetCheckList="handleSetCheckList"
              :checkList="checkList"
              :modelType="modelType"
              :num="num"
              :videoNum="videoNum"
              :searchParams="searchChildParams"
              fallId="team"
            />
          </el-tab-pane>
          <el-tab-pane label="媒资个人库" name="self">
            <Waterfalllist
              v-if="searchParams.scope === 'self'"
              ref="selfRef"
              @handleSetCheckList="handleSetCheckList"
              :checkList="checkList"
              :modelType="modelType"
              :num="num"
              :videoNum="videoNum"
              :searchParams="searchChildParams"
              fallId="self"
              :parentId="parentId"
              :parentFileName="parentFileName"
              :isLoad="isLoad"
            />
          </el-tab-pane>
        </el-tabs>
        <!-- 选中的图片显示区域 -->
        <div class="imgupload-checkedImg" v-if="checkList.length">
          <el-scrollbar>
            <div class="imgupload-checked-imageslist">
              <div
                class="imgupload-checked-imagesitem"
                v-for="(item, index) in checkList"
                :key="item.id"
              >
                <span class="imgupload-delete-img" @click="hanldeDelete(item)"
                  ><img src="./img/icon_close.png"
                /></span>
                <img v-if="modelType !== 2" :src="item.coverUrl" />
                <div class="imgupload-checked-audio" v-else>
                  <img src="./img/icon_music.png" />
                </div>
                <span
                  v-if="modelType === 0"
                  class="imgupload-cropper"
                  @click="handleCropper(item.coverUrl, index)"
                  >裁切图片</span
                >
                <span
                  class="imgupload-video"
                  v-if="modelType === 1 && item?.duration"
                >
                  {{ item?.duration }}
                </span>
              </div>
            </div>
          </el-scrollbar>

          <el-button
            class="imgupload-oksumit"
            type="primary"
            @click="hanldeChoose"
            >确定({{ checkList.length }} / {{ num }})</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 视频相关弹窗 -->
    <VideoUpload
      v-model:visible="videoVisible"
      :videoFile="videoFile"
      :isTemporary="isTemporary"
      :nodeId="nodeId"
      :appendTo="curAppendTo"
      :aspectRatio="aspectRatio"
      @handleOk="handleSubmitVideo"
    />

    <!-- 裁剪 -->
    <Cropper
      v-model:visible="cropperVisible"
      :cropperUrl="cropperUrl"
      @singleSuccess="singleSuccess"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, nextTick } from "vue";

import VideoUpload from "../VideoUpload";

import Cropper from "./com/Cropper";

import Waterfalllist from "./com/WaterfallList";

import MaterialUpload from "./com/MaterialUpload";

import { Search } from "@element-plus/icons-vue";

import { ElMessage } from "element-plus";

import { postResource, getUploadStatus } from "@/api/upload";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  modelType: {
    type: [Number, String]
  },
  keyWord: {
    type: String,
    default: ""
  },
  num: {
    type: Number,
    default: 10
  },
  videoNum: {
    type: Number,
    default: 0
  },
  nodeId: {
    type: [Number, String]
  },
  contId: {
    type: [Number, String]
  },
  aspectRatio: {
    type: Number,
    default: NaN
  },
  needLibrary: {
    type: Boolean,
    default: false
  },
  appendTo: {
    type: String,
    default: undefined
  }
});
const dialogVisible = ref(false);
const curAppendTo = ref("");

const emits = defineEmits(["update:visible", "handleOk"]);

const selfRef = ref(null);

//  下拉选择
const options = ref([
  {
    value: 1,
    label: "按相关"
  },
  {
    value: 2,
    label: "按最新"
  }
]);

watch(
  () => props.appendTo,
  newVal => {
    if (newVal) {
      setTimeout(() => {
        curAppendTo.value = newVal;
      }, 1);
    }
  },
  { immediate: true }
);

const searchParams = ref({
  orderType: 1,
  searchKey: "",
  mediaType: props.modelType === 0 ? 1 : props.modelType === 1 ? 2 : 3,
  scope: props.needLibrary ? "content" : "team",
  hiddenSearchKey: props.keyWord
});

const searchChildParams = ref({});

const checkList = ref([]);

const videoVisible = ref(false);

const videoFile = ref(null);

//  裁剪图片
const cropperVisible = ref(false);
const cropperUrl = ref("");
const cropperIndex = ref(null);

//  上传状态
const maskVisible = ref(false);
const willUploadList = ref([]);
const uploadImgLength = ref(0);

let loopStatus = null;

const isTemporary = ref(false);
const parentId = ref(null);
const parentFileName = ref(null);
const isLoad = ref(false);

const handleClose = () => {
  emits("update:visible", false);
  searchParams.value = {
    orderType: 1,
    searchKey: "",
    mediaType: props.modelType === 0 ? 1 : props.modelType === 1 ? 2 : 3,
    // scope: props.modelType === 0 ? "all" : "team",
    scope: props.needLibrary ? "content" : "team",
    hiddenSearchKey: props.keyWord,
    contId: null
  };
  searchChildParams.value = {
    orderType: 1,
    searchKey: "",
    mediaType: props.modelType === 0 ? 1 : props.modelType === 1 ? 2 : 3,
    // scope: props.modelType === 0 ? "all" : "team",
    scope: props.needLibrary ? "content" : "team",
    hiddenSearchKey: props.keyWord,
    contId: null
  };

  checkList.value.map(item => {
    item.checked = false;
  });
  checkList.value = [];
  parentId.value = "";
  parentFileName.value = "";
};

const singleSuccess = imgUrl => {
  let originSrc = checkList.value[cropperIndex.value].originSrc;
  if (imgUrl.ossUrl) {
    checkList.value.splice(cropperIndex.value, 1, {
      originSrc,
      coverUrl: imgUrl.ossUrl
    });
  }
  nextTick(() => {
    console.log(checkList.value, "pic");
  });
};

const handleSetCheckList = item => {
  checkList.value = item;
};

//  视频上传相关

const fileChange = async (file: any) => {
  if (file.raw.size / 1024 / 1024 > 2500) {
    return ElMessage.warning(`文件不能大于2500M`);
  }
  let nameList = ["mp4", "avi", "mp4", "wmv", "mov"];
  let name = file.name.split(".");
  let lastName = name[name.length - 1].toLowerCase();

  if (nameList.indexOf(lastName) === -1) {
    ElMessage.error("视频可上传pm4格式文件，大小不超过2500M");
    return;
  }
  isTemporary.value = true;
  videoFile.value = file;
  videoVisible.value = true;
};

const fileChange1 = async (file: any) => {
  if (file.raw.size / 1024 / 1024 > 2500) {
    return ElMessage.warning(`文件不能大于2500M`);
  }
  let nameList = ["mp4", "avi", "mp4", "wmv", "mov"];
  let name = file.name.split(".");
  let lastName = name[name.length - 1].toLowerCase();

  if (nameList.indexOf(lastName) === -1) {
    ElMessage.error("视频可上传pm4格式文件，大小不超过2500M");
    return;
  }
  isTemporary.value = false;
  videoFile.value = file;
  videoVisible.value = true;
};

const handleSubmitVideo = params => {
  videoVisible.value = false;
  // emits("handleOk", checkList.value, props.modelType);
  checkList.value = [
    ...checkList.value,
    {
      ...params,
      fileType: 2
    }
  ];
  console.log(params, "🌷🌷🌷🌷🌷🌷", checkList.value);
  isLoad.value = false;
  parentId.value = params.parentId;
  parentFileName.value = params.parentFileName;
  handleChangeTabs("self");
  // selfRef.value.setNewFileToFirst({ ...params, fileType: 2 });
  console.log(params);
};

//  删除选中的图片
const hanldeDelete = item => {
  //  反向修改选中的字段的值，否则在删除的时候这个值没有更新，所以这里手动更新取消选中
  item.checked = false;
  let index = checkList.value.findIndex(check => check.id === item.id);
  checkList.value.splice(index, 1);
};

const handleCropper = (url, index) => {
  cropperUrl.value = url;
  cropperIndex.value = index;
  nextTick(() => {
    cropperVisible.value = true;
  });
};

const handleEnter = () => {
  if (!searchParams.value.searchKey.trim()) return;
  searchChildParams.value = {
    ...JSON.parse(JSON.stringify(searchParams.value)),
    pageNum: 1,
    pageSize: 10
  };
};

const handleChangeTabs = name => {
  console.log(name, "🍓🍇");
  if (parentId.value) {
    isLoad.value = true;
  }
  // delete searchChildParams.value.pageNum;
  searchParams.value.scope = name;
  nextTick(() => {
    // searchChildParams.value.scope = name;
    searchChildParams.value = JSON.parse(JSON.stringify(searchParams.value));
  });
};

const getLength = length => {
  maskVisible.value = true;
  uploadImgLength.value = length;
};

const onMaterialUpload = item => {
  isLoad.value = false;
  console.log(item, "item");
  postResource({
    coverUrl: "",
    height: 0,
    size: 0,
    title: "",
    type: props.modelType === 0 ? "IMAGE" : "AUDIO",
    url: item.ossUrl,
    fileName: item.fileName,
    isTemporary: item.isTemporary,
    width: 0
  }).then((data: any) => {
    //  音频文件需要轮训查找是否转码完成
    console.log(data.data, "🐻🐻🐻🐻🐻", props.modelType);

    if (props.modelType === 2 && data.data.originSrc.indexOf(".mp3") === -1) {
      maskVisible.value = true;
      loopVideoStatus(data, item);
      loopStatus = setInterval(() => {
        loopVideoStatus(data, item);
      }, 3000);
    } else {
      willUploadList.value = [...willUploadList.value, item];
      // selfRef.value.setNewFileToFirst({ ...data.data, fileType: 2 });
      //  这里是模拟后端返回之后选中
      checkList.value = [...checkList.value, { ...data.data, fileType: 2 }];
      nextTick(() => {
        if (willUploadList.value.length === uploadImgLength.value) {
          maskVisible.value = false;
          nextTick(() => {
            willUploadList.value = [];
            uploadImgLength.value = 0;
          });
        }
      });
      // isLoad.value = false;
      parentId.value = data.data.parentId;
      parentFileName.value = data.data.parentFileName;
      handleChangeTabs("self");
    }
  });
};

const loopVideoStatus = (data, item) => {
  getUploadStatus({
    mediaId: data.data.id
  }).then(result => {
    console.log(result.data, "😭😭😭", data.data);
    if (result.data.finish) {
      clearInterval(loopStatus);
      maskVisible.value = false;
      willUploadList.value = [...willUploadList.value, item];
      // selfRef.value.setNewFileToFirst({ ...data.data, fileType: 2 });
      //  这里是模拟后端返回之后选中
      checkList.value = [
        ...checkList.value,
        {
          ...data.data,
          fileType: 2,
          duration: result.data.mediaDetail.media.duration
        }
      ];
      nextTick(() => {
        if (willUploadList.value.length === uploadImgLength.value) {
          maskVisible.value = false;
          nextTick(() => {
            willUploadList.value = [];
            uploadImgLength.value = 0;
          });
        }
      });
      // isLoad.value = false;
      parentId.value = result.data.parentId;
      parentFileName.value = result.data.parentFileName;
      handleChangeTabs("self");
    }
  });
};

const hanldeChoose = () => {
  console.log(checkList.value, "🐟🐟🐟");

  emits("handleOk", checkList.value, props.modelType);
  handleClose();
};

watch(
  () => props.visible,
  newVal => {
    // console.log(props.modelType, "🐟🐟🐟", newVal);
    dialogVisible.value = newVal;
    searchParams.value.hiddenSearchKey = props.keyWord;
    searchParams.value.mediaType =
      props.modelType === 0 ? 1 : props.modelType === 1 ? 2 : 3;
    // ,
    // (searchParams.value.scope = props.modelType ? "team" : "self");
    searchChildParams.value.hiddenSearchKey = props.keyWord;
    searchChildParams.value.mediaType =
      props.modelType === 0 ? 1 : props.modelType === 1 ? 2 : 3;
    // ,
    if (props.needLibrary) {
      searchParams.value.scope = "content";
      searchChildParams.value.scope = "content";
      searchParams.value.contId = props.contId;
      searchChildParams.value.contId = props.contId;
      console.log(searchParams.value, "🐷🐷🐷");
      // searchParams.scope
    } else {
      searchChildParams.value.scope = "team";
    }
  }
);

watch(
  () => props.modelType,
  newVal => {
    if (newVal === 1) {
      // searchParams.value.scope = "team";
      searchParams.value.mediaType = 2;
    } else if (newVal === 0) {
      searchParams.value.mediaType = 1;
      // searchParams.value.scope = "self";
    } else {
      // searchParams.value.scope = "team";
      searchParams.value.mediaType = 3;
    }
  }
);
</script>

<style scoped lang="scss">
.img_list {
  display: flex;
  flex-wrap: wrap;
  max-height: 580px;
  overflow: auto;
}

.imgupload-oksumit {
  margin-left: 20px;
}

.imglist_item {
  width: 230px;
  max-height: 230px;
  border: 1px solid #ccc;
  padding: 10px 0;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.search_col {
  display: inline-flex;
  width: calc(100% - 108px);
  align-items: center;
  justify-content: right;
}

.preview {
  cursor: pointer;
}

.imgupload-flex {
  display: flex;
}

.imgupload-search {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 0 20px;
}

.imgupload-tabs {
  margin-top: 14px;

  :deep(.el-tabs__nav) {
    margin-left: 20px;
  }

  :deep(.el-tabs__item) {
    color: #333;

    &.is-active {
      color: #409eff;
    }
  }

  :deep(.el-tabs__header) {
    padding: 0;
    position: relative;
    margin: 0 0 15px !important;
  }

  :deep(.el-tabs__nav-scroll) {
    display: block !important;
  }
}

.imgupload-icon-search {
  cursor: pointer;
}

:deep(.imgupload-dialog) {
  z-index: 999;

  .el-dialog__body {
    padding: 0 !important;
    // margin-top: 8vh !important;
  }

  .el-dialog__header {
    padding: 20px !important;
    padding-bottom: 10px !important;
    margin-right: 16px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.imgupload-video {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 0 3px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 1px;
  font-size: 12px;
}

.imgupload-checked-imageslist {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-top: 25px;

  .imgupload-checked-imagesitem {
    position: relative;
    margin-right: 10px;
    width: 100px;
    flex-shrink: 0;

    img {
      background: #000;
      width: 100px;
      height: 100px;
      object-fit: contain;
      border-radius: 2px;
    }

    .imgupload-checked-audio {
      background: #f8f8f8;
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        background: none;
        width: 56px;
        height: 56px;
      }
    }

    span {
      display: none;
      position: absolute;

      &.imgupload-delete-img {
        right: 5px;
        top: 5px;

        img {
          width: 24px;
          height: 24px;
          background: none;
          cursor: pointer;
        }
      }

      &.imgupload-cropper {
        color: #fff;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        padding: 5px 0;
      }
    }

    &:hover {
      span {
        display: block;
      }
    }
  }
}

.imgupload-checkedImg {
  padding: 0 20px;
  background: #fff;
  border-top: 1px solid #f0f0f0;
  height: 154px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    border-radius: 2px;
  }
}

.imgupload-mask {
  height: 654px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 36px;
    height: 36px;
    animation: spin 3s linear infinite;
    margin-bottom: 15px;
  }
}
</style>
