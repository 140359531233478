import type { CSSProperties } from "vue";
import {
  defineComponent,
  onMounted,
  nextTick,
  ref,
  unref,
  computed,
  PropType,
  watch
} from "vue";
import { templateRef } from "@vueuse/core";
import { useAttrs } from "@pureadmin/utils";

import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

type Options = Cropper.Options;

const defaultOptions: Cropper.Options = {
  preview: ".preview0",
  aspectRatio: 1,
  zoomable: true,
  zoomOnTouch: true,
  zoomOnWheel: true,
  cropBoxMovable: true,
  cropBoxResizable: true,
  toggleDragModeOnDblclick: true,
  autoCrop: true,
  background: true,
  highlight: true,
  center: true,
  responsive: true,
  restore: true,
  checkCrossOrigin: true,
  checkOrientation: true,
  scalable: true,
  modal: true,
  guides: true,
  movable: true,
  rotatable: true,
  viewMode: 1,
  autoCropArea: 1
};

const props = {
  src: {
    type: String,
    required: true
  },
  alt: {
    type: String
  },
  width: {
    type: [String, Number],
    default: ""
  },
  height: {
    type: [String, Number],
    default: "360px"
  },
  crossorigin: {
    type: String || Object,
    default: undefined
  },
  imageStyle: {
    type: Object as PropType<CSSProperties>,
    default() {
      return {};
    }
  },
  options: {
    type: Object as PropType<Options>,
    default() {
      return {};
    }
  },
  getData: {
    type: Function
  },
  aspectRatio: {
    type: Number || Object || String,
    default: 1
  }
};

export default defineComponent({
  name: "ReCropper",
  props,
  setup(props) {
    const cropper: any = ref<Nullable<Cropper>>(null);
    const imgElRef = templateRef<HTMLImageElement | null>("imgElRef", null);

    const isReady = ref<boolean>(false);

    const getImageStyle = computed((): CSSProperties => {
      return {
        height: props.height,
        width: props.width,
        maxWidth: "100%",
        ...props.imageStyle
      };
    });

    const getWrapperStyle = computed((): CSSProperties => {
      const { height, width } = props;
      return {
        width: `${width}`.replace(/px/, "") + "px",
        height: `${height}`.replace(/px/, "") + "px"
      };
    });

    function init() {
      const imgEl = unref(imgElRef);
      if (!imgEl) {
        return;
      }
      cropper.value = new Cropper(imgEl, {
        ...defaultOptions,

        ready: () => {
          isReady.value = true;
          props.getData && props.getData(cropper.value.getData(false));
          imgEl.addEventListener("cropmove", () => {
            props.getData && props.getData(cropper.value.getData(false));
          });
        },
        ...props.options
      });

      // console.log(
      //   cropper.value,
      //   " cropper.value cropper.value cropper.value cropper.value"
      // );
    }

    onMounted(() => {});

    watch(
      () => props.src,
      newVal => {
        if (newVal) {
          // console.log(cropper.value);
          // if (cropper.value) {
          //   cropper.value.reset();
          // } else {
          nextTick(() => {
            init();
          });
          // }
        }
      },
      { immediate: true }
    );

    return {
      props,
      imgElRef,
      cropper,
      getWrapperStyle,
      getImageStyle
    };
  },

  render() {
    return (
      <>
        <div
          class={useAttrs({ excludeListeners: true, excludeKeys: ["class"] })}
          style={this.getWrapperStyle}
        >
          <img
            ref="imgElRef"
            src={this.props.src}
            alt={this.props.alt}
            crossorigin={this.props.crossorigin}
            style={{
              ...this.getImageStyle,
              display: this.props.src ? "block" : "none"
            }}
          />
        </div>
      </>
    );
  }
});
