<template>
  <div class="video-mask" v-if="maskVisible">
    <p @click="hanldeClose">
      <el-icon><Close /></el-icon>
    </p>
    <video controls :src="videoUrl" autoplay />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  url: {
    type: String,
    default: ""
  }
});
const emits = defineEmits(["update:visible", "handleClose"]);

const maskVisible = ref(false);

const videoUrl = ref("");

const hanldeClose = () => {
  emits("update:visible", false);
};

watch(
  () => props.visible,
  newVal => {
    maskVisible.value = newVal;
  }
);

watch(
  () => props.url,
  newVal => {
    videoUrl.value = newVal;
  }
);
</script>

<style lang="scss" scoped>
.video-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    position: absolute;
    right: 100px;
    top: 100px;
    z-index: 100;
    cursor: pointer;

    i {
      font-size: 40px;
      color: #fff;
    }
  }

  video {
    max-width: 800px;
    max-height: 80vh;
  }
}
</style>
