<template>
  <div :class="{ pai_table: pointer }">
    <!-- 搜索插槽 -->
    <div :class="{ 'pub-search': slotSearch }">
      <slot name="search" />
    </div>
    <div class="topBtns" v-if="noTop">
      <div style="display: flex">
        <slot name="left" />
      </div>
      <div>
        <slot name="right" />
      </div>
    </div>
    <div class="customWrap">
      <el-table
        ref="tableRef"
        v-loading="loading"
        :data="paiData"
        :height="height || reallyHeight"
        :row-class-name="rowClassName"
        style="width: 100%"
        border
        v-bind="attrs"
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
        :default-sort="{ ...defaultSort }"
      >
        <template v-if="selection">
          <el-table-column type="selection" width="45" />
        </template>
        <template v-if="Array.isArray(columns)">
          <el-table-column
            v-for="(
              {
                prop,
                label,
                width,
                btns,
                slot,
                ellipsis,
                headerSlot,
                ...otherColumnItem
              },
              columnsIndex
            ) in columns"
            :key="columnsIndex"
            :prop="prop"
            :label="label"
            :width="width"
            :show-overflow-tooltip="ellipsis"
            v-bind="otherColumnItem"
            style="cursor: pointer"
          >
            <template v-if="headerSlot" #header>
              <slot :name="headerSlot" />
            </template>
            <template v-slot="scope">
              <template v-if="prop === 'operation'">
                <template
                  v-for="{
                    label: btnLabel,
                    btnClick,
                    handleType,
                    popconfirmTitle,
                    ...otherButtonItem
                  } in btns"
                  :key="btnLabel"
                >
                  <el-popconfirm
                    v-if="handleType === 'delete'"
                    :title="popconfirmTitle || '确认要删除此条数据吗？'"
                    @confirm="btnClick && btnClick(scope.row, scope.$index)"
                    width="200px"
                  >
                    <template #reference>
                      <el-button
                        @click.stop="() => {}"
                        v-bind="otherButtonItem"
                      >
                        {{ btnLabel }}
                      </el-button>
                    </template>
                  </el-popconfirm>
                  <el-button
                    v-else
                    @click.stop="btnClick && btnClick(scope.row, scope.$index)"
                    v-bind="otherButtonItem"
                  >
                    {{ btnLabel }}
                  </el-button>
                </template>
              </template>
              <template v-if="slot">
                <slot :name="slot" v-bind="scope">{{ scope.row[slot] }}</slot>
              </template>
              <template v-else>
                <p>{{ scope.row[prop] }}</p>
              </template>
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <slot />
        </template>
      </el-table>
    </div>

    <div :class="{ pagination_center: paginationCenter }">
      <el-pagination
        :style="`justify-content: ${paginationAlign};`"
        v-if="pagination"
        v-model:currentPage="pageParams.pageIndex"
        v-model:page-size="pageParams.pageSize"
        :page-sizes="pageList"
        :small="!smallPagination"
        background
        layout="total, sizes, prev, pager, next, jumper,slot"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, reactive, useAttrs, onMounted, nextTick } from "vue";
import { ElMessage } from "element-plus";

const paiData = ref([]);
const emit = defineEmits([
  "update:loading",
  "update:manualOpenCallback",
  "selectionChange",
  "dataSuccessCallback",
  "manualCallback",
  "sortChange"
]);

const tableRef = ref();

const attrs = useAttrs();

const props = defineProps({
  defaultSort: {
    type: Object,
    default: () => {
      return {};
    }
  },
  data: {
    type: Array
  },
  loading: {
    type: Boolean,
    default: false
  },
  isDalog: {
    type: Boolean,
    default: false
  },
  height: {
    type: String
  },
  manualOpenCallback: {
    type: Boolean,
    default: false
  },
  pageNum: {
    type: Boolean,
    default: true
  },
  selection: {
    type: Boolean,
    default: false
  },
  pagination: {
    type: Boolean,
    default: true
  },
  smallPagination: {
    type: Boolean,
    default: false
  },
  paginationCenter: {
    type: Boolean,
    default: false
  },
  columns: {
    type: Array
  },
  reqFun: {
    type: Function,
    default: () => {}
  },
  reqParams: {
    type: Object,
    default: () => ({})
  },
  frontCallback: {
    type: Function
  },
  pointer: {
    type: Boolean
  },
  isObj: {
    type: Boolean
  },
  paginationAlign: {
    type: String,
    default: "flex-end"
  },
  pageList: {
    type: Array,
    default: () => [20, 50, 100]
  },
  rowClassName: {
    type: String,
    default: ""
  },
  noTop: {
    type: Boolean,
    default: true
  },
  occupyTop: { type: Number, default: 0 },
  pageSize: {}
});

const pageParams = ref({
  pageIndex: 1,
  pageSize: props.pageSize || 50
});
//  总条数
const total = ref(0);
const multipleSelection = ref([]);

watch(
  () => props.data,
  newValue => {
    if (newValue) {
      paiData.value = newValue;
    }
  },
  { deep: true, immediate: true }
);

const handleSizeChange = val => {
  pageParams.value.pageSize = val;
  pageParams.value.pageIndex = 1;
  if (total.value) {
    emit("update:loading", true);
  }
};

const handleCurrentChange = val => {
  pageParams.value.pageIndex = val;
  console.log(tableRef.value, "tableRef");
  tableRef.value?.setScrollTop(0);
  if (total.value) {
    emit("update:loading", true);
  }
};

//  表格的高度
const reallyHeight = ref("500px");
const tableHieght = () => {
  //  搜索区域的高度
  let topHeight =
    (document.querySelector(".customWrap") &&
      document.querySelector(".customWrap")?.getBoundingClientRect()?.top) ||
    0;
  const topHeight2 = Number(topHeight) + Number(props.occupyTop);
  let hight = props.pagination
    ? props.isDalog
      ? ``
      : `calc(100vh - ${topHeight2}px - 60px)`
    : `calc(100vh - ${topHeight2}px - 30px)`;
  console.log("有无分页器", hight, topHeight2, props.occupyTop);
  return hight;
};

const getTableData = () => {
  const {
    reqFun,
    reqParams,
    // eslint-disable-next-line
    pageNum,
    pagination,
    manualOpenCallback,
    frontCallback,
    isObj
  } = props;
  // eslint-disable-next-line
  const empty = pagination ? pageParams.value : {};

  if (typeof reqFun === "function") {
    reqFun({
      // ...empty,
      ...reqParams,
      ...(pagination
        ? {
            pageSize: empty.pageSize || reqParams.pageSize || 50,
            pageNum: empty.pageIndex || 1
          }
        : {})
    })
      .then(res => {
        emit("update:loading", false);
        if (frontCallback) {
          return frontCallback(res).then(val => {
            paiData.value = val.data;

            total.value = Number(val.total);
          });
        }

        if (res.code === 200) {
          if (isObj) {
            return (paiData.value = res.data ? [res.data] : []);
          }
          if (res.data === null) {
            paiData.value = [];
          } else {
            paiData.value = Array.isArray(res.data) ? res.data : res.data.list;
          }
          total.value = Number(res.data.total) || res.data.totalRecords || 0;

          if (manualOpenCallback) {
            emit(
              "manualCallback",
              Array.isArray(res.data) ? res.data : res.data.list
            );
            emit("update:manualOpenCallback", false);
          } else {
            emit("dataSuccessCallback", res);
          }
        }
      })
      .catch(() => {
        emit("update:loading", false);
      });
  }
};

const handleSelectionChange = val => {
  multipleSelection.value = val;
  emit("selectionChange", val);
};

const handleSortChange = val => {
  emit("sortChange", val);
};

const operationTips = curRow => {
  return new Promise(reslove => {
    if (curRow) return reslove();
    if (!Array.from(multipleSelection.value).length) {
      return ElMessage({
        showClose: true,
        message: "请选择一条数据操作！",
        type: "warning"
      });
    }
    if (Array.from(multipleSelection.value).length !== 1) {
      return ElMessage({
        showClose: true,
        message: "只能操作一条数据！",
        type: "warning"
      });
    }
    reslove();
  });
};

// 上移
const moveUp = curRow => {
  operationTips(curRow).then(() => {
    const [currentRow] = curRow ? [curRow] : multipleSelection.value;
    const currentIndex = paiData.value.findIndex(item => item === currentRow);

    if (currentIndex === 0) return;
    paiData.value.splice(currentIndex, 1);
    paiData.value.splice(currentIndex - 1, 0, currentRow);
  });
};

// 下移
const moveDown = curRow => {
  operationTips(curRow).then(() => {
    const [currentRow] = curRow ? [curRow] : multipleSelection.value;
    const currentIndex = paiData.value.findIndex(item => item === currentRow);
    if (currentIndex > paiData.value.length) return;
    paiData.value.splice(currentIndex, 1);
    paiData.value.splice(currentIndex + 1, 0, currentRow);
  });
};
// 置顶
const topping = curRow => {
  operationTips(curRow).then(() => {
    const [currentRow] = curRow ? [curRow] : multipleSelection.value;
    const currentIndex = paiData.value.findIndex(item => item === currentRow);
    paiData.value.splice(currentIndex, 1);
    paiData.value.splice(0, 0, currentRow);
  });
};
// 置底
const setLow = curRow => {
  operationTips(curRow).then(() => {
    const [currentRow] = curRow ? [curRow] : multipleSelection.value;
    const currentIndex = paiData.value.findIndex(item => item === currentRow);
    paiData.value.splice(currentIndex, 1);
    paiData.value.push(currentRow);
  });
};
// 删除
const deleteData = curRow => {
  operationTips(curRow).then(() => {
    const [currentRow] = curRow ? [curRow] : multipleSelection.value;
    const currentIndex = paiData.value.findIndex(item => item === currentRow);
    paiData.value.splice(currentIndex, 1);
  });
};

// 删除全部所选
const deleteDataAll = () => {
  multipleSelection.value.forEach(Fitem => {
    const currentIndex = paiData.value.findIndex(item => item === Fitem);
    paiData.value.splice(currentIndex, 1);
  });
};

// 插入对象
const pushData = (curRow, addRow) => {
  const [currentRow] = curRow ? [curRow] : multipleSelection.value;
  const currentIndex = paiData.value.findIndex(item => item === currentRow);
  paiData.value.splice(currentIndex, 0, addRow);
};

const handleData = (type, curRow, addRow) => {
  switch (type) {
    case 0:
      moveUp(curRow);
      break;
    case 1:
      moveDown(curRow);
      break;
    case 2:
      topping(curRow);
      break;
    case 3:
      setLow(curRow);
      break;
    case 4:
      deleteData(curRow);
      break;
    case 5:
      deleteDataAll();
      break;
    case 6:
      pushData(curRow, addRow);
      break;
  }
};
const resetForm = () => {
  pageParams.value.pageIndex = 1;
  pageParams.value.pageSize = props.reqParams?.pageSize || 50;
};

watch(
  () => props.loading,
  newValue => {
    if (props.pageSize) {
      setTimeout(() => {
        console.log(pageParams, "pageParams");
        newValue && getTableData();
      }, 10);
    } else {
      nextTick(() => {
        newValue && getTableData();
      });
    }
  },
  { immediate: true }
);

//  初始化表格高度
onMounted(() => {
  reallyHeight.value = tableHieght();
  resetForm();
});

defineExpose({
  handleData,
  resetForm,
  tableData: () => paiData.value,
  setTableData: () => paiData
});
</script>
<style lang="scss" scoped>
.topBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 15px 0;
}

.pai_table {
  :deep(.el-table__body-wrapper) {
    td {
      cursor: pointer;
    }
  }

  :deep(.cell) {
    color: #000000;
  }
}

:deep(.warning-row) {
  color: #dcdcdc;
}

:deep(.success-row) {
  color: #00bfff;
}

.pagination_center {
  .el-pagination {
    display: flex;

    align-items: center;
    justify-content: center;
  }
}
</style>
