<template>
  <div class="preview_img">
    <div v-if="needBtn" @click.stop="visible = true">
      <div><el-button type="primary"> 选择图片 </el-button></div>
      <div>20M内常用图片（除GIF）</div>
    </div>
    <div v-if="!imgUrl" class="no_img" @click.stop="visible = true">
      <div class="no_img_layout">
        <!-- <img src="../img/icon_36x36_add_333333@2x.png" /> -->
        <div class="plus_div" />
        <div>上传图片</div>
      </div>
    </div>
    <div v-else class="exist_img">
      <el-image
        style="width: 100%; height: 100%; background: #ccc"
        :src="imgUrl"
        :zoom-rate="1.2"
        :preview-src-list="[imgUrl]"
        fit="contain"
        preview-teleported
        hide-on-click-modal
      />

      <div
        class="del_img"
        @click.stop="emits('onClose', curItem)"
        v-if="openClose"
      >
        <img
          src="../../views/article_root/img/icon_24x24_close_style2@2x.png"
        />
      </div>
      <div class="btn_img" v-if="!noChange">
        <div class="btn_div" @click.stop="visible = true">
          <img
            src="../../views/article_root/img/icon_12x12_change_ffffff@2x.png"
          />
          <span>替换</span>
        </div>
        <div class="line" />
        <div
          class="btn_div"
          @click.stop="
            singleVisible = true;
            cropperUrl = imgUrl;
          "
        >
          <img
            src="../../views/article_root/img/icon_12x12_cut_ffffff@2x.png"
          />
          <span>裁剪</span>
        </div>
      </div>
    </div>

    <UploadDialog
      v-model:visible="visible"
      :modelType="0"
      :num="1"
      @handleOk="handleOk"
    />

    <singleDialog
      v-model:visible="singleVisible"
      :cropperUrl="cropperUrl"
      :aspectRatio="aspectRatio"
      @singleSuccess="singleSuccess"
    />
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import UploadDialog from "@/components/UploadDialog/index.vue";
import singleDialog from "@/components/Editor/singleDialog.vue";
const emits = defineEmits(["onClose", "onReplace", "onCropping", "setImgInfo"]);
const props = defineProps({
  imgUrl: {
    type: String
  },
  curItem: {
    type: Object
  },
  openCropping: {
    type: Boolean
  },
  aspectRatio: {
    type: Number,
    default: NaN
  },
  needBtn: {
    type: Boolean
  },
  noChange: {
    type: Boolean,
    default: false
  },
  openClose: {
    type: Boolean,
    default: true
  }
});

const visible = ref(false);
const singleVisible = ref(false);
const cropperUrl = ref("");

const handleOk = val => {
  if (props.openCropping) {
    singleVisible.value = true;
    cropperUrl.value = val[0].originSrc;
  } else {
    emits("setImgInfo", val[0]);
  }
};

const singleSuccess = val => {
  console.log(val, "val");
  emits("setImgInfo", val);
};
</script>
<style lang="scss" scoped>
.preview_img {
  width: 100%;
  height: 100%;

  :deep(.el-image__preview) {
    margin: auto;
  }

  .no_img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px dashed #ccc;

    .no_img_layout {
      .plus_div {
        width: 36px;
        height: 36px;
        margin: 0 auto;
        background: url("../../views/article_root/img/icon_36x36_add_333333@2x.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      div {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        margin-top: 5px;
      }
    }
  }

  .no_img:hover {
    border: 1px dashed #409eff;
    background: rgb(64, 158, 255, 0.2);

    .no_img_layout {
      .plus_div {
        background: url("../../views/article_root/img/icon_36x36_add_409eff@2x.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      div {
        color: #409eff;
      }
    }
  }

  .exist_img {
    width: 100%;
    height: 100%;
    position: relative;

    .del_img {
      width: 24px;
      height: 24px;
      color: #409eff;
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      display: none;
    }

    .btn_img {
      width: 100%;
      height: 32px;
      background: #000000;
      opacity: 0.7;
      position: absolute;
      bottom: 0;
      right: 0;
      align-items: center;
      justify-content: space-between;
      display: none;

      .btn_div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;

        img {
          width: 12px;
          height: 12px;
        }

        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-left: 2px;
          line-height: 32px;
        }
      }

      .line {
        width: 1px;
        height: 16px;
        background: #ffffff;
      }
    }
  }

  .exist_img:hover {
    .del_img {
      display: block;
    }

    .btn_img {
      display: flex;
    }
  }
}
</style>
