import type { RouteConfigsTable } from "/#/index";
import pageRouterName from "@/router/pageRouterName";

const AiPoster: RouteConfigsTable = {
  path: "/aiPoster/edit",
  name: pageRouterName("aiPosterEdit"),
  component: () => import("@/views/aiPoster/edit/index.vue"),
  meta: {
    rank: 2,
    title: "编辑",
    keepAlive: true,
    showLink: false
  }
};

export default AiPoster;
