<template>
  <Teleport :to="appendTo ? `#${appendTo}` : undefined" :disabled="!appendTo">
    <div
      class="custom-mask"
      v-if="maskVisible"
      :style="{ position: appendTo ? 'absolute' : 'fixed' }"
    >
      <div class="custom-mask-container">
        <img src="./img/icon_loading_active.png" />
        <p>{{ title }}</p>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { ref, watch } from "vue";
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ""
  },
  appendTo: {
    type: String,
    default: undefined
  }
});
const emits = defineEmits(["update:visible", "handleClose"]);

const maskVisible = ref(false);

const videoUrl = ref("");

const hanldeClose = () => {
  emits("handleClose");
};

watch(
  () => props.visible,
  newVal => {
    maskVisible.value = newVal;
  }
);

watch(
  () => props.appendTo,
  newVal => {
    console.log(newVal, "666666666");
  },
  { immediate: true }
);

watch(
  () => props.url,
  newVal => {
    videoUrl.value = newVal;
  }
);
</script>

<style lang="scss" scoped>
.custom-mask {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 36px;
    height: 36px;
    animation: spin 3s linear infinite;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    color: #666;
  }

  .custom-mask-container {
    background: #fff;
    width: 400px;
    height: 146px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
