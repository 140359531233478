import { defineStore } from "pinia";
import { store } from "@/store";
import { cacheType } from "./types";
import { constantMenus } from "@/router";
import { cloneDeep } from "lodash-unified";
import { RouteConfigs } from "@/layout/types";
import { ascending, filterTree } from "@/router/utils";
import { listUserMenus } from "@/api/sysConfig";

export const usePermissionStore = defineStore({
  id: "permission",
  state: () => ({
    // 静态路由生成的菜单
    constantMenus,
    // 整体路由生成的菜单（静态、动态）
    wholeMenus: [],
    // 深拷贝一个菜单树，与导航菜单不突出
    menusTree: [],
    buttonAuth: [],
    // 缓存页面keepAlive
    cachePageList: [],
    cannotClosePages: []
  }),
  actions: {
    closePagesOperate({ mode, name }: cacheType) {
      switch (mode) {
        case "add":
          this.cannotClosePages.push(name);
          this.cannotClosePages = [...new Set(this.cannotClosePages)];
          break;
        case "delete":
          this.cannotClosePages = this.cannotClosePages.filter(v => v !== name);
          break;
      }
    },
    /** 获取异步路由菜单 */
    asyncActionRoutes(routes) {
      if (
        this.wholeMenus.length > 0 &&
        this.wholeMenus.length === routes.length - 1
      ) {
        return;
      }
      this.wholeMenus = filterTree(
        ascending(this.constantMenus.concat(routes))
      );

      this.menusTree = cloneDeep(
        filterTree(ascending(this.constantMenus.concat(routes)))
      );

      const getButtonAuth = (arrRoutes: Array<RouteConfigs>) => {
        if (!arrRoutes || !arrRoutes.length) return;
        arrRoutes.forEach((v: RouteConfigs) => {
          if (v.meta && v.meta.authority) {
            this.buttonAuth.push(...v.meta.authority);
          }
          if (v.children) {
            getButtonAuth(v.children);
          }
        });
      };

      getButtonAuth(this.wholeMenus);
    },
    async changeSetting(routes) {
      await this.asyncActionRoutes(routes);
    },
    cacheOperate({ mode, name }: cacheType) {
      switch (mode) {
        case "add":
          this.cachePageList.push(name);
          this.cachePageList = [...new Set(this.cachePageList)];
          break;
        case "delete":
          // eslint-disable-next-line no-case-declarations
          this.cachePageList = this.cachePageList.filter(v => v !== name);
          // const delIndex = this.cachePageList.findIndex(v => v === name);
          // delIndex !== -1 && this.cachePageList.splice(delIndex, 1);
          break;
      }
    },
    /** 清空缓存页面 */
    clearAllCachePage() {
      this.wholeMenus = [];
      this.menusTree = [];
      this.buttonAuth = [];
      this.cachePageList = [];
    },
    setBtnAuth(arr = []) {
      this.buttonAuth = arr;
    }
  }
});

export function usePermissionStoreHook() {
  return usePermissionStore(store);
}
