import type { RouteConfigsTable } from "/#/index";
import pageRouterName from "@/router/pageRouterName";

const aiFs: RouteConfigsTable[] = [
  {
    path: "/aiFs/create",
    name: pageRouterName("AiFinancialCreate"),
    component: () => import("@/views/aiFs/create.vue"),
    meta: {
      rank: 2,
      title: "AI财报编辑",
      keepAlive: true,
      showLink: false
    }
  }
];

export default aiFs;
