import type { RouteConfigsTable } from "/#/index";

const defaultRouter: RouteConfigsTable[] = [
  {
    path: "/h5",
    name: "H5Page",
    meta: {
      icon: "camera",
      title: "H5生成器",
      rank: 2,
      showLink: false
    },
    children: [
      {
        path: "/h5",
        alias: "/h5/",
        redirect: "/h5create/collection",
        meta: {
          title: "",
          keepAlive: true
        }
      },
      {
        path: "/h5/questionnaire/edit/:id",
        name: "QaEdit",
        component: () => import("@/views/h5/questionnaire/edit.vue"),
        meta: {
          title: "问卷制作",
          showLink: false,
          keepAlive: true
        }
      },
      {
        path: "/h5/questionnaire/detail",
        name: "QaItemDetail",
        component: () => import("@/views/h5/questionnaire/detail/index.vue"),
        meta: {
          title: "问卷数据",
          showLink: false,
          keepAlive: true
        }
      },
      {
        path: "/h5/special/edit/:id",
        name: "SpecialEdit",
        component: () => import("@/views/h5/special/edit.vue"),
        meta: {
          title: "专题制作",
          showLink: false,
          keepAlive: true
        }
      },
      {
        path: "/h5/voteH5/edit/:id",
        name: "VoteH5Edit",
        component: () => import("@/views/h5/voteH5/edit.vue"),
        meta: {
          title: "投票H5",
          showLink: false,
          keepAlive: true
        }
      },
      {
        path: "/h5/voteH5/share/:id",
        name: "VoteH5Share",
        component: () => import("@/views/h5/voteH5/share.vue"),
        meta: {
          title: "投票数据",
          showLink: false,
          keepAlive: true
        }
      },
      {
        path: "/h5/lotteryH5/edit/:id",
        name: "lotteryH5Edit",
        component: () => import("@/views/h5/lottery/edit.vue"),
        meta: {
          title: "抽奖H5",
          showLink: false,
          keepAlive: true
        }
      },
      {
        path: "/h5/lotteryH5/share/:id",
        name: "LotteryH5Share",
        component: () => import("@/views/h5/lottery/share.vue"),
        meta: {
          title: "抽奖H5分享标题",
          showLink: false,
          keepAlive: true
        }
      },
      {
        path: "/h5/helper",
        name: "H5Helper",
        component: () => import("@/views/h5/helper/index.vue"),
        meta: {
          title: "求助",
          showLink: false
        },
        children: [
          {
            path: "/h5/helper/create",
            name: "HelperCreate",
            component: () => import("@/views/h5/helper/create/index.vue"),
            meta: {
              title: "求助制作",
              showLink: false,
              keepAlive: true
            },
            children: [
              {
                path: "/h5/helper/create/:id",
                name: "HelperEdit",
                component: () => import("@/views/h5/helper/create/index.vue"),
                meta: {
                  showLink: false,
                  keepAlive: true
                }
              }
            ]
          },
          {
            path: "/h5/helper/detail/:id",
            name: "HelperDetail",
            component: () => import("@/views/h5/helper/detail/index.vue"),
            meta: {
              showLink: false,
              keepAlive: true
            }
          }
        ]
      },
      {
        path: "/h5create/subscription/:id",
        name: "Subscription",
        component: () => import("@/views/h5create/subscription/index.vue"),
        meta: {
          title: "订阅期刊",
          showLink: false,
          keepAlive: true
        }
      }
    ]
  },
  {
    path: "/draft",
    name: "Draft",
    component: () => import("@/views/draft/index/index.vue"),
    meta: {
      icon: "icon-pp-menu-logistic-logo",
      title: "智能抓稿",
      rank: 2,
      showLink: false,
      keepAlive: true
    }
  },
  {
    path: "/xhs_draft",
    name: "Xhs_draft",
    component: () => import("@/views/xhs_draft/index/index.vue"),
    meta: {
      icon: "icon-pp-menu-logistic-logo",
      title: "新华社稿库",
      rank: 2,
      showLink: false,
      keepAlive: true
    }
  },
  {
    path: "/chat",
    name: "ChatGPT",
    component: () => import("@/views/chatGPT/index/index.vue"),
    meta: {
      title: "AI对话",
      rank: 2,
      showLink: false
    }
  },
  {
    path: "/statichtml",
    name: "Statichtml",
    component: () => import("@/views/statichtml/index.vue"),
    meta: {
      icon: "icon-pp-menu-logistic-logo",
      title: "静态页面",
      rank: 2,
      showLink: false
    },
    children: [
      {
        path: "/statichtml/add",
        name: "StatichtmlAdd",
        component: () => import("@/views/statichtml/add/index.vue"),
        meta: {
          title: "新增静态页面",
          showLink: false,
          keepAlive: true
        },
        children: [
          {
            path: "/statichtml/add/:id",
            name: "StatichtmlEdit",
            component: () => import("@/views/statichtml/add/index.vue"),
            meta: {
              showLink: false,
              keepAlive: true
            }
          }
        ]
      }
    ]
  },
  {
    path: "/picture/sharing",
    name: "PictureSharing",
    component: () => import("@/views/material/pictureSharing/index.vue"),
    meta: {
      icon: "icon-pp-menu-logistic-logo",
      title: "集团图片共享库",
      rank: 2,
      showLink: false
    },
    children: [
      {
        path: "/picture/sharing/detaile",
        name: "PictureSharingDetaile",
        component: () =>
          import("@/views/material/pictureSharing/com/detaile.vue"),
        meta: {
          showLink: false,
          keepAlive: true
        }
      },
      {
        path: "/picture/sharing/add",
        name: "PictureSharingAdd",
        component: () => import("@/views/material/pictureSharing/com/add.vue"),
        meta: {
          showLink: false,
          keepAlive: true
        }
      }
    ]
  },
  {
    path: "/aiDraw",
    redirect: "/aiDraw/audit",
    meta: {
      icon: "icon-pp-menu-logistic-logo",
      title: "智媒·AI作画",
      rank: 2,
      showLink: false
    },
    children: [
      {
        path: "/aiDraw/audit",
        name: "AiDrawAudit",
        component: () => import("@/views/aiDraw/audit/index.vue"),
        meta: {
          title: "审核后台",
          showLink: false,
          keepAlive: true
        }
      },
      {
        path: "/aiDraw/business",
        name: "AiDrawBusiness",
        component: () => import("@/views/aiDraw/business/index.vue"),
        meta: {
          title: "运营后台",
          showLink: false,
          keepAlive: true
        }
      }
    ]
  },
  {
    path: "/ipAiDraw",
    redirect: "/ipAiDraw/audit",
    meta: {
      icon: "icon-pp-menu-logistic-logo",
      title: "IP上海AI作画",
      rank: 2,
      showLink: false
    },
    children: [
      {
        path: "/ipAiDraw/audit",
        name: "IpAiDrawAudit",
        component: () => import("@/views/ipAiDraw/audit/index.vue"),
        meta: {
          title: "审核后台",
          showLink: false,
          keepAlive: true
        }
      },
      {
        path: "/ipAiDraw/business",
        name: "IpAiDrawBusiness",
        component: () => import("@/views/ipAiDraw/business/index.vue"),
        meta: {
          title: "运营后台",
          showLink: false,
          keepAlive: true
        }
      }
    ]
  },
  {
    path: "/statistics",
    redirect: "/statistics/interaction",
    meta: {
      icon: "icon-pp-menu-data",
      title: "统计管理",
      rank: 2,
      showLink: false
    },
    children: [
      {
        path: "/statistics/interaction",
        name: "Interaction",
        component: () => import("@/views/statistics/interaction/index.vue"),
        meta: {
          title: "互动贴数量统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/topic",
        name: "Topic",
        component: () => import("@/views/statistics/topic/index.vue"),
        meta: {
          title: "话题互动贴数量统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/manuscript",
        name: "Manuscript",
        component: () => import("@/views/statistics/manuscript/index.vue"),
        meta: {
          title: "稿件总点击量统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/comment",
        name: "Comment",
        component: () => import("@/views/statistics/comment/index.vue"),
        meta: {
          title: "文章评论和点赞数据统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/manuscriptDay",
        name: "ManuscriptDay",
        component: () => import("@/views/statistics/manuscriptDay/index.vue"),
        meta: {
          title: "稿件按天点击排行",
          keepAlive: true
        }
      },
      {
        path: "/statistics/topicClick",
        name: "TopicClick",
        component: () => import("@/views/statistics/topicClick/index.vue"),
        meta: {
          title: "话题点击排行",
          keepAlive: true
        }
      },
      {
        path: "/statistics/topicAll",
        name: "TopicAll",
        component: () => import("@/views/statistics/topicAll/index.vue"),
        meta: {
          title: "话题综合统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/govHistory",
        name: "GovHistory",
        component: () => import("@/views/statistics/govHistory/index.vue"),
        meta: {
          title: "政务稿件历史统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/mediumHistory",
        name: "MediumHistory",
        component: () => import("@/views/statistics/mediumHistory/index.vue"),
        meta: {
          title: "媒体稿件历史统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/paiHistory",
        name: "PaiHistory",
        component: () => import("@/views/statistics/paiHistory/index.vue"),
        meta: {
          title: "湃客稿件历史统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/govDay",
        name: "GovDay",
        component: () => import("@/views/statistics/govDay/index.vue"),
        meta: {
          title: "政务稿件按天统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/mediumDay",
        name: "MediumDay",
        component: () => import("@/views/statistics/mediumDay/index.vue"),
        meta: {
          title: "媒体稿件按天统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/paiDay",
        name: "PaiDay",
        component: () => import("@/views/statistics/paiDay/index.vue"),
        meta: {
          title: "湃客稿件按天统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/examine",
        name: "Examine",
        component: () => import("@/views/statistics/examine/index.vue"),
        meta: {
          title: "考核数据统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/govExamine",
        name: "GovExamine",
        component: () => import("@/views/statistics/govExamine/index.vue"),
        meta: {
          title: "政务考核数据统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/mediumExamine",
        name: "MediumExamine",
        component: () => import("@/views/statistics/mediumExamine/index.vue"),
        meta: {
          title: "媒体考核数据统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/paiExamine",
        name: "PaiExamine",
        component: () => import("@/views/statistics/paiExamine/index.vue"),
        meta: {
          title: "湃客考核数据统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/userList",
        name: "UserList",
        component: () => import("@/views/statistics/userList/index.vue"),
        meta: {
          title: "查看注册用户数",
          keepAlive: true
        }
      },
      {
        path: "/statistics/directSeeding",
        name: "DirectSeeding",
        component: () => import("@/views/statistics/directSeeding/index.vue"),
        meta: {
          title: "一周内直播点击统计",
          keepAlive: true
        }
      },
      {
        path: "/statistics/govRanking",
        name: "GovRanking",
        component: () => import("@/views/statistics/govRanking/index.vue"),
        meta: {
          title: "政务号影响力排行榜统计管理",
          keepAlive: true
        }
      },

      {
        path: "/statistics/govCountByDay",
        name: "GovCountByDay",
        component: () => import("@/views/statistics/govCountByDay/index.vue"),
        meta: {
          title: "政务按天统计报表",
          keepAlive: true
        }
      },
      {
        path: "/statistics/mediumCountByDay",
        name: "MediumCountByDay",
        component: () =>
          import("@/views/statistics/mediumCountByDay/index.vue"),
        meta: {
          title: "媒体按天统计报表",
          keepAlive: true
        }
      },
      {
        path: "/statistics/paiCountByDay",
        name: "PaiCountByDay",
        component: () => import("@/views/statistics/paiCountByDay/index.vue"),
        meta: {
          title: "湃客按天统计报表",
          keepAlive: true
        }
      },
      {
        path: "/statistics/govManuscript",
        name: "GovManuscript",
        component: () => import("@/views/statistics/govManuscript/index.vue"),
        meta: {
          title: "政务稿件报表",
          keepAlive: true
        }
      },
      {
        path: "/statistics/mediumManuscript",
        name: "MediumManuscript",
        component: () =>
          import("@/views/statistics/mediumManuscript/index.vue"),
        meta: {
          title: "媒体稿件报表",
          keepAlive: true
        }
      },
      {
        path: "/statistics/paiManuscript",
        name: "PaiManuscript",
        component: () => import("@/views/statistics/paiManuscript/index.vue"),
        meta: {
          title: "湃客稿件报表",
          keepAlive: true
        }
      }
    ]
  },
  {
    path: "/journal",
    redirect: "/journal/h5",
    meta: {
      icon: "icon-pp-menu-Hometextile",
      title: "日志管理",
      rank: 2,
      showLink: false
    },
    children: [
      {
        path: "/journal/h5",
        name: "H5Log",
        component: () => import("@/views/journal/h5/index.vue"),
        meta: {
          title: "H5日志",
          keepAlive: true
        }
      }
    ]
  },
  {
    path: "/articleMange",

    meta: {
      title: "稿件管理",
      rank: 2,
      showLink: false
    },
    children: [
      {
        path: "/articleMange/batchDelete",
        name: "BatchDelete",
        component: () => import("@/views/articleMange/batchDelete/index.vue"),
        meta: {
          title: "批量删稿",
          keepAlive: true
        }
      }
    ]
  },
  // {
  //   path: "/speechToText",

  //   meta: {
  //     title: "语音转文字",
  //     rank: 2,
  //     showLink: false
  //   },
  //   children: [
  //     {
  //       path: "/speechToText",
  //       name: "SpeechToText",
  //       component: () => import("@/views/speechToText/index/index.vue"),
  //       meta: {
  //         title: "语音转文字",
  //         keepAlive: true
  //       }
  //     }
  //   ]
  // },
  {
    path: "/aiwrite",
    name: "AiWrite",
    component: () => import("@/views/aiwrite/index.vue"),
    meta: {
      title: "AI写作",
      rank: 2,
      showLink: false
    },
    children: [
      {
        path: "/aiwrite/create/:id",
        name: "AiWriteCreate",
        component: () => import("@/views/aiwrite/create.vue"),
        meta: {
          title: "AI写作创建",
          keepAlive: true
        }
      }
    ]
  },
  {
    path: "/reporttopic",
    name: "ReportTopic",
    component: () => import("@/views/reporttopic/single/index.vue"),
    meta: {
      title: "报题管理",
      rank: 2,
      showLink: false
    },
    children: [
      // {
      //   path: "/reporttopic/single",
      //   name: "ReportTopicSingle",
      //   component: () => import("@/views/reporttopic/single/index.vue"),
      //   meta: {
      //     title: "个人报题",
      //     keepAlive: true
      //   }
      // },
      {
        path: "/reporttopic/department",
        name: "ReportTopicDepartment",
        component: () => import("@/views/reporttopic/department/index.vue"),
        meta: {
          title: "部门报题",
          keepAlive: true
        }
      },
      {
        path: "/reporttopic/centre",
        name: "ReportTopicCentre",
        component: () => import("@/views/reporttopic/centre/index.vue"),
        meta: {
          title: "中心报题",
          keepAlive: true
        }
      },
      // {
      //   path: "/reporttopic/open",
      //   name: "ReportTopicOpen",
      //   component: () => import("@/views/reporttopic/open/index.vue"),
      //   meta: {
      //     title: "公开报题",
      //     keepAlive: true
      //   }
      // },
      // {
      //   path: "/reporttopic/largeOpen/:id",
      //   name: "ReportTopicOpen",
      //   component: () => import("@/views/reporttopic/open/index.vue"),
      //   meta: {
      //     title: "报题大屏显示",
      //     keepAlive: true
      //   }
      // },
      {
        path: "/reporttopic/newSingle/:id",
        name: "ReportTopicOpen",
        component: () => import("@/views/reporttopic/single/index.vue"),
        meta: {
          title: "个人报题新建",
          keepAlive: true
        }
      },
      {
        path: "/reporttopic/newSingle/:id",
        name: "ReportTopicSingle",
        component: () => import("@/views/reporttopic/single/index.vue"),
        meta: {
          title: "新建个人报题",
          keepAlive: true
        }
      }
    ]
  },
  {
    path: "/crossword",
    redirect: "/crossword/list",
    meta: {
      icon: "icon-pp-menu-rankinglist",
      title: "填字游戏",
      rank: 2,
      showLink: false
    },
    children: [
      {
        path: "/crossword/list",
        name: "CrosswordList",
        component: () => import("@/views/crossword/list/index.vue"),
        meta: {
          title: "题库管理",
          keepAlive: true,
          showLink: false
        }
      },
      {
        path: "/crossword/skin",
        name: "SkinManagement",
        component: () => import("@/views/crossword/skin/index.vue"),
        meta: {
          title: "皮肤管理",
          keepAlive: true,
          showLink: false
        }
      },
      {
        path: "/crossword/charts",
        name: "CrosswordCharts",
        component: () => import("@/views/crossword/charts/index.vue"),
        meta: {
          title: "数据概览",
          keepAlive: true,
          showLink: false
        }
      },
      {
        path: "/crossword/blacklist",
        name: "BlackList",
        component: () => import("@/views/crossword/blacklist/index.vue"),
        meta: {
          title: "黑名单",
          keepAlive: true,
          showLink: false
        }
      }
    ]
  }
];

export default defaultRouter;
