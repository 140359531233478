import Axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  HttpError,
  HttpRequestConfig,
  HttpResponse,
  RequestMethods
} from "./types.d";
import qs from "qs";
import NProgress from "../progress";
import { loadEnv } from "@build/index";
import Cookies from "js-cookie";
import { ElMessage, ElMessageBox } from "element-plus";
import GlobalCustom from "../common";
import CryptoJS from "crypto-js";

// 加载环境变量 VITE_PROXY_DOMAIN（开发环境）  VITE_PROXY_DOMAIN_REAL（打包后的线上环境）
const { VITE_APP_PAI_API, VITE_APP_CLIENTNAME } = loadEnv();

// 相关配置请参考：www.axios-js.com/zh-cn/docs/#axios-request-config-1
const defaultConfig: AxiosRequestConfig = {
  timeout: 1000 * 60,
  withCredentials: true,
  baseURL: VITE_APP_PAI_API,
  paramsSerializer: params => qs.stringify(params, { indices: false })
};

class Http {
  constructor() {
    this.httpInterceptorsRequest();
    this.httpInterceptorsResponse();
  }

  /** 初始化配置对象 */
  private static initConfig: HttpRequestConfig = {};

  /** 保存当前Axios实例对象 */
  private static axiosInstance: AxiosInstance = Axios.create(defaultConfig);

  /** 请求拦截 */
  private httpInterceptorsRequest(): void {
    Http.axiosInstance.interceptors.request.use(
      (config: HttpRequestConfig) => {
        const $config = config;
        // console.log(config, "config");
        // 开启进度条动画
        if (!config.params || !config.params.noProcessBar) {
          NProgress.start();
        }
        // 优先判断post/get等方法是否传入回掉，否则执行初始化设置等回掉
        if (typeof config.beforeRequestCallback === "function") {
          config.beforeRequestCallback($config);
          return $config;
        }
        if (Http.initConfig.beforeRequestCallback) {
          Http.initConfig.beforeRequestCallback($config);
          return $config;
        }
        // const token = Cookies.get("token");
        $config.headers.ClientName = VITE_APP_CLIENTNAME;
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");

        if (token && userId) {
          $config.headers.token = localStorage.getItem("token");
          const d = new Date();
          const timestamp = d.getTime();
          const md5 = CryptoJS.MD5(userId + "" + token + "" + timestamp);
          $config.headers.Authorization =
            CryptoJS.enc.Hex.stringify(md5).toUpperCase();
          $config.headers.RandomId = userId;

          $config.headers.Timestamp = timestamp;
        } else {
          //重定向到登录页面
          GlobalCustom.removeValues();
          // 此处重定向会导致参数丢失
        }
        return $config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  /** 响应拦截 */
  private httpInterceptorsResponse(): void {
    const instance = Http.axiosInstance;
    instance.interceptors.response.use(
      (response: HttpResponse) => {
        // const $config = response.config;

        // 关闭进度条动画
        NProgress.done();
        if (response.data.code === 200 || response.data.resultCode == 1) {
          return response.data;
        }
        if (response.data.resultCode === 9999) {
          if (response.data.message || response.data.resultMsg) {
            ElMessageBox.confirm(
              response.data.message || response.data.resultMsg,
              "",
              {
                confirmButtonText: "重新登录",
                cancelButtonText: "取消",
                type: "warning",
                closeOnClickModal: false
              }
            )
              .then(() => {
                Cookies.remove("userInfo");
                Cookies.remove("token");
                sessionStorage.clear();
                GlobalCustom.removeValues();
                location.href = "/login";
              })
              .catch(() => {
                console.log("已取消");
              });
          }
          return response.data;
        }

        if (response.data.code === 10004) {
          ElMessage.error(response.data.desc);
          localStorage.removeItem("token");
          location.href = "/login";
          return response.data;
        }

        if (response.data.code === 401) {
          ElMessage.error(response.data.desc);
          return response.data;
        }

        if (response.data.resultCode === 0 && response.data.resultMsg) {
          ElMessage.error(response.data.resultMsg);
          return response.data;
        }
        if (response.data.code !== 200 && response.data.desc) {
          ElMessage.error(response.data.desc);
          return response.data;
        }

        return response.data;
      },
      (error: HttpError) => {
        const $error = error;
        $error.isCancelRequest = Axios.isCancel($error);
        // 关闭进度条动画
        NProgress.done();

        if ($error.message === "Network Error") {
          ElMessage.error("请检查您的网络环境");
          return Promise.reject($error);
        } else if (error.message.indexOf("timeout") !== -1) {
          ElMessage.error("网络请求超时");
          return Promise.reject($error);
        }
        return Promise.reject($error);
      }
    );
  }

  /** 通用请求工具函数 */
  public request<T>(
    method: RequestMethods,
    url: string,
    param?: AxiosRequestConfig,
    axiosConfig?: HttpRequestConfig
  ): Promise<T> {
    const config = {
      method,
      url,
      ...param,
      ...axiosConfig
    } as HttpRequestConfig;

    // 单独处理自定义请求/响应回掉
    return new Promise((resolve, reject) => {
      Http.axiosInstance
        .request(config)
        .then((response: undefined) => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /** 单独抽离的post工具函数 */
  public post<T, P>(
    url: string,
    params?: T,
    config?: HttpRequestConfig
  ): Promise<P> {
    return this.request<P>("post", url, params, config);
  }

  /** 单独抽离的get工具函数 */
  public get<T, P>(
    url: string,
    params?: T,
    config?: HttpRequestConfig
  ): Promise<P> {
    return this.request<P>("get", url, params, config);
  }
}

export const http = new Http();
