import { defineStore } from "pinia";
import { store } from "@/store";

export const useMenuListStore = defineStore({
  id: "menuList",
  state: () => ({
    list: []
  }),

  actions: {
    changeMenuList(data) {
      this.list = data;
    }
  }
});

export function useMenuListStoreHook() {
  return useMenuListStore(store);
}
