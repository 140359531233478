import type { RouteConfigsTable } from "/#/index";

const pushManageRouter: RouteConfigsTable = {
  path: "/pushManage",
  name: "PushManage",
  component: () => import("@/views/contentOperations/pushManage/index.vue"),
  meta: {
    rank: 1,
    title: "推送设置",
    showLink: false,
    keepAlive: true
  }
};

export default pushManageRouter;
