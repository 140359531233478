import type { RouteConfigsTable } from "/#/index";

const adventureRouter: RouteConfigsTable = {
  path: "/adventure",
  name: "adventure",
  component: () => import("@/views/contentOperations/adventure/index.vue"),
  meta: {
    rank: 1,
    title: "APP首页",
    showLink: false,
    keepAlive: true
  }
};

export default adventureRouter;
