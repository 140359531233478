import { http } from "../utils/http";
import { loadEnv } from "@build/index";
import type { ResObj } from "/#/index";

// 查询所有目录菜单
export const listMenuTree = (params: object) => {
  return http.request("get", "/menu/listMenuTree", { params });
};

//  新增或修改菜单
export const saveMenu = (data: object) => {
  return http.request("post", "/open/user/menu/save", {
    data
  });
};

export const updateMyInfo = (data: object) => {
  return http.request("post", "/gateway/updateMyInfo", {
    data
  });
};

// 用户所有有效权限：根据用户ID获取用户所有有效权限。用户组为有效状态的角色，菜单权限为可见状态
export const listUserMenuTree = (params: object) => {
  return http.request<ResObj>("get", "/gateway/info", {
    params
  });
};

// 搜索角色组
export const groupSearch = (data: object) => {
  return http.request("post", "/group/search", {
    data
  });
};

// 创建角色组
export const groupCreate = (data: object) => {
  return http.request("post", "/group/create", {
    data
  });
};

// 删除角色组
export const groupDel = (data: object) => {
  return http.request("post", "/group/del", {
    data
  });
};

// 获取角色组详情页
export const groupId = (data: object) => {
  return http.request("post", "/group/id", {
    data
  });
};

// 获取全部角色组
export const groupList = (data: object) => {
  return http.request("post", "/group/list", {
    data
  });
};

// 更新角色组
export const groupUpdate = (data: object) => {
  return http.request("post", "/group/update", {
    data
  });
};

// 获取所有角色 ;/role/list
export const groupRoleList = (params: object) => {
  return http.request("get", "/role/list", { params });
};

// 获取权限(新接口)
export const nodeNodeList = (params: object) => {
  return http.request<ResObj>("get", "/node/nodeList", {
    params
  });
};

//
export const contentArticleOneKeySync = (data: object) => {
  return http.request("post", "/content/article/oneKeySync", {
    data
  });
};
