const routerNames = {
  ipWebsiteInformation: "/sysconfig/ipWebsiteInformation",
  aiPosterEdit: "/aiPoster/edit",
  aiSpeechToText: "/speechToText",
  aiPoster: "/aiPoster",
  aimanage: "/sysconfig/aimanage",
  aiVideo: "/aiVideo",
  aiVideoEdit: "/aiVideo/edit",
  aiVideoEditClip: "/aiVideo/clip",
  aiVideoManage: "/sysconfig/aiVideoManage",
  AiFinancialCreate: "/aiFs/create",
  AiFinancial: "/aiFs",
  txtConvertVoice: "/txtConvertVoice",
  copyrightPicTotal: "/statistics/copyrightPic/total",
  copyrightPicUser: "/statistics/copyrightPic/user",
  copyrightPicMaterial: "/statistics/copyrightPic/material"
};

const routerNamesLowerCase = {};
for (const key in routerNames) {
  routerNamesLowerCase[key.toLowerCase()] = routerNames[key];
}

export default key => {
  const name = routerNamesLowerCase[key.toLowerCase()];
  if (name) {
    return name.toLowerCase().replaceAll("/", "_");
  } else {
    console.warn("routeName 不存在");
    return `key${Math.random()}`;
  }
};
