<template>
  <div>
    <el-select
      v-model="selectValue"
      placeholder="请选择"
      v-bind="attrs"
      @input="emit('input', $event)"
    >
      <el-option
        v-for="{
          [optionsConfig.key]: key,
          [optionsConfig.label]: label,
          [optionsConfig.value]: value,
          ...otherOptionItem
        } in options"
        :key="key"
        :label="label"
        :value="value"
        v-bind="otherOptionItem"
      />
    </el-select>
  </div>
</template>

<script setup>
import { ref, watch, useAttrs, onMounted } from "vue";

const emit = defineEmits(["input", "handleOptions"]);

const attrs = useAttrs();

const props = defineProps({
  optionsList: {
    type: Array
  },
  value: {
    type: [String, Number],
    default: null
  },
  reqFun: {
    type: Function,
    default: null
  },
  optionsConfig: {
    type: Object,
    default: () => {
      return {
        key: "value",
        label: "label",
        value: "value"
      };
    }
  },
  reqParams: {
    type: Object,
    default: () => ({})
  },
  frontCallback: {
    type: Object,
    default: null
  }
});
const options = ref(props.optionsList);
const selectValue = ref("");

const getTableData = () => {
  const { reqFun, reqParams, frontCallback } = props;

  if (typeof reqFun === "function" && reqFun) {
    reqFun({ ...reqParams })
      .then(res => {
        if (frontCallback) {
          return frontCallback(res).then(val => {
            console.log(val.data, "data");
            options.value = val.data;
          });
        }

        if (res.code === 200) {
          options.value = Array.isArray(res.data) ? res.data : res.data.list;
          emit("handleOptions", options.value);
        }
      })
      .catch(() => {});
  }
};

// watch(
//   () => props.reqFun,
//   newValue => {
//     console.log(newValue, "newValue");
//     newValue && getTableData();
//   },
//   {
//     immediate: true,
//     deep: true
//   }
// );

onMounted(() => {
  getTableData();
});
watch(
  () => props.value,
  newValue => {
    selectValue.value = newValue;
  },
  { immediate: true }
);

watch(
  () => props.optionsList,
  newVal => {
    options.value = newVal;
  }
);
</script>
