import { getConfig } from "@/config";
import { toRouteType } from "./types";
import NProgress from "@/utils/progress";
import { findIndex } from "lodash-unified";
import GlobalCustom from "@/utils/common";
import { transformI18n } from "@/plugins/i18n";
import { useMultiTagsStoreHook } from "@/store/modules/multiTags";
import { usePermissionStoreHook } from "@/store/modules/permission";
import {
  createRouter,
  RouteComponent,
  Router,
  RouteRecordRaw
} from "vue-router";
import {
  ascending,
  findRouteByPath,
  formatFlatteningRoutes,
  formatTwoStageRoutes,
  getHistoryMode,
  handleAliveRoute,
  initRouter,
  isArticleRoot,
  isArticleTable
} from "./utils";
import { buildHierarchyTree, openLink } from "@pureadmin/utils";

import remainingRouter from "./modules/remaining";
import defaultRouter from "./modules/default";
import { isURL } from "@/utils/utils";

/** 自动导入全部静态路由！匹配 src/router/modules 目录（任何嵌套级别）中具有 .ts 扩展名的所有文件，除了 remaining.ts 文件
 * 如何匹配所有文件请看：https://github.com/mrmlnc/fast-glob#basic-syntax
 * 如何排除文件请看：https://cn.vitejs.dev/guide/features.html#negative-patterns
 */
const modules: Record<string, any> = import.meta.glob(
  [
    "./modules/**/*.ts",
    "!./modules/**/remaining.ts",
    "!./modules/**/default.ts"
  ],
  {
    eager: true
  }
);

/** 原始静态路由 */
const routes = [...defaultRouter];
console.log(modules, "🎄");

Object.keys(modules).forEach(key => {
  const isArray = Array.isArray(modules[key].default);
  if (isArray) {
    modules[key].default.forEach(item => {
      routes.push(item);
    });
  } else {
    routes.push(modules[key].default);
  }
});

/** 导出处理后的静态路由（三级及以上的路由全部拍成二级） */
export const constantRoutes: Array<RouteRecordRaw> = formatTwoStageRoutes(
  formatFlatteningRoutes(buildHierarchyTree(ascending(routes)))
);

/** 用于渲染菜单，保持原始层级 */
export const constantMenus: Array<RouteComponent> = ascending(routes).concat(
  ...remainingRouter
);

/** 不参与菜单的路由 */
export const remainingPaths = Object.keys(remainingRouter).map(v => {
  return remainingRouter[v].path;
});

/** 创建路由实例 */
export const router: Router = createRouter({
  history: getHistoryMode(),
  routes: constantRoutes.concat(...(remainingRouter as any)),
  strict: true,
  scrollBehavior(to, from, savedPosition) {
    return new Promise(resolve => {
      if (savedPosition) {
        return savedPosition;
      } else {
        if (from.meta.saveSrollTop) {
          const top: number =
            document.documentElement.scrollTop || document.body.scrollTop;
          resolve({ left: 0, top });
        }
      }
    });
  }
});

/** 重置路由 */
export function resetRouter() {
  router.getRoutes().forEach(route => {
    const { name, meta } = route;
    if (name && router.hasRoute(name) && meta?.backstage) {
      router.removeRoute(name);
      router.options.routes = formatTwoStageRoutes(
        formatFlatteningRoutes(buildHierarchyTree(ascending(routes)))
      );
    }
  });
  usePermissionStoreHook().clearAllCachePage();
}

/** 路由白名单 */
const whiteList = ["/login"];

router.beforeEach(async (to: toRouteType, _from, next) => {
  if (to.meta?.keepAlive) {
    const newMatched = to.matched;
    handleAliveRoute(newMatched, "add");
    // 页面整体刷新和点击标签页刷新
    if (_from.name === undefined || _from.name === "Redirect") {
      handleAliveRoute(newMatched);
    }
  }
  // const name = GlobalCustom.getValue(2);
  // const name = localStorage.getItem("token");
  // const name = GlobalCustom.getValue(3);
  // const name = localStorage.getItem("username");
  const name = localStorage.getItem("token");

  NProgress.start();
  const externalLink = isURL(to?.name as string);
  if (!externalLink) {
    to.matched.some(item => {
      if (!item.meta.title) return "";
      const Title = getConfig().Title;
      if (Title) {
        document.title = `${transformI18n(item.meta.title)} - ${Title}`;
      } else document.title = transformI18n(item.meta.title);
    });
  }

  if (name) {
    if (to.path === "/login") {
      next({ path: "/welcome" });
    }
    if (_from?.name) {
      // name为超链接
      if (externalLink) {
        openLink(to?.name as string);
        NProgress.done();
      } else {
        next();
      }
    } else {
      // 刷新
      try {
        // if (usePermissionStoreHook().buttonAuth.length === 0) {
        //   await usePermissionStoreHook().asyncAuth({
        //     appId: 15
        //   });
        // }
        if (usePermissionStoreHook().wholeMenus.length === 0) {
          await initRouter().then((router: Router) => {
            if (!useMultiTagsStoreHook().getMultiTagsCache) {
              const { path } = to;
              const index = findIndex(remainingRouter, v => {
                return v.path == path;
              });
              const routes: any =
                index === -1
                  ? router.options.routes[0].children
                  : router.options.routes;

              // 对栏目文章刷新后跳转到首页
              if (isArticleTable(path) || isArticleRoot(path)) {
                import.meta.env.PROD && next({ path: "/welcome" });
              } else {
                const route = findRouteByPath(path, routes);
                if (
                  route &&
                  route.meta?.title &&
                  whiteList.indexOf(route.path) === -1 &&
                  // query、params模式路由传参数的标签页在页面中追加
                  Object.keys(to.query).length === 0
                ) {
                  useMultiTagsStoreHook().handleTags("push", {
                    path: route.path,
                    name: route.name,
                    meta: route.meta
                  });
                }
              }
            }
            router.push(to.fullPath);
          });
          next();
        }
        next();
      } catch (e) {
        next();
      }
    }
  } else {
    if (to.path !== "/login") {
      if (whiteList.indexOf(to.path) !== -1) {
        next();
      } else {
        next({ path: "/login" });
      }
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
