import JsEncrypt from "jsencrypt";
import { v1 } from "uuid";
import { loadEnv } from "@build/index";

const { VITE_APP_PAPER_HEAD } = loadEnv();

export const isURL = url => {
  const urlPattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/i;
  return urlPattern.test(url);
};

// 复制文本
export function copy(text: string) {
  const input = document.createElement("textarea");
  input.value = text;
  document.body.appendChild(input);
  input.select();
  document.execCommand("copy");
  document.body.removeChild(input);
}

export const jsEncrypt = psd => {
  const encry = new JsEncrypt();
  encry.setPublicKey(
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCWQjZrrQhFVs6yyxbtJFmHf3IwsdvkI3CapbaSYdQu3OlLYOZj2K84qvWE+Y6lEG3juC2F2MvYnecCjyI7J/djiIs3YgoVDUjZTNYAqt2AuMxQ5SmuswdXlKEQ5vVXGeB9Yecissyxishqxb/nr+HDyl+dUt4aOSPpL9it0Aw/JwIDAQAB"
  );
  return encry.encrypt(psd);
};

export function getUUID() {
  return `${v1()}-${parseInt(Math.random() * 100000000)}`.replaceAll("-", "");
}

// 密码长度大于8且包含英文大小写、数字和特殊符号。
/*
^ 表示匹配字符串的开头
(?=.*[A-Z]) 表示必须包含至少一个大写字母
(?=.*[a-z]) 表示必须包含至少一个小写字母
(?=.*\d) 表示必须包含至少一个数字
(?=.*[!@#$%^&*()_+~\|}{[]\:;'<>,.?/])` 表示必须包含至少一个特殊字符
[A-Za-z\d!@#$%^&*()_+~\|}{[]\:;'<>,.?/]{8,}` 表示可以包含大小写字母、数字、特殊字符，并且字符串长度大于等于 8
$ 表示匹配字符串的结尾
*/
export function validatePassword(password) {
  const regex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+~=\-`|}{[\]\\:;'<>,.?/])[A-Za-z\d!@#$%^&*()_+~=\-`|}{[\]\\:;'<>,.?/]{8,}$/;
  return regex.test(password);
}

/**
 *
 * @param url
 * @param {string:[sh|hz]}: type sh|hz
 */
export const urlTranslation = (url, type = "sh") => {
  if (!url) return;
  if (url.indexOf(".cnstock.com") > -1) {
    return url;
  }
  const path = url.substring(url.indexOf(".com/", 0) + 1).slice(3);
  if (type === "sh") {
    const newUrl =
      "https://image" +
      `${["-dev", "-test"].includes(VITE_APP_PAPER_HEAD) ? "-test" : ""}` +
      ".cnstock.com" +
      path;
    return newUrl;
  }

  if (type === "hz") {
    return url;
  }
};

// 转化时间戳 视频时长
export const getLongTime = onlyLongTime => {
  if (onlyLongTime == 0 || onlyLongTime == undefined) {
    console.log("时间戳格式不对");
    return "";
  }

  const hour = parseInt(onlyLongTime / 3600);
  const minute = parseInt((onlyLongTime % 3600) / 60);
  const second = parseInt((onlyLongTime % 3600) % 60);
  // console.log(hour, minute, second, "🌷", onlyLongTime.toString());
  return (
    (hour < 10 ? "0" + hour : hour) +
    ":" +
    (minute < 10 ? "0" + minute : minute) +
    ":" +
    (second < 10 ? "0" + second : second)
  );
};

export const nToBr = (str = "") => {
  return `${str || ""}`.replace(/\n/g, "<br/>");
};

export const toThousands = (num = 0) => {
  return num.toString().replace(/\d+/, function (n) {
    return n.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
  });
};
export const changeSize = limit => {
  console.log(limit, "🎋");
  let size = "";
  if (limit < 1 * 1024) {
    //小于0.1KB，则转化成B
    if (limit > 0) {
      size = limit.toFixed(0) + "B";
    } else {
      size = 0 + "B";
    }
  } else if (limit < 1 * 1024 * 1024) {
    //小于0.1MB，则转化成KB
    size = (limit / 1024).toFixed(0) + "KB";
  } else if (limit < 1 * 1024 * 1024 * 1024) {
    //小于0.1GB，则转化成MB
    size = (limit / (1024 * 1024)).toFixed(0) + "MB";
  } else {
    //其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(1) + "GB";
    console.log(size, "🦌");
  }

  const sizeStr = size + ""; //转成字符串
  const index = sizeStr.indexOf("."); //获取小数点处的索引
  const dou = sizeStr.substr(index + 1, 1); //获取小数点后两位的值

  if (dou == "0" && limit) {
    console.log(dou, "🐼");
    //判断后两位是否为00，如果是则删除00
    // return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
    //判断后两位是否为0，如果是则删除0
    return sizeStr.substring(0, index) + sizeStr.substr(index + 2, 2);
  }
  return size;
};

function DOWLOAD_FILE_NAME(url, filename) {
  // 文件名称
  let fname = filename;
  // 没有文件名同时链接有值
  if (!fname && url) {
    // 获得最后一个斜杠坐标
    const index = url.lastIndexOf("/");
    // 从斜杆后一个坐标开始截取
    fname = url.substring(index + 1);
  }
  // 返回
  return fname;
}

export function DOWLOAD_FILE_URL_PRO(
  url,
  filename: any = new Date().getTime()
) {
  // Promise
  return new Promise((resolve, reject) => {
    // 获取链接二进制数据
    fetch(url)
      .then(res => {
        // 获得二进制数据
        res
          .blob()
          .then(blob => {
            // 创建一个a节点
            const a = document.createElement("a");
            // 创建一个可供下载链接
            const url = window.URL.createObjectURL(blob);
            // 将需要下载的URL赋值给a节点的href
            a.href = url;
            // 设置节点的download属性值
            a.download = DOWLOAD_FILE_NAME(url, filename);
            // 触发点击事件
            a.click();
            // 释放
            window.URL.revokeObjectURL(url);
            // 获取成功
            resolve("");
          })
          .catch(err => {
            // 获取失败
            reject(err);
          });
      })
      .catch(err => {
        // 获取失败
        reject(err);
      });
  });
}

export const calculateWidth = () => {
  const curClientWidth = document?.body?.clientWidth;
  if (curClientWidth) {
    // console.log(curClientWidth, "🌷");
    return curClientWidth < 990 ? "wap" : "www";
  }
};

// base64转file
export function dataURLtoFile(dataurl, fileName) {
  const arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);

  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const bolbObj = new Blob([u8arr], { type: mime });

  bolbObj.lastModifiedDate = new Date();
  bolbObj.name = fileName;
  return bolbObj;
}

export const getBase64 = (file): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject("");
  });

export function scaleDimensions(width, height, maxLength) {
  if (width <= maxLength && height <= maxLength) {
    // 如果宽高都小于等于最大长度，无需缩放，直接返回原始宽高
    return { width, height };
  }

  let newWidth, newHeight;

  if (width > height) {
    newWidth = maxLength;
    newHeight = Math.round((height / width) * maxLength);
  } else {
    newHeight = maxLength;
    newWidth = Math.round((width / height) * maxLength);
  }

  return { width: newWidth, height: newHeight };
}

export function compressImage(imgUrl, maxSize, fileType = "png") {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imgUrl;

    image.onload = function () {
      const outputSize = scaleDimensions(image.width, image.height, maxSize);
      // 创建一个临时的 Canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // 设置临时 Canvas 的尺寸为输出尺寸
      canvas.width = outputSize.width;
      canvas.height = outputSize.height;

      // 在临时 Canvas 上绘制压缩后的图像
      ctx.drawImage(image, 0, 0, outputSize.width, outputSize.height);
      resolve(canvas.toDataURL(`image/${fileType}`));
    };
    image.onerror = () => {
      reject("图片压缩失败");
    };
  });
}
