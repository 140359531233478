import {
  createWebHashHistory,
  createWebHistory,
  RouteComponent,
  RouteRecordNormalized,
  RouteRecordRaw,
  RouterHistory
} from "vue-router";
import { router } from "./index";
import { isProxy, toRaw } from "vue";
import { loadEnv } from "../../build";
import { cloneDeep } from "lodash-unified";
import { useTimeoutFn } from "@vueuse/core";
import { RouteConfigs } from "@/layout/types";
import { buildHierarchyTree } from "@pureadmin/utils";
import { usePermissionStoreHook } from "@/store/modules/permission";
import { useMenuListStoreHook } from "@/store/modules/menuList";
// 动态路由
// import { getAsyncRoutes } from "@/api/routes";
import { listUserMenuTree } from "@/api/sysConfig";

const IFrame = () => import("@/layout/frameView.vue");
// https://cn.vitejs.dev/guide/features.html#glob-import
// const modulesRoutes = import.meta.glob("/src/views/**/*.{vue,tsx}");
// 去除文件夹中的 components
const modulesRoutes = import.meta.glob([
  "/src/views/**/*.{vue,tsx}",
  "!/src/**/components/**/*.{vue,tsx}"
]);

/** 按照路由中meta下的rank等级升序来排序路由 */
function ascending(arr: any[]) {
  arr.forEach((v, index) => {
    if (!v?.meta?.rank && v.name !== "Home" && v.path !== "/") {
      v.meta.rank = index + 2;
    }
    if (v?.meta?.rank === 0) {
      if (v.name !== "Home" && v.path !== "/") {
        console.warn("rank only the home page can be 0");
      }
    }
  });
  return arr.sort(
    (a: { meta: { rank: number } }, b: { meta: { rank: number } }) => {
      return a?.meta?.rank - b?.meta?.rank;
    }
  );
}

/** 过滤meta中showLink为false的路由 */
function filterTree(data: RouteComponent[]) {
  const newTree = cloneDeep(data).filter(
    (v: { meta: { showLink: boolean } }) => v.meta?.showLink !== false
  );
  newTree.forEach(
    (v: { children }) => v.children && (v.children = filterTree(v.children))
  );
  return newTree;
}

/** 批量删除缓存路由(keepalive) */
function delAliveRoutes(delAliveRouteList: Array<RouteConfigs>) {
  delAliveRouteList.forEach(route => {
    usePermissionStoreHook().cacheOperate({
      mode: "delete",
      name: route?.name
    });
  });
}

/** 通过path获取父级路径 */
function getParentPaths(path: string, routes: RouteRecordRaw[]) {
  // 深度遍历查找
  function dfs(routes: RouteRecordRaw[], path: string, parents: string[]) {
    for (let i = 0; i < routes.length; i++) {
      const item = routes[i];
      // 找到path则返回父级path
      if (item.path === path) return parents;
      // children不存在或为空则不递归
      if (!item.children || !item.children.length) continue;
      // 往下查找时将当前path入栈
      parents.push(item.path);

      if (dfs(item.children, path, parents).length) return parents;
      // 深度遍历查找未找到时当前path 出栈
      parents.pop();
    }
    // 未找到时返回空数组
    return [];
  }

  return dfs(routes, path, []);
}

/** 查找对应path的路由信息 */
function findRouteByPath(path: string, routes: RouteRecordRaw[]) {
  let res = routes.find((item: { path: string }) => item.path == path);
  if (res && !res.children) {
    return isProxy(res) ? toRaw(res) : res;
  }
  if (res && res.children) {
    // 只查第一层
    const nRes = res.children.find(
      (item: { path: string }) => item.path == path
    );
    if (nRes) {
      return isProxy(nRes) ? toRaw(nRes) : nRes;
    }
    return res;
  } else {
    for (let i = 0; i < routes.length; i++) {
      if (
        routes[i].children instanceof Array &&
        routes[i].children.length > 0
      ) {
        res = findRouteByPath(path, routes[i].children);
        if (res) {
          return isProxy(res) ? toRaw(res) : res;
        }
      }
    }
    return null;
  }
}

function addPathMatch() {
  if (!router.hasRoute("pathMatch")) {
    router.addRoute({
      path: "/:pathMatch(.*)",
      name: "pathMatch",
      redirect: "/error/404"
    });
  }
}

function setDifAuthority(authority, routes) {
  routes.children[1].meta.authority = [authority];
  return routes;
}

// eslint-disable-next-line
const permissionRouter = [
  setDifAuthority("v-admin", {
    path: "/permission",
    meta: {
      title: "menus.permission",
      icon: "lock",
      rank: 7
    },
    children: [
      {
        path: "/permission/page/index",
        name: "PermissionPage",
        meta: {
          title: "menus.permissionPage"
        }
      },
      {
        path: "/permission/button/index",
        name: "PermissionButton",
        meta: {
          title: "menus.permissionButton",
          authority: []
        }
      }
    ]
  })
];

const transformMenu = (menu: any[], id: any) => {
  const homePath = [];
  menu.forEach((item: any, index) => {
    const title = item["name"];
    item["name"] = `${item.routerUrl}`.toLowerCase().replaceAll("/", "_");
    item["pathList"] = id ? [id, item["id"]] : [item["id"]];
    item["parentId"] = id || null;
    item["meta"] = {
      title,
      icon: item["iconStyle"],
      rank: index + 1,
      keepAlive: true
    };
    if (!id) {
      item["meta"]["icon"] = item["iconStyle"];
    }
    // if (item.children && item.children.length) {
    //   const childUrl = item.children[0].routerUrl;
    //   let path = `/${item.routerUrl}`;
    //   if (!item.routerUrl && childUrl) {
    //     const list = childUrl.split("/").filter(l => l);
    //     if (list.length >= 2) {
    //       path = `/${list.slice(0, list.length - 1).join("/")}`;
    //     } else {
    //       path = childUrl;
    //     }
    //   }
    //   item["path"] = path;
    //   return transformMenu(item.children, "/");
    // } else {
    //   item["path"] = item["routerUrl"] || "/error/404";
    //   delete item.children;
    // }
    if (item.children && item.children.length) {
      item["path"] =
        `${item.url}`.indexOf("/") === 0 ? item.url : `/${item.url}`;
      item["redirect"] = item.children[0]["url"];
      return transformMenu(item.children, item.parentId);
    } else {
      item["path"] =
        `${item.url}`.indexOf("/") === 0 ? item["url"] : `/${item.url}`;
      delete item.children;
    }
  });
  return [...homePath, ...menu];
};

const handleHeavy = (arr, callback, childrenKey = "children", level) => {
  Array.isArray(arr) &&
    arr.forEach(item => {
      callback && callback(item, level);
      if (Array.isArray(item[childrenKey])) {
        handleHeavy(item[childrenKey], callback, childrenKey, level + 1);
      }
    });
};

//  过滤菜单选项
const filterMenu = (menu: any) => {
  handleHeavy(
    menu,
    item => {
      if (item.children && item.children.length === 0) {
        delete item.children;
      }
      if (Array.isArray(item.children)) {
        item.children = item.children.filter(fItem => fItem.visible !== "0");
      }
      if (
        item.children &&
        item.children.length > 0 &&
        item.children[0].type === "f"
      ) {
        item.btnList = item.children;
        delete item.children;
      }
      if (item.url === "/final_root" && Array.isArray(item.children)) {
        item.children.forEach((Fitem, fInedx) => {
          const arrPar = Fitem.url.split("/");
          Fitem.url = `/final_root?channel=${fInedx}&id=${arrPar[3]}`;
        });
      }
    },
    "children",
    null
  );
  return menu;
};

/** 初始化路由 */
function initRouter() {
  return new Promise(resolve => {
    listUserMenuTree({ sysCode: 0 }).then(async (res: any) => {
      if (res.code === 200) {
        usePermissionStoreHook().setBtnAuth(res.data.permissions);
        //  初始化后端返回的菜单，由于里面有用户信息，直接保存
        const userInfo = {
          userName: res.data.userName,
          userId: res.data.id,
          mobile: res.data.mobile,
          introduction: res.data.introduction
        };
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        useMenuListStoreHook().changeMenuList(res.data.permissions);

        const filterMenuData = filterMenu(res.data.resTreeList).filter(
          item => item.type !== "f" && item.visible !== "0"
        );
        const arr = transformMenu(filterMenuData, "");
        // console.log(arr, "👧🏻");
        formatFlatteningRoutes(addAsyncRoutes(arr)).map((v: RouteRecordRaw) => {
          // 防止重复添加路由
          const index = router.options.routes[0].children.findIndex(
            value => value.path === v.path
          );
          if (index === -1) {
            // 切记将路由push到routes后还需要使用addRoute，这样路由才能正常跳转
            router.options.routes[0].children.push(v);
            // 最终路由进行升序
            ascending(router.options.routes[0].children);
            if (!router.hasRoute(v?.name)) router.addRoute(v);
            const flattenRouters: any = router
              .getRoutes()
              .find(n => n.path === "/");
            router.addRoute(flattenRouters);
          } else {
            const currentRoute = router.options.routes[0].children[index];
            if (v?.meta?.title && currentRoute?.meta) {
              if (!currentRoute?.children?.length) {
                currentRoute.meta.title = v.meta.title;
              }
            }
          }
        });
        await usePermissionStoreHook().changeSetting(arr);
        console.log(router, "router1111");
        resolve(router);
      }
      addPathMatch();
    });
  });
}

/**
 * 将多级嵌套路由处理成一维数组
 * @param routesList 传入路由
 * @returns 返回处理后的一维路由
 */
function formatFlatteningRoutes(routesList: RouteRecordRaw[] = []) {
  if (routesList.length === 0) return routesList;
  let hierarchyList = buildHierarchyTree(routesList);
  for (let i = 0; i < hierarchyList.length; i++) {
    if (hierarchyList[i].children) {
      hierarchyList = hierarchyList
        .slice(0, i + 1)
        .concat(hierarchyList[i].children, hierarchyList.slice(i + 1));
    }
  }
  return hierarchyList;
}

/**
 * 一维数组处理成多级嵌套数组（三级及以上的路由全部拍成二级，keep-alive 只支持到二级缓存）
 * @param routesList 处理后的一维路由菜单数组
 * @returns 返回将一维数组重新处理成规定路由的格式
 */
function formatTwoStageRoutes(routesList: RouteRecordRaw[]) {
  if (routesList.length === 0) return routesList;
  const newRoutesList: RouteRecordRaw[] = [];
  routesList.forEach((v: RouteRecordRaw) => {
    if (v.path === "/") {
      newRoutesList.push({
        component: v.component,
        name: v.name,
        path: v.path,
        redirect: v.redirect,
        meta: v.meta,
        children: []
      });
    } else {
      newRoutesList[0].children.push({ ...v });
    }
  });
  return newRoutesList;
}

/** 处理缓存路由（添加、删除、刷新） */
function handleAliveRoute(matched: RouteRecordNormalized[], mode?: string) {
  switch (mode) {
    case "add":
      matched.forEach(v => {
        usePermissionStoreHook().cacheOperate({ mode: "add", name: v.name });
      });
      break;
    case "delete":
      usePermissionStoreHook().cacheOperate({
        mode: "delete",
        name: matched[matched.length - 1].name
      });
      break;
    default:
      usePermissionStoreHook().cacheOperate({
        mode: "delete",
        name: matched[matched.length - 1].name
      });
      useTimeoutFn(() => {
        matched.forEach(v => {
          usePermissionStoreHook().cacheOperate({ mode: "add", name: v.name });
        });
      }, 100);
  }
}

/** 过滤后端传来的动态路由 重新生成规范路由 */
function addAsyncRoutes(arrRoutes: Array<RouteRecordRaw>) {
  if (!arrRoutes || !arrRoutes.length) return;
  const modulesRoutesKeys = Object.keys(modulesRoutes);
  arrRoutes.forEach((v: RouteRecordRaw) => {
    // 将backstage属性加入meta，标识此路由为后端返回路由
    v.meta.backstage = true;
    // 父级的redirect属性取值：如果子级存在且父级的redirect属性不存在，默认取第一个子级的path；如果子级存在且父级的redirect属性存在，取存在的redirect属性，会覆盖默认值
    if (v?.children && v.children.length && !v.redirect)
      v.redirect = v.children[0].path;
    // 父级的name属性取值：如果子级存在且父级的name属性不存在，默认取第一个子级的name；如果子级存在且父级的name属性存在，取存在的name属性，会覆盖默认值
    if (v?.children && v.children.length && !v.name) {
      v.name = v.children[0].name;
    }
    if (v.meta?.frameSrc) {
      v.component = IFrame;
    } else {
      // 对后端传component组件路径和不传做兼容（如果后端传component组件路径，那么path可以随便写，如果不传，component组件路径会跟path保持一致）
      // let path = `/src/views${v.path}`;
      // if (path.substring(path.length - 1) !== "/") {
      //   path += "/index.vue";
      // }
      let path = v.path;
      if (v.path.indexOf("/lanmu_root/") === 0) {
        path = "/lanmu_root/index";
      }
      const index = v?.component
        ? modulesRoutesKeys.findIndex(ev => ev.includes(v.component as any))
        : modulesRoutesKeys.findIndex(ev =>
            ev.toLowerCase().includes(path.toLowerCase())
          );
      v.component = modulesRoutes[modulesRoutesKeys[index]];
    }
    if (v?.children && v.children.length) {
      addAsyncRoutes(v.children);
    }
  });
  return arrRoutes;
}

/** 获取路由历史模式 https://next.router.vuejs.org/zh/guide/essentials/history-mode.html */
function getHistoryMode(): RouterHistory {
  const routerHistory = loadEnv().VITE_ROUTER_HISTORY;
  // len为1 代表只有历史模式 为2 代表历史模式中存在base参数 https://next.router.vuejs.org/zh/api/#%E5%8F%82%E6%95%B0-1
  const historyMode = routerHistory.split(",");
  const leftMode = historyMode[0];
  const rightMode = historyMode[1];
  // no param
  if (historyMode.length === 1) {
    if (leftMode === "hash") {
      return createWebHashHistory("");
    } else if (leftMode === "history") {
      return createWebHistory("");
    }
  } //has param
  else if (historyMode.length === 2) {
    if (leftMode === "hash") {
      return createWebHashHistory(rightMode);
    } else if (leftMode === "h5") {
      return createWebHistory(rightMode);
    }
  }
}

/** 是否有权限 */
function hasPermissions(value: Array<string>): boolean {
  if (value && value instanceof Array && value.length > 0) {
    const roles = usePermissionStoreHook().buttonAuth;
    const permissionRoles = value;

    const hasPermission = roles.some(role => {
      return permissionRoles.includes(role);
    });

    if (!hasPermission) {
      return false;
    }
    return true;
  } else {
    return false;
  }
}

const isArticleTable = (path = "") => {
  const regex = /\/article_table\/\d+$/g; // 匹配 "/article_table/" 后跟一个或多个数字
  const matches = path.match(regex);
  return !!matches;
};

const isArticleRoot = (path = "") => {
  const regex = /\/article_root$/g;
  const matches = path.match(regex);
  return !!matches;
};

export {
  isArticleTable,
  isArticleRoot,
  ascending,
  filterTree,
  initRouter,
  hasPermissions,
  getHistoryMode,
  addAsyncRoutes,
  delAliveRoutes,
  getParentPaths,
  findRouteByPath,
  handleAliveRoute,
  formatTwoStageRoutes,
  formatFlatteningRoutes
};
