import iconifyIconOffline from "./src/iconifyIconOffline";
import fontIcon from "./src/iconfont";

/** 离线图标组件 */
const IconifyIconOffline = iconifyIconOffline;

/** iconfont组件 */
const FontIcon = fontIcon;

export { IconifyIconOffline, FontIcon };
