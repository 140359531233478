import { http } from "../utils/http";
import { loadEnv } from "@build/index";

const path = "";
const paiPath = "/open/pai";
const { VITE_APP_PAI, VITE_APP_PAI_API } = loadEnv();
/** 获取oss配置 */
export const getCredentials = (data: object) => {
  return http.request("post", `${VITE_APP_PAI}/media/upload/getCredentials`, {
    data
  });
};

/** 获取oss配置裁剪 /file/getOssInfo */
export const getOssInfo = (data: object) => {
  return http.request("get", `${VITE_APP_PAI_API}file/getOssInfo`, {
    data
  });
};

/** 获取资源列表 */
export const getBrowseList = (data: object) => {
  return http.request("post", `${VITE_APP_PAI}/media/search/browseList`, {
    data
  });
};

/** 上传 */
export const postResource = (data: object) => {
  return http.request("post", `${VITE_APP_PAI}/media/upload/resource`, {
    data
  });
};

/** 是否含有文章图 */
export const hasContentMedia = (data: object) => {
  return http.request("post", `${VITE_APP_PAI}/media/search/hasContentMedia`, {
    data
  });
};

/** 查询当前上传视频的状态 */
export const getUploadStatus = (data: object) => {
  return http.request(
    "post",
    `${VITE_APP_PAI}/media/upload/video/getUploadStatus`,
    {
      data
    }
  );
};

/** 查询当前视频是否可以打栏包 */
export const checkVideo = (data: object) => {
  return http.request("post", `${VITE_APP_PAI}/content/video/checkVideo`, {
    data
  });
};

/** 批量下载图片到oss */
export const batchDownImage = (data: object) => {
  return http.request("post", `${VITE_APP_PAI}/media/upload/batchDownImage`, {
    data
  });
};

// 修改媒资库临时ID
export const updateMediaTemporaryId = (data: object) => {
  return http.request("post", `${VITE_APP_PAI}/media/updateMediaTemporaryId`, {
    data
  });
};
