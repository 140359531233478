import { usePermissionStoreHook } from "@/store/modules/permission";
import type { DirectiveBinding } from "vue";
import { Directive } from "vue";

export const auth: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const { value } = binding;
    if (value) {
      let hasAuth = false;
      if (typeof value === "string") {
        hasAuth = usePermissionStoreHook().buttonAuth.includes(value);
      }
      if (Array.isArray(value)) {
        hasAuth = value.some(i =>
          usePermissionStoreHook().buttonAuth.includes(i)
        );
      }
      if (!hasAuth) {
        el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("need roles! Like v-auth=\"['admin','test']\"");
    }
  }
};
