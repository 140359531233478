import type { RouteConfigsTable } from "/#/index";
import pageRouterName from "@/router/pageRouterName";

const aiVideo: RouteConfigsTable[] = [
  {
    path: "/aiVideo/edit",
    name: pageRouterName("aiVideoEdit"),
    component: () => import("@/views/aiVideo/edit/index.vue"),
    meta: {
      rank: 2,
      title: "一键成片",
      keepAlive: true,
      showLink: false
    }
  },
  {
    path: "/aiVideo/clip",
    name: pageRouterName("aiVideoEditClip"),
    component: () => import("@/views/aiVideo/clip/index.vue"),
    meta: {
      rank: 2,
      title: "编辑片段",
      keepAlive: true,
      showLink: false
    }
  }
];

export default aiVideo;
