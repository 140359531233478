import OSS from "ali-oss";
import { urlTranslation } from "./utils";
import { getCredentials, getOssInfo } from "@/api/upload";

class Oss {
  type = null;
  mediaType = null;
  client = null;
  currentInstance = null;
  ossConfig = {
    region: "",
    bucket: "",
    accessKeyId: "",
    accessKeySecret: "",
    stsToken: ""
  };
  prefix = null;
  expiration = null;

  constructor(config = { type: "sh" }) {
    this.type = config.type;
    this.mediaType = config.mediaType;
  }

  initOssKey() {
    if (this.expiration) {
      if (new Date() > new Date(this.expiration)) {
        this.getAliToken();
      }
    } else {
      this.getAliToken();
    }
  }

  getAliToken() {
    console.log(this.type, "type,type");
    const fn = this.type === "media" ? getCredentials : getOssInfo;
    const mediaType =
      this.type === "media"
        ? {
            mediaType: this.mediaType
          }
        : {};
    return new Promise((resolve, reject) => {
      fn(mediaType)
        .then(res => {
          if (res.code === 200) {
            const { expiration, accessKeyId, accessKeySecret, securityToken } =
              res.data.credentials;
            this.expiration = expiration;
            this.prefix =
              this.mediaType === "IMAGE"
                ? res.data.imagePrefix
                : this.mediaType === "VIDEO"
                ? res.data.videoPrefix
                : this.mediaType === "AUDIO"
                ? res.data.audioPrefix
                : "";
            this.ossConfig = {
              region: res.data.region,
              bucket: res.data.bucket,
              accessKeyId: accessKeyId,
              accessKeySecret: accessKeySecret,
              stsToken: securityToken
            };
            // client初始化
            this.client = new OSS(this.ossConfig);
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch(err => {
          console.log(err);
          reject(false);
        });
    });
  }

  random_string(len) {
    len = len || 32;
    const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678";
    const maxPos = chars.length;
    let pwd = "";
    for (let i = 0; i < len; i++) {
      pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  }

  async uploadFile(file, prefix, type = false, progressCallback) {
    if (new Date() > new Date(this.expiration)) {
      await this.getAliToken();
    }
    const objKey = (this.prefix || prefix) + file.lastModified + "_";

    const random_name =
      this.random_string(6) +
      "_" +
      new Date().getTime() +
      "." +
      file.name?.split(".").pop();

    return new Promise((resolve, reject) => {
      this.client
        .multipartUpload(objKey + random_name, file, {
          progress: (percentage, instance) => {
            // this.currentInstance = instance;
            progressCallback && progressCallback(percentage);
            return this.progress;
          },
          headers: {
            "Content-Disposition":
              "attachment; filename=" + encodeURIComponent(file.name)
          },
          parallel: 4,
          partSize: 1024 * 1024
        })
        .then(result => {
          let _str = result.res.requestUrls[0];
          if (_str.lastIndexOf("?") > 0) {
            _str = _str.substring(0, _str.lastIndexOf("?"));
          }

          // + consts.ossSuffix.coverImg
          const coverImg = type ? _str : _str; // 给图片添加后缀 不然将无法读取
          // ElMessage.success("上传成功");
          const urls = urlTranslation(coverImg, this.type);
          // console.log(result, type, urls, "🥒🥒", _str);

          resolve({
            url: urls,
            reqUrl: objKey + random_name,
            ossUrl: coverImg
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  async getDownLoadUrl(filename, filePath) {
    if (new Date() > new Date(this.expiration)) {
      await this.getAliToken();
    }
    return new Promise(resolve => {
      const response = {
        "content-disposition": `attachment; filename=${encodeURIComponent(
          filename
        )}`
      };
      const url = this.client.signatureUrl(filePath, { response });
      resolve(url);
    });
  }

  progress() {
    return function (done) {
      done();
    };
  }

  abort() {
    /*try {
     this.client.abortMultipartUpload(
     this.currentInstance.name,
     this.currentInstance.uploadId
     );
     } catch (e) {
     console.log("取消上传失败");
     }*/

    try {
      this.client.cancel();
    } catch (e) {
      console.log("取消上传失败");
    }
  }
}

export default Oss;
