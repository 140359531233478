import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { loadEnv } from "@build/index";

const { VITE_APP_DOMAIN, VITE_APP_COOKIE_NAME } = loadEnv();

const GlobalCustom = {
  defaultStr:
    "YWJjZGVmZ2hpamtsbW5vcHFyc3R1dnd4eXpBQkNERUZHSElKS0xNTk9QUVJTVFVWV1hZWg==",
  defaultStr2: "X3doaXRlbGlzdF9zZWxmX3NlcnZpY2Vz",
  getGmtTimestamp: function () {
    const d = new Date();
    const localTime = d.getTime();
    return localTime;
  },
  strToBase64: function (str) {
    const base64Str = window.btoa(str).toString("base64");
    return base64Str;
  },
  base64ToStr: function (base64Str) {
    const str = window.atob(base64Str).toString();
    return str;
  },
  encAes: function (message) {
    let key = CryptoJS.enc.Utf8.parse(
      GlobalCustom.base64ToStr(GlobalCustom.defaultStr2)
    );
    let srcs = CryptoJS.enc.Utf8.parse(message);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  },
  decAes: function (encryptStr) {
    let key = CryptoJS.enc.Utf8.parse(
      GlobalCustom.base64ToStr(GlobalCustom.defaultStr2)
    );
    let decrypt = CryptoJS.AES.decrypt(encryptStr, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  },
  encDes: function (message) {
    const key = GlobalCustom.base64ToStr(GlobalCustom.defaultStr2);
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.DES.encrypt(message, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  },
  decDes: function (ciphertext) {
    const key = GlobalCustom.base64ToStr(GlobalCustom.defaultStr2);
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const decrypted = CryptoJS.DES.decrypt(
      {
        ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
      },
      keyHex,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  },
  addStorage: function (key, value) {
    console.log("addStorage:" + key + "," + value);
    localStorage.setItem(key, value);
    //sessionStorage.setItem(key,value);
  },
  getStorage: function (key) {
    console.log("getStorage:" + key + ":" + localStorage.getItem(key));
    return localStorage.getItem(key);
    //sessionStorage.getItem(key);
  },
  removeStorage: function (key) {
    console.log("removeStorage:" + key);
    localStorage.removeItem(key);
    //sessionStorage.removeItem(key);
  },

  randomStr: function (length) {
    const str = GlobalCustom.base64ToStr(GlobalCustom.defaultStr);
    let result = "";
    for (let i = length; i > 0; --i) {
      result += str[Math.floor(Math.random() * str.length)];
    }
    return result;
  },

  addValues: function (id, name, token) {
    let random = GlobalCustom.randomStr(6);
    random = random + "-";
    const cookieDomain = VITE_APP_DOMAIN;
    const cookieName = VITE_APP_COOKIE_NAME;
    if (cookieDomain) {
      Cookies.set(cookieName + "0", GlobalCustom.encDes(random), {
        path: "/",
        domain: cookieDomain
      });
      Cookies.set(cookieName + "1", GlobalCustom.encDes(random + id), {
        path: "/",
        domain: cookieDomain
      });
      Cookies.set(cookieName + "2", GlobalCustom.encDes(random + name), {
        path: "/",
        domain: cookieDomain
      });
      Cookies.set(cookieName + "3", GlobalCustom.encDes(random + token), {
        path: "/",
        domain: cookieDomain
      });
    } else {
      Cookies.set(cookieName + "0", GlobalCustom.encDes(random), { path: "/" });
      Cookies.set(cookieName + "1", GlobalCustom.encDes(random + id), {
        path: "/"
      });
      Cookies.set(cookieName + "2", GlobalCustom.encDes(random + name), {
        path: "/"
      });
      Cookies.set(cookieName + "3", GlobalCustom.encDes(random + token), {
        path: "/"
      });
    }
  },
  removeValues: function () {
    const cookieDomain = VITE_APP_DOMAIN;
    const cookieName = VITE_APP_COOKIE_NAME;
    if (cookieDomain) {
      Cookies.set(cookieName + "0", null, { path: "/", domain: cookieDomain });
      Cookies.set(cookieName + "1", null, { path: "/", domain: cookieDomain });
      Cookies.set(cookieName + "2", null, { path: "/", domain: cookieDomain });
      Cookies.set(cookieName + "3", null, { path: "/", domain: cookieDomain });
    } else {
      Cookies.set(cookieName + "0", null, { path: "/" });
      Cookies.set(cookieName + "1", null, { path: "/" });
      Cookies.set(cookieName + "2", null, { path: "/" });
      Cookies.set(cookieName + "3", null, { path: "/" });
    }
  },
  getValue: function (key) {
    let val = Cookies.get(VITE_APP_COOKIE_NAME + key);
    if (val) {
      val = GlobalCustom.decDes(val);
      if (key > 0) {
        return val.substr(7);
      } else {
        return val;
      }
    } else {
      return null;
    }
  },
  getQueryString: function (name) {
    const reg = new RegExp("[?&]" + name + "=([^&#]*)", "i");
    const res = window.location.href.match(reg);

    if (res && res.length > 1) {
      return decodeURIComponent(res[1]);
    }
    return "";
  },
  toMain: function () {
    const server = GlobalCustom.getQueryString("s");
    if (server == "task") {
      window.location.href = process.env.VUE_APP_MAIN_TASK;
    } else {
      window.location.href = process.env.VUE_APP_MAIN_PAI;
    }
  },
  dateFormat(fmt, date) {
    let ret;
    const opt = {
      "Y+": date.getFullYear().toString(), // 年
      "m+": (date.getMonth() + 1).toString(), // 月
      "d+": date.getDate().toString(), // 日
      "H+": date.getHours().toString(), // 时
      "M+": date.getMinutes().toString(), // 分
      "S+": date.getSeconds().toString(), // 秒
      "F+": date.getMilliseconds().toString() //毫秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(
          ret[1],
          ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
        );
      }
    }
    return fmt;
  },
  getFullDate(date) {
    return this.dateFormat("YYYYmmddHHMMSSFFF", date);
  },
  randomString(length) {
    var str = "abcdefghijklmnopqrstuvwxyz";
    var result = "";
    for (var i = length; i > 0; --i) {
      result += str[Math.floor(Math.random() * str.length)];
    }
    return result;
  }
};

export default GlobalCustom;
