import FontFaceObserver from "fontfaceobserver";
import { useBase64, useClipboard, useFileDialog } from "@vueuse/core";
import { ElMessage } from "element-plus";
import fontList from "@/views/aiPoster/assets/fonts/font";

interface Font {
  type: string;
  fontFamily: string;
}

/**
 * @description: 图片文件转字符串
 * @param {Blob|File} file 文件
 * @return {String}
 */
export function getImgStr(file: File | Blob): Promise<FileReader["result"]> {
  return useBase64(file).promise.value;
}

function settlePromises(promises) {
  const settledPromises = promises.map(promise => {
    return promise
      .then(value => ({ status: "fulfilled", value }))
      .catch(reason => ({ status: "rejected", reason }));
  });

  return Promise.all(settledPromises);
}

/**
 * @description: 根据json模板下载字体文件
 * @return {Promise}
 * @param obj
 */
export function downFontByJSON(obj) {
  const skipFonts = ["arial", "Microsoft YaHei"];
  const fontFamilies: string[] = obj
    .objects!.filter(
      (item: Font) =>
        // 为text 并且不为包含字体
        // eslint-disable-next-line implicit-arrow-linebreak
        item.type.includes("text") &&
        !skipFonts.includes(item.fontFamily) &&
        item.fontFamily &&
        fontList.some(fi => fi.fontFamily === item.fontFamily)
    )
    .map((item: Font) => item.fontFamily);
  const fontFamiliesAll = fontFamilies.map(fontName => {
    const font = new FontFaceObserver(fontName);
    return font.load(null, 20000);
  });
  return settlePromises(fontFamiliesAll);
}

/**
 * @description: 选择文件
 * @param {Object} options accept = '', capture = '', multiple = false
 * @return {Promise}
 */
export function selectFiles(options: {
  accept?: string;
  capture?: string;
  multiple?: boolean;
  limit?: number;
}): Promise<FileList | null> {
  return new Promise((resolve, reject) => {
    const { onChange, open } = useFileDialog(options);
    onChange(files => {
      if (files.length > options.limit) {
        ElMessage.warning("超出上传数量限制");
        reject();
      }
      resolve(files);
    });
    open();
  });
}

/**
 * @description: 前端下载文件
 * @param {String} fileStr
 * @param fileName
 */
export function downFile(fileStr: string, fileName: string) {
  const anchorEl = document.createElement("a");
  anchorEl.href = fileStr;
  anchorEl.download = fileName;
  document.body.appendChild(anchorEl); // required for firefox
  anchorEl.click();
  anchorEl.remove();
}

/**
 * @description: 创建图片元素
 * @param {String} str 图片地址或者base64图片
 * @return {Promise} element 图片元素
 */
export function insertImgFile(str: string) {
  return new Promise(resolve => {
    const imgEl = document.createElement("img");
    imgEl.src = str;
    // 插入页面
    document.body.appendChild(imgEl);
    imgEl.onload = () => {
      resolve(imgEl);
    };
  });
}

/**
 * Copying text to the clipboard
 * @param source Copy source
 * @param options Copy options
 * @returns Promise that resolves when the text is copied successfully, or rejects when the copy fails.
 */
export const clipboardText = async (
  source: string,
  options?: Parameters<typeof useClipboard>[0]
) => {
  try {
    await useClipboard({ source, ...options }).copy();
    ElMessage.success("复制成功");
  } catch (error) {
    ElMessage.error("复制失败");
    throw error;
  }
};

export const createInitEmptyTemplate = (
  initData = {
    config: {
      width: 750,
      height: 1624
    },
    objects: []
  }
) => {
  const expand = {
    version: "5.3.0",
    objects: [
      {
        type: "rect",
        version: "5.3.0",
        originX: "left",
        originY: "top",
        left: 0,
        top: 0,
        width: initData.config.width,
        height: initData.config.height,
        fill: "#ffffff",
        stroke: null,
        strokeWidth: 1,
        strokeDashArray: null,
        strokeLineCap: "butt",
        strokeDashOffset: 0,
        strokeLineJoin: "miter",
        strokeUniform: false,
        strokeMiterLimit: 4,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        flipX: false,
        flipY: false,
        opacity: 1,
        shadow: null,
        visible: true,
        backgroundColor: "",
        fillRule: "nonzero",
        paintFirst: "fill",
        globalCompositeOperation: "source-over",
        skewX: 0,
        skewY: 0,
        rx: 0,
        ry: 0,
        id: "workspace",
        selectable: false,
        hasControls: false
      },
      ...initData.objects
    ],
    clipPath: {
      type: "rect",
      version: "5.3.0",
      originX: "left",
      originY: "top",
      left: 0,
      top: 0,
      width: initData.config.width,
      height: initData.config.height,
      fill: "#ffffff",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      rx: 0,
      ry: 0,
      selectable: true,
      hasControls: true
    },
    background: "#ffffff"
  };
  return {
    aiBackImg: "",
    posterImg: "",
    categoryId: "",
    expand: JSON.stringify(expand),
    width: initData.config.width,
    height: initData.config.height,
    name: "未命名文件"
  };
};

function DOWLOAD_FILE_NAME(url, filename) {
  // 文件名称
  let fname = filename;
  // 没有文件名同时链接有值
  if (!fname && url) {
    // 获得最后一个斜杠坐标
    const index = url.lastIndexOf("/");
    // 从斜杆后一个坐标开始截取
    fname = url.substring(index + 1);
  }
  // 返回
  return fname;
}

export function DOWLOAD_FILE_URL_PRO(
  url,
  filename = `${new Date().getTime()}`
) {
  // Promise
  return new Promise((resolve, reject) => {
    // 获取链接二进制数据
    fetch(url)
      .then(res => {
        // 获得二进制数据
        res
          .blob()
          .then(blob => {
            // 创建一个a节点
            const a = document.createElement("a");
            // 创建一个可供下载链接
            const url = window.URL.createObjectURL(blob);
            // 将需要下载的URL赋值给a节点的href
            a.href = url;
            // 设置节点的download属性值
            a.download = DOWLOAD_FILE_NAME(url, filename);
            // 触发点击事件
            a.click();
            // 释放
            window.URL.revokeObjectURL(url);
            // 获取成功
            resolve("");
          })
          .catch(err => {
            // 获取失败
            reject(err);
          });
      })
      .catch(err => {
        // 获取失败
        reject(err);
      });
  });
}

export function addTimestampToImageUrl(url) {
  if (!url) return "";
  const timestamp = Date.now();
  if (url.includes("time=")) {
    // 如果图片地址中已经存在 time 字段，则用新的时间戳替换它
    return url.replace(/time=\d+/, `time=${timestamp}`);
  } else {
    // 如果图片地址中不存在 time 字段，则直接添加时间戳
    return `${url}${url.includes("?") ? "&" : "?"}time=${timestamp}`;
  }
}

export function fetchAndConvertToBase64(url) {
  return fetch(url)
    .then(response => response.blob())
    .then(blob => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

export const isSharpe = (type = "") => {
  return ["line", "rect", "circle"].includes(type);
};

export const calculateScaleToFit = (originalSize, maxSize) => {
  const widthScale = maxSize.width / originalSize.width;
  const heightScale = maxSize.height / originalSize.height;

  return Math.min(widthScale, heightScale);
};

export const changeSeniorPrompt = (str, referImg) => {
  if (!str) return "";
  let seniorPrompt = str as any;
  if (referImg) {
    try {
      seniorPrompt = JSON.parse(str);
      seniorPrompt.referImg = referImg;
      delete seniorPrompt.init_images;
      seniorPrompt = JSON.stringify(seniorPrompt);
      // eslint-disable-next-line no-empty
    } catch {}
  }
  return seniorPrompt;
};
