<template>
  <div>
    <el-dialog
      v-model="dialogVisible"
      :title="title"
      width="950px"
      :before-close="handleClose"
      style="height: 800px; margin: 5vh auto"
    >
      <PaiTable
        v-model:loading="loading"
        ref="paiTableRef"
        :columns="columns"
        :reqFun="fn"
        :reqParams="{ isPublished: 1, ...form, contDbId: 0 }"
        selection
        isDalog
        @selection-change="val => (selectTable = val)"
        style="height: 500px"
      >
        <template #search>
          <el-form :model="form" :inline="true" ref="formRef">
            <el-form-item label="关键词：" prop="keyword">
              <el-input
                v-model="form.keyword"
                placeholder="请输入关键词或#ID进行搜索"
                clearable
                style="width: 220px"
              />
            </el-form-item>
            <el-form-item
              label="报名活动："
              prop="keyword"
              v-if="props.isActivity"
            >
              <el-input
                v-model="form.activityKeyword"
                placeholder="请输入活动关键词搜索"
              />
            </el-form-item>

            <el-form-item label="栏目" prop="nodeId" v-if="!props.isActivity">
              <el-tree-select
                v-model="form.nodeId"
                :data="columnTreeData"
                highlight-current
                style="width: 300px"
                check-strictly
                default-expand-all
              />
            </el-form-item>
            <!-- <el-form-item label="报名活动:" prop="activityId">
              <el-tree-select
                v-model="form.activityId"
                :data="activityData"
                highlight-current
                style="width: 100%"
                check-strictly
                default-expand-all
              />
            </el-form-item> -->

            <el-form-item>
              <el-button type="primary" @click="loading = true">
                查询
              </el-button>
              <el-button type="primary" @click="onSubmit"> 确定选择 </el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #title="scope">
          {{ scope.row.shortTitle || scope.row.title }}
        </template>
        <template #siteApp="scope">
          {{ scope.row.siteApp === 1 ? "APP" : null }}
          {{ scope.row.siteWww === 1 ? "WWW" : null }}
        </template>
        <template #operate="scope">
          <!-- <el-button @click="onPreview(scope.row)">预览</el-button> -->
          <ArticlePreview :row="scope.row" />
        </template>
      </PaiTable>
    </el-dialog>
    <QRcodeCom v-model:visible="QRcodeComVisible" />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, reactive } from "vue";
import { searchQueryEdit, nodeList, activityContList } from "@/api/power";
import { nodecontPreviewSign } from "@/api/lanmu";
import { loadEnv } from "@build/index";
import { perviewH5 } from "@/utils/index";
import { ElMessage } from "element-plus";
import QRcodeCom from "@/components/QRcodeCom/index.vue";

const QRcodeComVisible = ref("");

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: "选择文章"
  },
  openNode: { type: Boolean },
  isActivity: {
    type: Boolean,
    default: false
  },
  activityId: {
    type: Number,
    default: 0
  },
  type: {
    type: Number,
    default: 0
  }
});
const emits = defineEmits(["update:visible", "onSelectData"]);
const dialogVisible = ref(false);
const loading = ref(false);
const selectTable = ref([]);
const formRef = ref();

const fn = ref(searchQueryEdit);
const handleClose = () => {
  emits("update:visible");
};

const columnTreeData = ref([]);
const activityData = ref([]);

const form = reactive({
  keyword: "",
  nodeId: "",
  activityId: 0,
  type: 3,
  word: "",
  activityKeyword: ""
});
const columns = ref([
  { prop: "contId", label: "ID", width: "100" },
  { prop: "title", label: "标题", slot: "title" },
  { prop: "nodeName", label: "所属栏目" },
  { prop: "createUserName", label: "作者" },
  { prop: "updateTime", label: "发布时间", width: 180 },
  { prop: "firstPublishTime", label: "首次发布时间", width: 180 },
  { prop: "operate", label: "操作", width: 100, slot: "operate" }
]);

const onSubmit = () => {
  if (selectTable.value.length) {
    emits("onSelectData", selectTable.value);
    emits("update:visible");
  } else {
    ElMessage.warning("请选择文章！");
  }
};

const handleHeavy = (arr, callback, childrenKey = "children", level) => {
  Array.isArray(arr) &&
    arr.forEach(item => {
      callback && callback(item, level);
      if (Array.isArray(item[childrenKey])) {
        handleHeavy(item[childrenKey], callback, childrenKey, level + 1);
      }
    });
};
const { VITE_APP_PAPER_WAP } = loadEnv();
//  预览跳转，需要在后面跟上参数?to=preview，否则无效
const onPreview = data => {
  console.log(data);

  if (VITE_APP_PAPER_WAP) {
    nodecontPreviewSign({
      contentEditId: data.contId
    }).then(res => {
      if (res.code === 200) {
        if (props.type === 3) {
          window.open(
            `${VITE_APP_PAPER_WAP}/activityWorks/${data.contId}?sign=${res.data.sign}&timestamp=${res.data.timestamp}`
          );
        } else {
          QRcodeComVisible.value = perviewH5(
            data.contId,
            data.contType,
            data.link,
            res.data
          );
        }
      }
    });
  }
};
const getMenuTree = () => {
  nodeList({ type: 0 }).then(res => {
    if (res.code === 200) {
      handleHeavy(
        res.data,
        item => {
          item.label = item.name;
          item.value = item.id;
        },
        "children",
        null
      );
      columnTreeData.value = res.data;
    }
  });
};
const getActiveTree = () => {
  nodeList({}).then(res => {
    if (res.code === 200) {
      handleHeavy(
        res.data,
        item => {
          item.label = item.name;
          item.value = item.id;
        },
        "children",
        null
      );
      columnTreeData.value = res.data;
    }
  });
};

watch(
  () => props.visible,
  newVal => {
    dialogVisible.value = newVal;
    console.log(props.activityId, "eeeeee");

    if (newVal) {
      if (props.isActivity) {
        fn.value = activityContList;
        form.activityId = props.activityId;
        form.type = props.type;
        // getActiveTree();
      } else {
        getMenuTree();
      }
      loading.value = true;
    } else {
      formRef.value?.resetFields();
    }
  }
);
</script>

<style scoped lang="scss"></style>
