<template>
  <el-button type="primary" @click="onCopy" plain>复制所选ID</el-button>
</template>

<script lang="ts" setup>
import { ElMessage } from "element-plus";

const props = defineProps({
  useKey: {
    type: String,
    default: "id"
  },
  selectionData: {
    type: Array,
    default: () => []
  },

  delimiter: {
    type: String,
    default: ","
  }
});

const onCopy = () => {
  if (!props.selectionData.length) {
    return ElMessage.warning("请选择要复制的数据");
  }
  const materialNoList = props.selectionData.map(
    (item: any) => item[props.useKey]
  );

  console.log(
    props.selectionData,
    "🍓🍓🍓🍓",
    props.useKey,
    props.selectionData
  );
  const input = document.createElement("input");
  input.value = materialNoList.join(props.delimiter);
  document.body.appendChild(input);
  input.select();
  document.execCommand("Copy");
  document.body.removeChild(input);
  ElMessage.success("复制成功");
};
</script>

<style lang="scss" scoped></style>
