import type { RouteConfigsTable } from "/#/index";

const interviewRouter: RouteConfigsTable = {
  // path: "/paper_db_root",
  // meta: {
  //   title: "小样管理",
  //   showLink: false
  // },
  // children: [
  //   {
  //     path: "/paper_db_root",
  //     meta: {
  //       title: "小样管理"
  //     },
  //     children: [
  //       {
  //         path: "/paper_db_root",
  //         name: "Paper_db_root",
  //         component: () =>
  //           import("@/views/myManuscriptLibrary/paper_db_root/index.vue"),
  //         meta: {
  //           title: "小样管理",
  //           showParent: true,
  //           keepAlive: true
  //         }
  //       }
  //     ]
  //   }
  // ]
  path: "/paper_db_root_sub",
  name: "Paper_db_root",
  component: () =>
    import("@/views/myManuscriptLibrary/paper_db_root/index.vue"),
  meta: {
    rank: 1,
    title: "小样管理",
    showLink: false,
    keepAlive: true
  }
};

export default interviewRouter;
