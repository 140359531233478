import type { RouteConfigsTable } from "/#/index";

const lanmuCreateRouter: RouteConfigsTable = {
  path: "/editRoute/:contId/:id",
  name: "Lanmu_editRoute",
  component: () => import("@/views/article_root/index.vue"),
  meta: {
    rank: 1,
    title: "新建文章",
    showLink: false,
    keepAlive: true
  }
};

export default lanmuCreateRouter;
