<template>
  <div
    class="preview_img"
    :style="{ display: dialogVisible ? 'block' : 'none' }"
  >
    <div class="preview_img_mask" @click.stop="onClose" />
    <img :src="src" class="main_img" />
    <div class="close_div" @click="handleClose">
      <el-icon style="color: #fff; font-size: 24px"><Close /></el-icon>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  src: {
    type: [String]
  }
});
const dialogVisible = ref(false);

const emits = defineEmits(["update:visible"]);

const onClose = () => {
  emits("update:visible", false);
};

const handleClose = () => {
  emits("update:visible", false);
};

watch(
  () => props.visible,
  newVal => {
    dialogVisible.value = newVal;
  }
);
</script>

<style scoped lang="scss">
.preview_img {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;

  .preview_img_mask {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    background: #000;
  }

  .main_img {
    max-width: 80vw;
    max-height: 80vh;
    min-width: 400px;
    min-height: 300px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .close_div {
    width: 44px;
    height: 44px;
    background: #606266;
    position: absolute;
    top: 40px;
    right: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
