import type { RouteConfigsTable } from "/#/index";

const defaultRouter: RouteConfigsTable = {
  path: "/media",
  redirect: "/media/individual",
  meta: {
    icon: "icon-pp-menu-rankinglist",
    title: "媒资库",
    rank: 1,
    showLink: false
  },
  children: [
    {
      path: "/media/individual",
      name: "IndividualMedia",
      component: () => import("@/views/media/individual/index.vue"),
      meta: {
        title: "个人库",
        keepAlive: true,
        showLink: false
      }
    },
    {
      path: "/media/enterprise",
      name: "EnterpriseMedia",
      component: () => import("@/views/media/enterprise/index.vue"),
      meta: {
        title: "企业库",
        keepAlive: true,
        showLink: false
      }
    },
    {
      path: "/media/recycle",
      name: "Recycle",
      component: () => import("@/views/media/recycle/index.vue"),
      meta: {
        title: "",
        keepAlive: false,
        showLink: false
      }
    },
    {
      path: "/media/statistics",
      name: "Statistics",
      component: () => import("@/views/media/statistics/index.vue"),
      meta: {
        title: "数据统计",
        keepAlive: true,
        showLink: false
      }
    }
  ]
};

export default defaultRouter;
