import { http } from "../utils/http";
import type { ResObj } from "/#/index";

/** 获取列表 */
export const getQueryNodeContList = (data: object) => {
  return http.request<ResObj>("post", `/nodecont/queryNodeContList`, { data });
};

/** 提交审核 */
export const getSubmitAudit = (data: object) => {
  return http.request<ResObj>("post", `/nodecont/submitAudit`, { data });
};

/** 发布 */
export const getPublish = (data: object) => {
  return http.request<ResObj>("post", `/content/publish`, { data });
};

/** 撤回 */
export const getRecallCont = (data: object) => {
  return http.request<ResObj>("post", `/nodecont/recallCont`, { data });
};

/** 删除 */
export const getDeleteCont = (data: object) => {
  return http.request<ResObj>("post", `/nodecont/deleteCont`, { data });
};

/** 加到瀑布流精选 */
export const getCreateWaterfallFlow = (data: object) => {
  return http.request<ResObj>("post", `/waterfallFlow/createWaterfallFlow`, {
    data
  });
};

/** 审核并发布 */
export const getAuditAndPublish = (data: object) => {
  return http.request<ResObj>("post", `/nodecont/auditAndPublish`, { data });
};

/** 移动到获取菜单树 */
export const getNodeList = (data?) => {
  return http.request<ResObj>("get", `/node/nodeList`, { params: data });
};

/** 执行移动到操作 */
export const getMoveCont = (data: object) => {
  return http.request<ResObj>("post", `/nodecont/moveCont`, { data });
};

/** 推荐到首页 */
export const getLinkCont = (data: object) => {
  return http.request<ResObj>("post", `/nodecont/linkCont`, { data });
};

/** 修改首次发布时间排序 */
export const getCustomPublishTime = (data: object) => {
  return http.request<ResObj>("post", `/content/customPublishTime`, { data });
};

/** 推荐置顶相关 */
/** 列表 */
export const getOperationPublishAllList = (data: object) => {
  return http.request<ResObj>("post", `/contEdit/getOperationPublishAllList`, {
    data
  });
};

/** 精选瀑布流 */
/** 列表 */
export const getWaterfallFlowList = (data: object) => {
  return http.request<ResObj>("post", `/waterfallFlow/list`, {
    data
  });
};

/** 查询 */
export const getOperationPublishContList = (data: object) => {
  return http.request<ResObj>("post", `/contEdit/getOperationPublishContList`, {
    data
  });
};

/** 保存操作 */
export const getSaveOperationPublish = (data: object) => {
  return http.request<ResObj>("post", `/contEdit/saveOperationPublish`, {
    data
  });
};

/** 操作日志 */
export const getOperationPublishAllLog = (data: object) => {
  return http.request<ResObj>("post", `/contEdit/getOperationPublishAllLog`, {
    data
  });
};

/** 瀑布流操作日志 */
export const getWaterfallFlowLog = (data: object) => {
  return http.request<ResObj>("post", `/waterfallFlow/optList`, {
    data
  });
};

/** 稿件列表 */
export const getQueryEdit = (data: object) => {
  return http.request<ResObj>("post", `/search/queryEdit`, { data });
};

/** 工作圈 */
export const getWorkShopList = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/jptnews-manage-content-server/workShop/getWorkShopList`,
    { data }
  );
};

/** 工作圈生效 */
export const getSaveWorkShop = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/jptnews-manage-content-server/workShop/saveWorkShop`,
    { data }
  );
};

/** 获取记者 */
export const getReporterList = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/jptnews-manage-user-server/clientUser/getReporterList`,
    { data }
  );
};
/** 保存文章 */
export const saveEssay = (data: object) => {
  return http.request<ResObj>("post", `/content/save`, { data });
};
/** 获取文章 */
export const getNewsDetail = (data: object) => {
  return http.request<ResObj>("post", `/content/getNewsDetail`, { data });
};

/** 报纸获取文章 */
export const sampleGetNewsDetail = (data: object) => {
  return http.request<ResObj>("post", `/paper/small/sample/getNewsDetail`, {
    data
  });
};
/** 审核发布 */
export const auditAndPublish = (data: object) => {
  return http.request<ResObj>("post", `/nodecont/auditAndPublish`, { data });
};
/** 获取视频直播表格 */
export const getLiveContentList = (data: object) => {
  return http.request<ResObj>("post", `/content/getLiveContentList`, { data });
};

/** 获取原创直播表格 */
export const originGetLiveContentList = (data: object) => {
  return http.request<ResObj>("post", `/content/origin/getLiveContentList`, {
    data
  });
};

/** 保存视频直播表格 */
export const saveLiveContent = (data: object) => {
  return http.request<ResObj>("post", `/content/saveLiveContent`, { data });
};

/** 保存原创视频直播表格 */
export const originSaveLiveContent = (data: object) => {
  return http.request<ResObj>("post", `/content/origin/saveLiveContent`, {
    data
  });
};

/** 删除视频直播表格 */
export const deleteLiveContent = (data: object) => {
  return http.request<ResObj>("post", `/content/deleteLiveContent`, { data });
};

/** 删除原创视频直播表格 */
export const originDeleteLiveContent = (data: object) => {
  return http.request<ResObj>("post", `/content/origin/deleteLiveContent`, {
    data
  });
};

/** 获取图片列表 */
export const imageList = (data: object) => {
  return http.request<ResObj>("post", `/imageList`, { data });
};
/** 获文章审核列表 */
export const getContentAuditRecordList = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/contentAuditRecord/getContentAuditRecordList`,
    { data }
  );
};

/** 获取人大号用户列表 */
export const clientUserGetRdhList = (data: object) => {
  return http.request<ResObj>("post", `/clientUser/getRdhList`, { data });
};

/** 删除封面图 */
export const fileDeleteContentCover = (data: object) => {
  return http.request<ResObj>("post", `/file/deleteContentCover`, {
    data
  });
};

/** 获取稿件预览签名 */
export const nodecontPreviewSign = (data: object) => {
  return http.request<ResObj>("post", `/nodecont/previewSign`, {
    data
  });
};

/** 财经日历预览页面 */
export const financeCalendarPreviewSign = (data: object) => {
  return http.request<ResObj>("post", `/nodecont/financeCalendarPreviewSign`, {
    data
  });
};

/** 删除文章图片 */
export const fileDeleteResources = (data: object) => {
  return http.request<ResObj>("post", `/file/deleteResources`, {
    data
  });
};

/** 审核直播内容 */
export const contentAuditLiveContent = (data: object) => {
  return http.request<ResObj>("post", `/content/auditLiveContent`, {
    data
  });
};

/** 发布撤回直播内容 */
export const contentPubLiveContent = (data: object) => {
  return http.request<ResObj>("post", `/content/pubLiveContent`, {
    data
  });
};

/** 审核并发布直播内容 */
export const contentAuditAndPubLiveContent = (data: object) => {
  return http.request<ResObj>("post", `/content/auditAndPubLiveContent`, {
    data
  });
};
/** 获取专题列表（包含专题栏口） */
export const querySpecialList = (data: object) => {
  return http.request<ResObj>("get", `/node/querySpecialList`, {
    params: data
  });
};

/** 展示操作 */
export const showWaterfallFlow = (data: object) => {
  return http.request<ResObj>("post", `/waterfallFlow/showWaterfallFlow`, {
    data
  });
};

/** 文章连接专题 */
export const linkContSpecial = (data: object) => {
  return http.request<ResObj>("post", `/content/linkContSpecial`, {
    data
  });
};
/** 删除文章连接专题 */
export const delContSpecial = (data: object) => {
  return http.request<ResObj>("post", `/content/delContSpecial`, {
    data
  });
};
