<template>
  <div>
    <!-- 瀑布流 -->
    <div
      :id="fallId"
      class="waterfall"
      :style="{ height: checkList.length ? '366px' : '520px' }"
    >
      <p
        class="imgupload-recommend"
        v-if="!searchParams.searchKey && searchParams.hiddenSearchKey"
      >
        根据关键词<span>{{ searchParams.hiddenSearchKey }}</span
        >为您智能推荐
      </p>
      <p
        class="imgupload-recommend-video"
        v-if="fallId === 'team' || fallId === 'self' || fallId === 'content'"
      >
        <el-breadcrumb :separator-icon="ArrowRight">
          <el-breadcrumb-item
            v-for="(item, index) in breadList"
            :key="item.id"
            :class="{
              'bread-active':
                breadList.length !== 1 && index === breadList.length - 1
            }"
            @click="handleToBack(item.id, index)"
            >{{ item.name }}</el-breadcrumb-item
          >
        </el-breadcrumb>
      </p>
      <div class="imgupload-list">
        <el-row :gutter="10">
          <el-col
            :span="6"
            v-for="item in resList"
            :key="item.id"
            @click.stop="onItemClick(item)"
          >
            <div class="imgupload-item">
              <div class="imgupload-img">
                <!-- 文件夹 -->
                <div class="imgupload-fold-bg" v-if="item.fileType != 2">
                  <img
                    src="../../img/icon_document.png"
                    alt=""
                    style="object-fit: cover"
                  />
                </div>
                <!-- 图片 -->
                <!-- <el-image
                  style="overflow: hidden"
                  v-if="item.fileType === 2 && modelType === 0"
                  :src="item.coverUrl"
                  :zoom-rate="1.2"
                  fit="cover"
                /> -->
                <el-image
                  style="width: 100%; height: 100%"
                  v-if="item.fileType === 2 && modelType === 0"
                  :src="item.coverUrl"
                  fit="cover"
                />
                <div
                  class="preview_dom"
                  v-if="item.fileType === 2 && modelType === 0"
                  @click.stop="onPreviewImg(item)"
                >
                  <img src="../../img/icon_18x18_magnify_ffffff@2x.png" />预览
                </div>
                <!-- 音频 -->
                <div
                  class="imgupload-audio-bg"
                  v-if="item.fileType === 2 && modelType === 2"
                >
                  <img
                    src="../../img/icon_music.png"
                    alt=""
                    style="object-fit: cover"
                  />
                </div>
                <!-- 视频 -->
                <div
                  class="imgupload-video-img"
                  @click="handlePreviewVideo(item.originSrc)"
                  v-if="item.fileType === 2 && modelType === 1"
                >
                  <img :src="item.coverUrl" alt="" style="object-fit: cover" />
                  <span class="imgupload-video-img-icon" />
                </div>
                <span class="imgupload-file-num" v-if="item.fileType !== 2">{{
                  item.fileNum
                }}</span>
                <span
                  v-if="item.fileType === 2 && modelType === 0"
                  class="imgupload-proportion"
                  >{{ item.width }} * {{ item.height }}</span
                >
                <span
                  v-if="item.fileType === 2 && modelType === 1 && item.duration"
                  class="imgupload-proportion"
                  >{{ item.duration }}</span
                >
                <template
                  v-if="handleIsChecked(item.id) && item.fileType === 2"
                >
                  <el-checkbox
                    class="imgupload-checked"
                    :checked="true"
                    size="large"
                    :disabled="
                      checkList.length === num && !handleIsChecked(item.id)
                    "
                    @change="e => handleChecked(e, item)"
                  />
                </template>
                <template
                  v-if="!handleIsChecked(item.id) && item.fileType === 2"
                >
                  <el-checkbox
                    class="imgupload-checked"
                    :checked="false"
                    size="large"
                    :disabled="
                      checkList.length === num && !handleIsChecked(item.id)
                    "
                    @change="e => handleChecked(e, item)"
                  />
                </template>
              </div>
              <div class="imgupload-middle">
                <div class="imgupload-news_info">
                  <div>
                    <p class="imgupload-title" v-if="item.fileType === 2">
                      {{ item.pathName }}
                    </p>
                    <p class="ellipsis_2 mt-1 heightnormal">
                      <span v-html="item.fileName" />
                    </p>
                  </div>
                  <p class="imgupload-date imgupload-search">
                    <span>{{
                      dayjs(item.createTime).format("YYYY/MM/DD HH:mm")
                    }}</span>
                    <span>{{ maxlength(item.createUser) }}</span>
                  </p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <InfiniteLoading
        :target="`#${fallId}`"
        @infinite="load"
        :identifier="identifier"
        :distance="200"
      >
        <template #spinner>
          <div
            class="pdf-upload"
            :style="{ height: searchForm.pageNum === 1 ? '500px' : 'auto' }"
          >
            <img src="../../img/icon_loading.png" />
          </div>
        </template>
        <template #complete>
          <div class="infinit-loaded" :class="{ line: resList.length > 0 }">
            <div v-if="resList.length <= 0">
              <slot name="empty">
                <div class="empty">
                  <p v-if="!searchParams.searchKey && breadList.length < 2">
                    <img src="../../img/pic_search.png" />
                    <span>上方搜索框进行搜索</span>
                  </p>
                  <p v-else>
                    <img src="../../img/pic_empty.png" />
                    <span>暂无搜索内容</span>
                  </p>
                </div>
              </slot>
            </div>
            <p v-else class="empty-text">没有更多了</p>
          </div>
        </template>
        <template #error="{ retry }">
          <div class="infinit-error">
            <span>加载异常</span>
            <el-button @click="retry">重试</el-button>
          </div>
        </template>
      </InfiniteLoading>
      <VideoPreview
        v-model:visible="videoMaskVisible"
        :url="videoUrl"
        @handleClose="handleClose"
      />
    </div>

    <previewImg v-model:visible="previewImgVisible" :src="previewImgUrl" />
  </div>
</template>

<script setup>
import { ref, nextTick, watch, computed, onMounted } from "vue";
import dayjs from "dayjs";
import InfiniteLoading from "v3-infinite-loading";

import { ArrowRight } from "@element-plus/icons-vue";

import { getBrowseList } from "@/api/upload";

import VideoPreview from "../../../VideoPreview";
import previewImg from "@/components/Editor/previewImg.vue";

import "v3-infinite-loading/lib/style.css";

const props = defineProps({
  fallId: {
    type: String,
    default: ""
  },
  checkList: {
    type: Array,
    default: () => {
      return {};
    }
  },
  modelType: {
    type: Number,
    default: 0
  },
  searchParams: {
    type: Object,
    default: () => {
      return {};
    }
  },
  num: {
    type: Number,
    default: 0
  },
  parentId: {
    type: String,
    default: null
  },
  parentFileName: {
    type: String,
    default: ""
  },
  isLoad: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(["handleSetCheckList"]);

const resList = ref([]);

const breadList = ref([]);

const videoMaskVisible = ref(false);

const videoUrl = ref("");

const previewImgVisible = ref(false);
const previewImgUrl = ref("");

//  勾选
const checkList = ref(props.checkList || []);

//  加载更多
const isLoading = ref(false);

const identifier = ref(new Date().getTime());

const searchForm = ref({
  mediaType: 1,
  orderType: 1,
  pageNum: 1,
  pageSize: 10,
  searchKey: "",
  scope: "",
  contId: undefined
});

watch(
  () => props.isLoad,
  newVal => {
    console.log(newVal, "🐷🍓🐷");
    if (newVal) {
      searchForm.value.pageNum = 1;

      if (props.parentId) {
        console.log(props.parentFileName, "🐷🐷🐷🐷🐷🐷🐷", props.parentId);
        goEnter({ id: props.parentId, fileName: props.parentFileName });
      }
      load();
    }
  }
);
onMounted(() => {
  //  fallId === "team" ? "企业库" : modelType === 1 ? "稿件视频" : "个人库"
  if (props.fallId === "team" || props.fallId === "self") {
    breadList.value.push({
      id: "",
      name:
        props.fallId === "team"
          ? "企业库"
          : props.modelType === 1
          ? "稿件视频"
          : "个人库"
    });
  }
});

onMounted(() => {
  console.log(props.searchParams, "🎄🎄🎄🎄🎄");
  searchForm.value = {
    ...searchForm.value,
    ...props.searchParams
    // parentId: props.parentId
  };
  if (props.parentId) {
    console.log(props.parentFileName, "🐷🐷🐷🐷🐷🐷🐷", props.parentId);
    goEnter({ id: props.parentId, fileName: props.parentFileName });
  }
});

const onPreviewImg = item => {
  console.log(item, "item");
  previewImgVisible.value = true;
  previewImgUrl.value = item.originSrc;
};

const handleClose = () => {
  videoMaskVisible.value = false;
};

const load = async $state => {
  if (isLoading.value) return;
  let params = { ...searchForm.value };

  try {
    isLoading.value = true;
    // const fn = props.modelType === 1 ? listFile : ApiGallerySearch;
    const res = await getBrowseList({ ...params });
    if (res.code === 200) {
      res.data.page.list = (res.data.page.list || []).map(item => {
        return {
          ...item
        };
      });
      if (params.pageNum === 1) {
        // resList.value = res.data.page.list;
        resList.value.push(...res.data.page.list);
      } else {
        resList.value.push(...res.data.page.list);
      }
      if (!res.data.page.hasNext) {
        nextTick(() => {
          $state && $state.complete();
        });
      } else {
        nextTick(() => {
          $state && $state.loaded();
        });
      }
      searchForm.value.pageNum = searchForm.value.pageNum + 1;
    }
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    $state.error();
  }
};

const handlePreviewVideo = originSrc => {
  videoMaskVisible.value = true;
  videoUrl.value = originSrc;
};

const goEnter = d => {
  resList.value = [];
  searchForm.value.parentId = d.id;
  searchForm.value.pageNum = 1;
  identifier.value = new Date().getTime();
  breadList.value.push({
    id: d.id,
    name: d.fileName
  });
  console.log(breadList.value, "🎄🎄🍎🎄🎄");
};

const handleChecked = (e, item) => {
  if (e) {
    checkList.value.push(item);
  } else {
    let index = checkList.value.findIndex(check => check.id === item.id);
    checkList.value.splice(index, 1);
  }
  nextTick(() => {
    emits("handleSetCheckList", checkList.value);
  });
};

const onItemClick = item => {
  if (item.fileType !== 2) {
    goEnter(item);
    return;
  }

  if (checkList.value.some(sitem => item.id === sitem.id)) {
    let index = checkList.value.findIndex(check => check.id === item.id);
    checkList.value.splice(index, 1);
  } else {
    checkList.value.push(item);
  }

  emits("handleSetCheckList", checkList.value);
};

const handleIsChecked = computed(() => {
  return id => {
    return checkList.value.some(item => item.id === id);
  };
});

const handleToBack = (id, idx) => {
  if (idx === breadList.value.length - 1) return;
  if (!id) {
    delete searchForm.value.parentId;
    breadList.value = breadList.value.slice(0, idx + 1);
  } else {
    searchForm.value.parentId = id;
    breadList.value = breadList.value.slice(0, idx + 1);
  }

  searchForm.value = {
    ...searchForm.value,
    pageNum: 1,
    pageSize: 10
  };
  nextTick(() => {
    resList.value = [];
    identifier.value = new Date().getTime();
  });
};

const maxlength = name => {
  if (name && name.length > 4) {
    return name.slice(0, 4) + "...";
  }
  return name;
};

//  往当前的数组里面添加新增的
const setNewFileToFirst = item => {
  if (resList.value.length) {
    resList.value.unshift(item);
  }
};

watch(
  () => props.checkList,
  newVal => {
    checkList.value = newVal;
  },
  {
    deep: true
  }
);

watch(
  () => props.searchParams,
  newVal => {
    if (newVal.pageNum === 1) {
      resList.value = [];
      identifier.value = new Date().getTime();
      breadList.value = breadList.value.slice(0, 1);
    }

    const req = {
      ...searchForm.value,
      ...newVal
    };

    if (props.fallId !== "content") {
      Reflect.deleteProperty(req, "contId");
    }

    searchForm.value = {
      ...req
    };
  },
  {
    deep: true
  }
);
defineExpose({
  setNewFileToFirst: setNewFileToFirst
});
</script>

<style lang="scss" scoped>
.waterfall {
  height: 420px;
  padding: 0 20px;
  overflow-y: auto;

  p {
    margin: 0;
    padding: 0;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background-color: rgba(50, 50, 50, 0.3);
  }

  &::-webkit-scrollbar-track {
    border-radius: 1em;
    background-color: rgba(50, 50, 50, 0.1);
  }
}

.infinit-loading {
  width: 30px;
  height: 30px;
  border: 2px solid var(--el-color-primary);
  border-top-color: transparent;
  border-radius: 100%;
  margin: 0 auto;

  animation: circle infinite 0.75s linear;
}

.infinit-loaded {
  text-align: center;
  padding-top: 15px;
  font-size: 14px;
  color: #999999;

  &.line {
    //border-top: 1px solid #f2f2f2;
  }

  .empty {
    margin-top: 120px;
    padding: 0 0 15px 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #999;

    img {
      width: 120px;
      height: 120px;
    }
  }

  .empty-text {
    padding: 0 0 15px 0 !important;
  }
}

.pdf-upload {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  img {
    margin-bottom: 20px;
    width: 36px;
    height: 36px;
    animation: spin 3s linear infinite;
  }
}

.bread-active {
  :deep(.el-breadcrumb__inner) {
    color: #409eff;

    &:hover {
      color: #409eff;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.imgupload-recommend {
  color: #333;
  margin-bottom: 10px !important;
  font-size: 14px;
  line-height: 21px;

  span {
    color: #409eff;
  }

  &-video {
    padding: 0 0 10px 0 !important;

    span {
      cursor: pointer;
    }
  }
}

.imgupload-list {
  width: 100%;

  p {
    line-height: 18px;
  }

  .imgupload-item {
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    margin-bottom: 10px;
    cursor: pointer;

    .imgupload-img {
      position: relative;
      height: 122px;
      display: flex;
      align-items: center;
      justify-content: center;

      .preview_dom {
        position: absolute;
        left: 9px;
        top: 94px;
        width: 54px;
        height: 20px;
        background: rgba(51, 51, 51, 0.7);
        border-radius: 1px;

        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        display: none;

        img {
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
      }

      .imgupload-fold-bg {
        background: #d9ecff;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .imgupload-audio-bg {
        background: #f8f8f8;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 56px;
        height: 56px;
      }

      :deep(.el-checkbox.el-checkbox--large .el-checkbox__inner) {
        width: 18px;
        height: 18px;
      }

      :deep(.el-checkbox__inner::after) {
        left: 6px;
        top: 3px;
      }

      :deep(.el-image) {
        width: 100%;

        img {
          width: 100% !important;
        }
      }

      // :deep(.el-image__preview) {
      //   height: 122px;
      //   width: 100% !important;
      //   object-fit: cover;
      //   cursor: zoom-in;
      // }

      .imgupload-video-img {
        position: relative;
        cursor: pointer;
        width: 100%;

        img {
          width: 100%;
          height: 122px;
          object-fit: cover;
        }

        .imgupload-video-img-icon {
          background: url("../../img/icon_play.png") no-repeat;
          background-size: cover;
          width: 36px;
          height: 36px;
          display: none;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
        }

        &:hover {
          .imgupload-video-img-icon {
            display: block;
          }
        }
      }

      .imgupload-file-num {
        background: url("../../img/icon_folder.png") no-repeat 5px center #fff;
        background-size: 12px 12px;
        padding: 0px 5px 0px 20px;
        color: #409eff;
        position: absolute;
        right: 8px;
        bottom: 8px;
        font-size: 12px;
        border-radius: 1px;
        line-height: 18px;
      }

      .imgupload-proportion {
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        padding: 2px 4px;
        color: #fff;
        font-size: 12px;
        right: 8px;
        bottom: 8px;
      }

      .imgupload-checked {
        position: absolute;
        right: 10px;
        top: 10px;
        height: auto;

        :deep(.el-checkbox__input) {
          width: 18px; /* 设置宽度 */
          height: 18px; /* 设置高度 */
        }
      }
    }

    &:hover {
      .preview_dom {
        display: inline-flex;
      }
    }

    .imgupload-middle {
      padding: 10px;

      .imgupload-title {
        color: #999;
        font-size: 14px;
        margin-bottom: 5px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .imgupload-news_info {
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        :deep(.highlight) {
          color: #409eff;
        }
      }

      .heightnormal {
        height: 38px;
        margin-bottom: 10px;
        color: #333;
        font-size: 14px;
      }

      .imgupload-date {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        margin-top: 5px;
        color: #999;
      }
    }
  }
}

.infinit-error {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  button {
    margin-top: 10px;
    width: 100px;
  }
}
</style>
