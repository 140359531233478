import type { RouteConfigsTable } from "/#/index";

const interviewRouter: RouteConfigsTable = {
  path: "/interview_center_root",
  meta: {
    title: "栏目",
    showLink: false
  },
  children: [
    {
      path: "/interview_center_root/:id",
      meta: {
        title: "栏目"
      },
      children: [
        {
          path: "/interview_center_root/:id/:channel_id",
          name: "Interview_center_root",
          component: () => import("@/views/interview_center_root/index.vue"),
          meta: {
            title: "栏目列表",
            showParent: true,
            keepAlive: true
          }
        }
      ]
    }
  ]
};

export default interviewRouter;
