<template>
  <div>
    <el-dialog
      v-model="dialogVisible"
      title="裁剪图片"
      width="776px"
      :before-close="handleClose"
      destroy-on-close
      :close-on-click-modal="false"
    >
      <div>
        <Cropper
          width="737px"
          height="364px"
          :options="{
            viewModeType: 1,
            noAspectRatio: true,
            autoCrop: true,
            autoCropArea: 1,
            aspectRatio: NaN
          }"
          :ref="el => (cropperRef = el)"
          :src="image"
          :getData="getData"
        />
      </div>

      <template #footer>
        <span class="dialog-footer">
          <div class="cropper_info">
            裁切尺寸{{ copperData.width }}*{{ copperData.height }}
          </div>
          <div>
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="submitForm"> 确定 </el-button>
          </div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import Cropper from "@/components/ReCropper";
import Oss from "@/utils/videoOss";

const emits = defineEmits(["update:visible", "singleSuccess"]);
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  editId: {
    type: [Number, String]
  },
  cropperUrl: {
    type: String,
    default: ""
  }
});
const dialogVisible = ref(false);
const currentFile = ref();
const image = ref(props.cropperUrl || "");
const cropperRef = ref();
const copperData: any = ref({});
const handleClose = () => {
  emits("update:visible", false);
};
// @ts-ignore
const oss = new Oss({ type: "business" });

const submitForm = () => {
  console.log(cropperRef.value, "cropperRef.value");
  if (cropperRef.value) {
    cropperRef.value.cropper
      .getCroppedCanvas({
        ...copperData.value,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: "high"
      })
      .toBlob(
        async blob => {
          let fileName = currentFile.value?.name;
          let file = new File([blob], fileName || `${Date.now()}.png`, {
            type: blob.type,
            lastModified: Date.now()
          });

          console.log(file, "file");
          try {
            // @ts-ignore
            oss.uploadFile(file, fileName).then(res => {
              emits("singleSuccess", res);
              handleClose();
            });
          } catch (e) {
            console.log(e);
          }
        },
        "image/jpeg",
        1
      );
  }
};

const getData = cData => {
  copperData.value = {
    width: parseInt(cData.width),
    height: parseInt(cData.height)
  };
};

watch(
  () => props.visible,
  newVal => {
    dialogVisible.value = newVal;
  }
);

watch(
  () => props.cropperUrl,
  newVal => {
    image.value = newVal;
  }
);
</script>

<style scoped lang="scss">
.dialog-footer {
  display: flex;
  justify-content: space-between;

  .cropper_info {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
  }
}
</style>
