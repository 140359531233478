import type { RouteConfigsTable } from "/#/index";

const sysconfigRouter: RouteConfigsTable = {
  path: "/sysconfig",
  redirect: "/sysconfig/menu",
  meta: {
    icon: "edit",
    title: "系统管理",
    showLink: false,
    rank: 3
  },
  children: [
    {
      path: "/menu",
      name: "SysMenu",
      component: () => import("@/views/sysConfig/menu/index.vue"),
      meta: {
        title: "菜单管理",
        keepAlive: true
      }
    },
    {
      path: "/columnArticle",
      name: "SysOnline",
      component: () => import("@/views/sysConfig/columnArticle/index.vue"),
      meta: {
        title: "频道栏目管理",
        keepAlive: true
      }
    },

    {
      path: "/adventure",
      name: "Adventure",
      component: () => import("@/views/contentOperations/adventure/index.vue"),
      meta: {
        title: "APP首页",
        keepAlive: true
      }
    },
    {
      path: "/role",
      name: "SysRole",
      component: () => import("@/views/sysConfig/role/index.vue"),
      meta: {
        title: "角色管理",
        keepAlive: true
      }
    },
    {
      path: "/rolegroup",
      name: "SysRolegroup",
      component: () => import("@/views/sysConfig/roleGroup/index.vue"),
      meta: {
        title: "角色组管理",
        keepAlive: true
      }
    },
    {
      path: "/user",
      name: "SysUser",
      component: () => import("@/views/sysConfig/user/index.vue"),
      meta: {
        title: "账号管理",
        keepAlive: true
      }
    },
    {
      path: "/online",
      name: "Online",
      component: () => import("@/views/sysConfig/online/index.vue"),
      meta: {
        title: "在线账号",
        keepAlive: true
      }
    },
    {
      path: "/columnArticle",
      name: "SysOnline",
      component: () => import("@/views/sysConfig/columnArticle/index.vue"),
      meta: {
        title: "在线账号",
        keepAlive: true
      }
    },

    {
      path: "/articleLog",
      name: "ArticleLog",
      component: () => import("@/views/logs/articleLog/index.vue"),
      meta: {
        title: "评论审核日志",
        keepAlive: true
      }
    },
    {
      path: "/log/loginMenu",
      name: "LoginMenu",
      component: () => import("@/views/logs/loginMenu/index.vue"),
      meta: {
        title: "后台登录日志",
        keepAlive: true
      }
    },
    {
      path: "/log/loginMenu",
      name: "LoginMenu",
      component: () => import("@/views/logs/loginMenu/index.vue"),
      meta: {
        title: "后台登录日志",
        keepAlive: true
      }
    },

    {
      path: "/readOpenCreate/:id",
      name: "ReadOpenCreate",
      component: () =>
        import("@/views/contentOperations/openingCreate/index.vue"),
      meta: {
        title: "新建必读",
        keepAlive: true
      }
    },
    {
      path: "/financeCalendar",
      name: "FinanceCalendar",
      component: () =>
        import("@/views/contentOperations/financeCalendar/index.vue"),
      meta: {
        title: "财经日历",
        keepAlive: true
      }
    },
    {
      path: "/readOpen",
      name: "ReadOpen",
      component: () =>
        import("@/views/contentOperations/openingRead/index.vue"),
      meta: {
        title: "开盘必读",
        keepAlive: true
      }
    },
    {
      path: "/hotWord",
      name: "HotWord",
      component: () => import("@/views/contentOperations/hotWord/index.vue"),
      meta: {
        title: "热搜榜",
        keepAlive: true
      }
    },

    {
      path: "/department",
      name: "Department",
      component: () => import("@/views/sysConfig/department/index.vue"),
      meta: {
        title: "部门管理",
        keepAlive: true
      }
    },

    {
      path: "/statichtml",
      name: "Statichtml",
      component: () => import("@/views/statichtml/index.vue"),
      meta: {
        title: "静态页面",
        showLink: false
      },
      children: [
        {
          path: "/statichtml/add",
          name: "StatichtmlAdd",
          component: () => import("@/views/statichtml/add/index.vue"),
          meta: {
            title: "新增静态页面",
            showLink: false,
            keepAlive: true
          },
          children: [
            {
              path: "/statichtml/add/:id",
              name: "StatichtmlEdit",
              component: () => import("@/views/statichtml/add/index.vue"),
              meta: {
                showLink: false,
                keepAlive: true
              }
            }
          ]
        }
      ]
    },
    {
      path: "/myManuscriptLibrary",
      name: "MyManuscriptLibrary",

      meta: {
        title: "我的稿库",
        showLink: false
      },
      children: [
        {
          path: "/myManuscriptLibrary/edit",
          name: "MyManuscriptLibrary_edit",
          component: () => import("@/views/myManuscriptLibrary/edit/index.vue"),
          meta: {
            title: "编辑稿库",
            showLink: false,
            keepAlive: true
          }
        },
        {
          path: "/myManuscriptLibrary/original",
          name: "MyManuscriptLibrary_original",
          component: () =>
            import("@/views/myManuscriptLibrary/original/index.vue"),
          meta: {
            title: "原创稿库",
            showLink: false,
            keepAlive: true
          }
        },
        {
          path: "/interview",
          name: "MyManuscriptLibrary_interview",
          component: () =>
            import("@/views/myManuscriptLibrary/interview/index.vue"),
          meta: {
            title: "采访中心",
            showLink: false,
            keepAlive: true
          }
        },
        {
          path: "/interview_audit",
          name: "MyManuscriptLibrary_interview_audit",
          component: () =>
            import("@/views/myManuscriptLibrary/interview_audit/index.vue"),
          meta: {
            title: "采访中心经我审核",
            showLink: false,
            keepAlive: true
          }
        },
        {
          path: "/websiteChannel",
          name: "MyManuscriptLibrary_websiteChannel",
          component: () =>
            import("@/views/myManuscriptLibrary/websiteChannel/index.vue"),
          meta: {
            title: "网站频道",
            showLink: false,
            keepAlive: true
          }
        },
        {
          path: "/websiteChannel_audit",
          name: "MyManuscriptLibrary_websiteChannel_audit",
          component: () =>
            import(
              "@/views/myManuscriptLibrary/websiteChannel_audit/index.vue"
            ),
          meta: {
            title: "网站频道经我审核",
            showLink: false,
            keepAlive: true
          }
        },
        {
          path: "/wechat_root",
          name: "MyManuscriptLibrary_wechat_root",
          component: () =>
            import("@/views/myManuscriptLibrary/wechat_root/index.vue"),
          meta: {
            title: "微信文章管理",
            showLink: false,
            keepAlive: true
          }
        },

        {
          path: "/sampleReview",
          name: "MyManuscriptLibrary_sampleReview",
          component: () =>
            import("@/views/myManuscriptLibrary/sampleReview/index.vue"),
          meta: {
            title: "大样审核",
            showLink: false,
            keepAlive: true
          }
        },
        {
          path: "/special_node_totalPoll",
          name: "MyManuscriptLibrary_special_node_totalPoll",
          component: () =>
            import(
              "@/views/myManuscriptLibrary/special_node_totalPoll/index.vue"
            ),
          meta: {
            title: "总库",
            showLink: false,
            keepAlive: true
          }
        },
        {
          path: "/newsletter",
          name: "MyManuscriptLibrary_newsletter",
          component: () =>
            import("@/views/myManuscriptLibrary/newsletter/index.vue"),
          meta: {
            title: "电子报回传稿库",
            showLink: false,
            keepAlive: true
          }
        }
      ]
    },
    {
      path: "/blackAndWhite",
      name: "BlackAndWhite",
      component: () =>
        import("@/views/contentOperations/blackAndWhite/index.vue"),
      meta: {
        title: "一键黑白&飘红",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/spread",
      name: "Spread",
      component: () => import("@/views/contentOperations/spread/index.vue"),
      meta: {
        title: "APP开屏",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/www",
      name: "Www",
      component: () => import("@/views/contentOperations/www/index.vue"),
      meta: {
        title: "www",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/commentList",
      name: "commentList",
      component: () =>
        import("@/views/operationManagement/commentList/index.vue"),
      meta: {
        title: "评论管理",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/feedback",
      name: "Feedback",
      component: () => import("@/views/operationManagement/feedback/index.vue"),
      meta: {
        title: "用户反馈",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/receptionUser",
      name: "ReceptionUser",
      component: () =>
        import("@/views/operationManagement/receptionUser/index.vue"),
      meta: {
        title: "用户管理",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/userSMS",
      name: "userSMS",
      component: () => import("@/views/operationManagement/userSMS/index.vue"),
      meta: {
        title: "短信验证码查看",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/media/individual",
      name: "mediaFile",
      component: () => import("@/views/media/individual/index.vue"),
      meta: {
        title: "个人库",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/media/enterprise",
      name: "mediaFile",
      component: () => import("@/views/media/enterprise/index.vue"),
      meta: {
        title: "企业库",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/media/statistics",
      name: "Statistics",
      component: () => import("@/views/media/statistics/index.vue"),
      meta: {
        title: "数据统计",
        keepAlive: true,
        showLink: false
      }
    },
    {
      path: "/aiVideo",
      name: "aiVideo",
      component: () => import("@/views/aiVideo/index.vue"),
      meta: {
        title: "AI视频",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/aiPoster",
      name: "aiPoster",
      component: () => import("@/views/aiPoster/index.vue"),
      meta: {
        title: "AI海报",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/aiwrite",
      name: "AiWrite",
      component: () => import("@/views/aiwrite/index.vue"),
      meta: {
        title: "AI写作",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/h5create/survey",
      name: "H5Survey",
      component: () => import("@/views/h5create/survey/index.vue"),
      meta: {
        title: "用户问卷",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/h5create/collection",
      name: "H5Collection",
      component: () => import("@/views/h5create/collection/index.vue"),
      meta: {
        title: "内容专题",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/h5create/helper",
      name: "H5Helper",
      component: () => import("@/views/h5create/helper/index.vue"),
      meta: {
        title: "求助平台",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/h5create/vote",
      name: "H5Activity",
      component: () => import("@/views/h5create/vote/index.vue"),
      meta: {
        title: "用户问卷",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/sysconfig/aiVideoManage",
      name: "aiVideoManage",
      component: () => import("@/views/sysConfig/aiVideoManage/index.vue"),
      meta: {
        title: "AI视频管理",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/sysconfig/aiwriteManage",
      name: "aiWriteRecord",
      component: () => import("@/views/sysConfig/aiwriteManage/index.vue"),
      meta: {
        title: "AI写作管理",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/sysconfig/aimanage",
      name: "aimanage",
      component: () => import("@/views/sysConfig/aimanage/index.vue"),
      meta: {
        title: "AI海报后台",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/manuscriptData",
      name: "ManuscriptData",
      component: () => import("@/views/manuscriptData/index.vue"),
      meta: {
        title: "稿件数据",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/sectionManuscriptData",
      name: "SectionManuscriptData",
      component: () => import("@/views/sectionManuscriptData/index.vue"),
      meta: {
        title: "部门稿件数据",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/all_articles",
      name: "All_articles",
      component: () =>
        import("@/views/contentOperations/all_articles/index.vue"),
      meta: {
        title: "稿件管理",
        showLink: false,
        keepAlive: true
      }
    },

    {
      path: "/recycleBin",
      name: "RecycleBin",
      component: () => import("@/views/contentOperations/recycleBin/index.vue"),
      meta: {
        title: "部门稿件数据",
        showLink: false,
        keepAlive: true
      }
    },
    {
      path: "/release/time",
      name: "ReleaseTime",
      component: () =>
        import("@/views/contentOperations/releaseTime/index.vue"),
      meta: {
        title: "发布时间管理",
        keepAlive: true
      }
    },
    {
      path: "/cdnDemo",
      name: "CdnDemo",
      component: () => import("@/views/sysConfig/cdnDemo/index.vue"),
      meta: {
        title: "缓存&CDN刷新",
        keepAlive: false
      }
    }
  ]
};

export default sysconfigRouter;
