import { http } from "../utils/http";
import Axios from "axios";
import { loadEnv } from "@build/index";
import type { ResObj } from "/#/index";

const { VITE_APP_PAI_API } = loadEnv();
const path = "/open/html";
const newLoginPath = "/open/sso/admin";

/** 菜单列表 */
export const resSearch = (data: object) => {
  return http.request<ResObj>("get", `/res/search`, {
    params: data
  });
};

/** 新增菜单 */
export const resCreate = (data: object) => {
  return http.request<ResObj>("post", `/res/create`, {
    data
  });
};

/** 新增菜单 */
export const resUpdateInfo = (data: object) => {
  return http.request<ResObj>("post", `/res/updateInfo`, {
    data
  });
};

/** 删除菜单 */
export const resDelete = (data: object) => {
  return http.request<ResObj>("post", `/res/delete`, {
    data
  });
};

/** 单个菜单详情 */
export const resId = (data: object) => {
  return http.request<ResObj>("get", `/res/id`, {
    params: data
  });
};

/** 栏目列表 */
export const nodeSearch = (data: object) => {
  return http.request<ResObj>("get", `/node/search`, {
    params: data
  });
};

/** 删除栏目 */
export const nodeDelete = (data: object) => {
  return http.request<ResObj>("post", `/node/delete`, {
    data
  });
};

/** 新增栏目 */
export const nodeCreate = (data: object) => {
  return http.request<ResObj>("post", `/node/create`, {
    data
  });
};

/** 编辑栏目 */
export const nodeUpdateInfo = (data: object) => {
  return http.request<ResObj>("post", `/node/updateInfo`, {
    data
  });
};

/** 栏目信息 */
export const nodeId = (data: object) => {
  return http.request<ResObj>("get", `/node/id`, {
    params: data
  });
};

/** 用户列表 */
export const userSearch = (data: object) => {
  return http.request<ResObj>("get", `/user/search`, {
    params: data
  });
};

/** 角色列表 */
export const roleSearch = (data: object) => {
  return http.request<ResObj>("get", `/role/search`, {
    params: data
  });
};

/** 角色删除 */
export const roleDelete = (data: object) => {
  return http.request<ResObj>("post", `/role/delete`, {
    data
  });
};

/** 创建角色 */
export const roleCreate = (data: object) => {
  return http.request<ResObj>("post", `/role/create`, {
    data
  });
};

/** 角色详情 */
export const roleId = (data: object) => {
  return http.request<ResObj>("get", `/role/id`, {
    params: data
  });
};

/** 角色修改 */
export const roleUpdate = (data: object) => {
  return http.request<ResObj>("post", `role/update`, {
    data
  });
};

/** 创建用户 */
export const userCreate = (data: object) => {
  return http.request<ResObj>("post", `/user/create`, {
    data
  });
};

/** 用户详情 */
export const userDetail = (data: object) => {
  return http.request<ResObj>("get", `/user/detail`, {
    params: data
  });
};

/** 编辑用户 */
export const userUpdateInfo = (data: object) => {
  return http.request<ResObj>("post", `/user/updateInfo`, {
    data
  });
};

/** 删除用户 */
export const userDelete = (data: object) => {
  return http.request<ResObj>("post", `/user/delete`, {
    data
  });
};

/** 在线账号 */
export const userGetOnline = (data: object) => {
  return http.request<ResObj>("get", `/user/getOnline`, {
    params: data
  });
};

/** 强制下线 */
export const userForceOffline = (data: object) => {
  return http.request<ResObj>("post", `/user/forceOffline`, {
    data
  });
};

/** 热搜列表 */
export const hotSearchWordsList = (data: object) => {
  return http.request<ResObj>("post", `/hotSearchWords/list`, {
    data
  });
};

/** 真实的热搜词列表 */
export const hotSearchWordsRealList = (data: object) => {
  return http.request<ResObj>("post", `/hotSearchWords/realList`, {
    data
  });
};

/** 热搜操作日志 */
export const recordList = (data: object) => {
  return http.request<ResObj>("post", `/hotSearchWords/record/list`, {
    data
  });
};

/** 保存热搜词 */
export const hotSearchWordsSave = (data: object) => {
  return http.request<ResObj>("post", `/hotSearchWords/save`, {
    data
  });
};

/** 专题列表 */
export const querySpecialList = (data: object) => {
  return http.request<ResObj>("get", `/node/querySpecialList`, {
    params: data
  });
};

/** 保存专题详情 */
export const nodeSaveSpecial = (data: object) => {
  return http.request<ResObj>("post", `/node/saveSpecial`, {
    data
  });
};

/** 查询专题详情 */
export const nodeQuerySpecialInfo = (data: object) => {
  return http.request<ResObj>("post", `/node/querySpecialInfo`, {
    data
  });
};

/** 获取专题分类列表 */
export const querySpecialClassList = (data: object) => {
  return http.request<ResObj>("post", `/node/querySpecialClassList`, {
    data
  });
};

/** 保存专题分类 */
export const saveSpecialClass = (data: object) => {
  return http.request<ResObj>("post", `/node/saveSpecialClass`, {
    data
  });
};
/** 获取活动下的栏口 */
export const queryActivityNodeList = (data: object) => {
  return http.request<ResObj>("post", `/activity/activityNodeList`, {
    data
  });
};
/** 新增活动栏口 */
export const saveActivityNode = (data: object) => {
  return http.request<ResObj>("post", `/activity/createNode`, {
    data
  });
};

/** 删除专题分类 */
export const delSpecialClassById = (data: object) => {
  return http.request<ResObj>("post", `/node/delSpecialClassById`, {
    data
  });
};

/** 专题分类稿件列表查询 */
export const querySepcialContList = (data: object) => {
  return http.request<ResObj>("post", `/nodecont/querySepcialContList`, {
    data
  });
};

/** 文章列表查询 */
export const searchQueryEdit = (data: object) => {
  return http.request<ResObj>("post", `/search/queryEdit`, {
    data
  });
};

/** 链接文章 */
export const nodecontLinkCont = (data: object) => {
  return http.request<ResObj>("post", `/nodecont/linkSpecialCont`, {
    data
  });
};

/** 删除链接文章 */
export const nodecontDeleteCont = (data: object) => {
  return http.request<ResObj>("post", `/nodecont/deleteClassCont`, {
    data
  });
};

/** 删除链接文章 */
export const activityDeleteCont = (data: object) => {
  return http.request<ResObj>("post", `/activity/delProduction`, {
    data
  });
};

/** 头条列表 */
export const getOperationPublishNodeList = (data: object) => {
  return http.request<ResObj>("post", `/contEdit/getOperationPublishNodeList`, {
    data
  });
};

/** 头条生效 */
export const saveOperationPublish = (data: object) => {
  return http.request<ResObj>("post", `/contEdit/saveOperationPublish`, {
    data
  });
};

/** 日志列表 */
export const getOperationPublishAllLog = (data: object) => {
  return http.request<ResObj>("post", `/contEdit/getOperationPublishAllLog`, {
    data
  });
};

/** 插入频道与详情页列表 */
export const getInterleaveList = (data: object) => {
  return http.request<ResObj>("post", `/Interleave/getInterleaveList`, {
    data
  });
};

/** www插入频道与详情页列表 */
export const getWwwInterleaveList = (data: object) => {
  return http.request<ResObj>("post", `/Interleave/getWwwInterleaveList`, {
    data
  });
};

/** 栏目列表 */
export const nodeList = (data: object) => {
  return http.request<ResObj>("get", `/node/nodeList`, {
    params: data
  });
};

/** 栏目列表 */
export const saveInterleave = (data: object) => {
  return http.request<ResObj>("post", `/Interleave/saveInterleave`, {
    data
  });
};

/* WWW保存 */
export const getContCoverUrl = (data: object) => {
  return http.request<ResObj>("post", `/Interleave/getContCoverUrl`, {
    data
  });
};

/* WWW保存 */
export const saveWwwInterleave = (data: object) => {
  return http.request<ResObj>("post", `/Interleave/saveWwwInterleave`, {
    data
  });
};

/** 删除插播宣传位 */
export const deleteInterleave = (data: object) => {
  return http.request<ResObj>("post", `/Interleave/deleteInterleave`, {
    data
  });
};

/** 获取插播宣传位详情 */
export const getInterleaveByid = (data: object) => {
  return http.request<ResObj>("post", `/Interleave/getInterleaveById`, {
    data
  });
};

/** 开屏广告列表 */
export const getPromoPlanList = (data: object) => {
  return http.request<ResObj>("post", `/promoPlan/getPromoPlanList`, {
    data
  });
};

/** 评论列表 */
export const commentSearch = (data: object) => {
  return http.request<ResObj>("get", `/comment/search`, {
    params: data
  });
};

/** 删除评论 */
export const commentDel = (data: object) => {
  return http.request<ResObj>("post", `/comment/del`, {
    data
  });
};

/** 评论状态修改 */
export const commentAudit = (data: object) => {
  return http.request<ResObj>("post", `/comment/audit`, {
    data
  });
};

/** 用户列表 */
export const clientUserList = (data: object) => {
  return http.request<ResObj>("post", `/clientUser/clientUserList`, {
    data
  });
};

/** 创建用户 */
export const addClientUser = (data: object) => {
  return http.request<ResObj>("post", `/clientUser/addClientUser`, {
    data
  });
};

/** 编辑用户 */
export const editClientUser = (data: object) => {
  return http.request<ResObj>("post", `/clientUser/editClientUser`, {
    data
  });
};

/** 审核用户 */
export const batchUpdateUserStatus = (data: object) => {
  return http.request<ResObj>("post", `/clientUser/batchUpdateUserStatus`, {
    data
  });
};

/** 加记者认证 */
export const clientUserUserCert = (data: object) => {
  return http.request<ResObj>("post", `/clientUser/userCert`, {
    data
  });
};

/** 推荐关注 */
export const addRecommendUser = (data: object) => {
  return http.request<ResObj>("post", `/clientUser/addRecommendUser`, {
    data
  });
};

/** 推荐关注列表 */
export const recommendUserList = (data: object) => {
  return http.request<ResObj>("post", `/clientUser/recommendUserList`, {
    data
  });
};

/** 删除用户 */
export const deleteClientUser = (data: object) => {
  return http.request<ResObj>("post", `/clientUser/deleteClientUser`, {
    data
  });
};

/** 用户反馈列表 */
export const getUserFeedbackList = (data: object) => {
  return http.request<ResObj>("post", `/userFeedback/getUserFeedbackList`, {
    data
  });
};

/** 修改反馈类型 */
export const editUserFeedback = (data: object) => {
  return http.request<ResObj>("post", `/userFeedback/editUserFeedback`, {
    data
  });
};

/** 查看图片视频 */
export const getUserFeedbackImageVideo = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/userFeedback/getUserFeedbackImageVideo`,
    {
      data
    }
  );
};

/** 下载用户反馈 */
export const userFeedbackExcel = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/userFeedback/userFeedbackExcel`,
    {
      data
    },
    {
      responseType: "blob"
    }
  );
};

/** 菜单树 */
export const resMenuList = (data: object) => {
  return http.request<ResObj>("get", `/res/menuList`, {
    params: data
  });
};

/** 评论操作日志列表 */
export const commentOptLogSearch = (data: object) => {
  return http.request<ResObj>("get", `/commentOptLog/search`, {
    params: data
  });
};

/** 搜索操作日志 */
export const logSearch = (data: object) => {
  return http.request<ResObj>("post", `/log/search`, {
    data
  });
};

/** 议事厅列表 */
export const govServiceList = (data: object) => {
  return http.request<ResObj>("post", `/govService/list`, {
    data
  });
};

/** 新增开屏广告 */
export const savePromoPlan = (data: object) => {
  return http.request<ResObj>("post", `/promoPlan/savePromoPlan`, {
    data
  });
};

/** 获取开屏广告详情 */
export const getPromoPlanById = (data: object) => {
  return http.request<ResObj>("post", `/promoPlan/getPromoPlanById`, {
    data
  });
};

/** 删除开屏广告 */
export const deletePromoPlan = (data: object) => {
  return http.request<ResObj>("post", `/promoPlan/deletePromoPlan`, {
    data
  });
};

/** 创建服务内容 */
export const govServiceCreate = (data: object) => {
  return http.request<ResObj>("post", `/govService/create`, {
    data
  });
};

/** 删除服务内容 */
export const govServiceDelete = (data: object) => {
  return http.request<ResObj>("post", `/govService/delete`, {
    data
  });
};

/** 保存服务内容 */
export const govServiceSave = (data: object) => {
  return http.request<ResObj>("post", `/govService/save`, {
    data
  });
};

/** 更新服务内容 */
export const govServiceUpdate = (data: object) => {
  return http.request<ResObj>("post", `/govService/update`, {
    data
  });
};

/** 查询议事厅置顶 */
export const getYishitingInteractionList = (data: object) => {
  return http.request<ResObj>("post", `/contEdit/getYishitingInteractionList`, {
    data
  });
};

/** 查询互动类型 */
export const listInteraction = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/listInteraction`, {
    data
  });
};

/** 置顶议事厅配置生效 */
export const saveYishitingInteractionTop = (data: object) => {
  return http.request<ResObj>("post", `/contEdit/saveYishitingInteractionTop`, {
    data
  });
};

/** 热榜列表 */
export const hotcontList = (data: object) => {
  return http.request<ResObj>("get", `/hotcont/list`, {
    params: data
  });
};

/** 屏蔽-取消屏蔽 */
export const hotcontShield = (data: object) => {
  return http.request<ResObj>("post", `/hotcont/shield`, {
    data
  });
};

/** 月刊列表 */
export const monthlyMagazineList = (data: object) => {
  return http.request<ResObj>("post", `/monthlyMagazine/list`, {
    data
  });
};

/** 月刊保存并发布 */
export const monthlyMagazineSaveAndPub = (data: object) => {
  return http.request<ResObj>("post", `/monthlyMagazine/saveAndPub`, {
    data
  });
};

/** 月刊删除 */
export const monthlyMagazineDelete = (data: object) => {
  return http.request<ResObj>("post", `/monthlyMagazine/delete`, {
    data
  });
};

/** 月刊详情 */
export const monthlyMagazineGetById = (data: object) => {
  return http.request<ResObj>("post", `/monthlyMagazine/getById`, {
    data
  });
};

/** 意见审核列表 */
export const draftRegulationOpinionList = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/yishiting/draftRegulationOpinion/list`,
    {
      data
    }
  );
};

/** 意见审核置顶 */
export const draftRegulationOpinionSetTop = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/yishiting/draftRegulationOpinion/setTop`,
    {
      data
    }
  );
};

/** 意见审核回复 */
export const draftRegulationOpinionReply = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/yishiting/draftRegulationOpinion/reply`,
    {
      data
    }
  );
};

/** 意见审核 */
export const draftRegulationOpinionAudit = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/yishiting/draftRegulationOpinion/audit`,
    {
      data
    }
  );
};

/** 查询日签 */
export const dailySignGetPage = (data: object) => {
  return http.request<ResObj>("post", `/dailySign/getPage`, {
    data
  });
};

/** 删除日签 */
export const dailySignDelete = (data: object) => {
  return http.request<ResObj>("post", `/dailySign/delete`, {
    data
  });
};

/** 保存日签 */
export const dailySignSave = (data: object) => {
  return http.request<ResObj>("post", `/dailySign/save`, {
    data
  });
};

/** 人大代表搜索 */
export const yishitingDeputyList = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/deputy/list`, {
    data
  });
};

/** 人大代表保存 */
export const yishitingDeputySave = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/deputy/save`, {
    data
  });
};

/** 议案信息搜索 */
export const yishitingDeputyMotionList = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/deputyMotion/list`, {
    data
  });
};

/** 议案信息保存 */
export const yishitingDeputyMotionSave = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/deputyMotion/save`, {
    data
  });
};

/** 议案信息获取 */
export const yishitingDeputyMotionGetById = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/deputyMotion/getById`, {
    data
  });
};

/** 议案删除 */
export const yishitingDeputyMotionDeleteByIds = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/deputyMotion/deleteByIds`, {
    data
  });
};

/** 建议信息搜索 */
export const yishitingDeputyProposalList = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/deputyProposal/list`, {
    data
  });
};

/** 建议信息删除 */
export const yishitingDeputyProposalDeleteByIds = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/deputyProposal/deleteByIds`, {
    data
  });
};

/** 建议信息获取 */
export const yishitingDeputyProposalGetById = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/deputyProposal/getById`, {
    data
  });
};

/** 建议信息保存 */
export const yishitingDeputyProposalSave = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/deputyProposal/save`, {
    data
  });
};

/** 说明列表 */
export const audienceNoticeList = (data: object) => {
  return http.request<ResObj>("post", `/audienceNotice/list`, {
    data
  });
};

/** 市民旁听说明保存 */
export const audienceNoticeSave = (data: object) => {
  return http.request<ResObj>("post", `/audienceNotice/save`, {
    data
  });
};

/** 市民旁听说明详情 */
export const audienceNoticeGetById = (data: object) => {
  return http.request<ResObj>("post", `/audienceNotice/getById`, {
    data
  });
};

/** 市民旁听说明保存并发布 */
export const audienceNoticeSavePub = (data: object) => {
  return http.request<ResObj>("post", `/audienceNotice/savePub`, {
    data
  });
};

/** 市民旁听说明发布 */
export const audienceNoticePublish = (data: object) => {
  return http.request<ResObj>("post", `/audienceNotice/publish`, {
    data
  });
};

/** 市民旁听说明删除 */
export const audienceNoticeDelete = (data: object) => {
  return http.request<ResObj>("post", `/audienceNotice/delete`, {
    data
  });
};

/** 申请审核列表 */
export const audienceApplyList = (data: object) => {
  return http.request<ResObj>("post", `/audienceApply/list`, {
    data
  });
};

/** 市民旁听报名申请审核 */
export const audienceApplyAudit = (data: object) => {
  return http.request<ResObj>("post", `/audienceApply/audit`, {
    data
  });
};

/** 市民旁听报名申请详情 */
export const audienceApplyGetById = (data: object) => {
  return http.request<ResObj>("post", `/audienceApply/getById`, {
    data
  });
};

/** 短信内容预览 */
export const audienceApplyPreviewSms = (data: object) => {
  return http.request<ResObj>("post", `/audienceApply/previewSms`, {
    data
  });
};

/** 发送短信 */
export const audienceApplySendSms = (data: object) => {
  return http.request<ResObj>("post", `/audienceApply/sendSms`, {
    data
  });
};

/** 市民建议列表 */
export const suggestionSelectPage = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/yishiting/resident-suggestion/selectPage`,
    {
      data
    }
  );
};

/** 市民建议发布撤回 */
export const suggestionPublish = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/yishiting/resident-suggestion/publish`,
    {
      data
    }
  );
};

/** 金点子取消 */
export const suggestionSetGoldIdea = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/yishiting/resident-suggestion/setGoldIdea`,
    {
      data
    }
  );
};

/** 市民建议回复 */
export const suggestionReply = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/resident-suggestion/reply`, {
    data
  });
};

/** 家站点列表 */
export const liaisonOfficeList = (data: object) => {
  return http.request<ResObj>("post", `/liaisonOffice/list`, {
    data
  });
};

/** 家站点保存 */
export const liaisonOfficeSave = (data: object) => {
  return http.request<ResObj>("post", `/liaisonOffice/save`, {
    data
  });
};

/** 家站点详情 */
export const liaisonOfficeGetById = (data: object) => {
  return http.request<ResObj>("post", `/liaisonOffice/getById`, {
    data
  });
};

/** 家站点删除 */
export const liaisonOfficeDelete = (data: object) => {
  return http.request<ResObj>("post", `/liaisonOffice/delete`, {
    data
  });
};

/** 基层立法联系点列表 */
export const legisativeOfficeList = (data: object) => {
  return http.request<ResObj>("post", `/legisativeOffice/list`, {
    data
  });
};

/** 基层立法联系点保存 */
export const legisativeOfficeSave = (data: object) => {
  return http.request<ResObj>("post", `/legisativeOffice/save`, {
    data
  });
};

/** 基层立法联系点详情 */
export const legisativeOfficeGetById = (data: object) => {
  return http.request<ResObj>("post", `/legisativeOffice/getById`, {
    data
  });
};

/** 基层立法联系点删除 */
export const legisativeOfficeDelete = (data: object) => {
  return http.request<ResObj>("post", `/legisativeOffice/delete`, {
    data
  });
};

/** 草案信息搜索接口 */
export const yishitingDraftRegulationList = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/draftRegulation/list`, {
    data
  });
};

/** 草案信息保存 */
export const yishitingDraftRegulationSave = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/draftRegulation/save`, {
    data
  });
};

/** 草案信息删除 */
export const yishitingDraftRegulationDeleteByIds = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/yishiting/draftRegulation/deleteByIds`,
    {
      data
    }
  );
};

/** 草案建议信息获取 */
export const yishitingDraftRegulationGetById = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/draftRegulation/getById`, {
    data
  });
};

/** 基层立法联系点批量录入读取文件 */
export const legisativeOfficeReadExcel = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/legisativeOffice/readExcel`,
    {
      data
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        token: localStorage.getItem("token")
      }
    }
  );
};

/** 基层立法联系点批量录入保存 */
export const legisativeOfficeSaveBatch = (data: object) => {
  return http.request<ResObj>("post", `/legisativeOffice/saveBatch`, {
    data
  });
};

/** 基层立法联系点模板下载地址 */
export const legisativeOfficeGetDownUrl = (data: object) => {
  return http.request<ResObj>("post", `/legisativeOffice/getDownUrl`, {
    data
  });
};

/** 家站点批量录入读取文件 */
export const liaisonOfficeReadExcel = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/liaisonOffice/readExcel`,
    {
      data
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        token: localStorage.getItem("token")
      }
    }
  );
};

/** 家站点批量保存 */
export const liaisonOfficeSaveBatch = (data: object) => {
  return http.request<ResObj>("post", `/liaisonOffice/saveBatch`, {
    data
  });
};

/** 家站点模板下载地址 */
export const liaisonOfficeGetDownUrl = (data: object) => {
  return http.request<ResObj>("post", `/liaisonOffice/getDownUrl`, {
    data
  });
};

/** 人大代表信息录入读取文件 */
export const yishitingDeputyReadExcel = (data: object) => {
  return http.request<ResObj>(
    "post",
    `/yishiting/deputy/readExcel`,
    {
      data
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        token: localStorage.getItem("token")
      }
    }
  );
};

/** 人大代表信息批量保存 */
export const yishitingDeputySaveBatch = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/deputy/saveBatch`, {
    data
  });
};

/** 人大代表信息删除 */
export const yishitingDeputyDelete = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/deputy/delete`, {
    data
  });
};

/** 人大代表信息获取 */
export const yishitingDeputyGetById = (data: object) => {
  return http.request<ResObj>("post", `/yishiting/deputy/getById`, {
    data
  });
};

/** 关联人大号文章 */
export const contentRelateUserCont = (data: object) => {
  return http.request<ResObj>("post", `/content/relateUserCont`, {
    data
  });
};

/** 关联人大号文章 */
export const searchQueryByDeputyId = (data: object) => {
  return http.request<ResObj>("post", `/search/queryByDeputyId`, {
    data
  });
};

/** 取消关联人大号文章 */
export const contentCancelRelateUserCont = (data: object) => {
  return http.request<ResObj>("post", `/content/cancelRelateUserCont`, {
    data
  });
};

/** 统计列表 */
export const statList = (data: object) => {
  return http.request<ResObj>("post", `/stat/list`, {
    data
  });
};

/** 统计明细列表 */
export const statDetailList = (data: object) => {
  return http.request<ResObj>("post", `/stat/detailList`, {
    data
  });
};

/** 统计明细列表 */
export const statExcel = (data: object) => {
  return http.request<ResObj>("get", `/stat/excel`, {
    params: data
  });
};

/** 静态资源列表 */
export const staticresList = (data: object) => {
  return http.request<ResObj>("post", `/staticres/list`, {
    data
  });
};

/** 静态资源保存或修改 */
export const staticresSaveStaticRes = (data: object) => {
  return http.request<ResObj>("post", `/staticres/saveStaticRes`, {
    data
  });
};

/** 查询黑白模式列表 */
export const cmsMonochromeSelect = (data: object) => {
  return http.request<ResObj>("post", `/cms/monochrome/select`, {
    data
  });
};

/** 黑白模式保存 */
export const cmsMonochromeSave = (data: object) => {
  return http.request<ResObj>("post", `/cms/monochrome/save`, {
    data
  });
};

/** 黑白模式删除 */
export const cmsMonochromeRemove = (data: object) => {
  return http.request<ResObj>("post", `/cms/monochrome/remove`, {
    data
  });
};

/** 删除封面图 */
export const fileDeleteContentCover = (data: object) => {
  return http.request<ResObj>("post", `/file/deleteContentCover`, {
    data
  });
};

/** 人大号子栏目列表 */
export const nodeChildNodeList = (data: object) => {
  return http.request<ResObj>("get", `/node/childNodeList`, {
    params: data
  });
};

/** 获取代表列表 */
export const clientUserGetDeputyList = (data: object) => {
  return http.request<ResObj>("post", `/clientUser/getDeputyList`, {
    data
  });
};

/** 文章操作记录 */
export const contentRecordList = (data: object) => {
  return http.request<ResObj>("post", `/content/record/list`, {
    data
  });
};

/** 获取稿件统计列表 */
export const selectContentVisit = (data: object) => {
  return http.request<ResObj>("post", `/stat/contentStatList`, {
    data
  });
};

/** 数通人大查询 */
export const workbenchSelectPage = (data: object) => {
  return http.request<ResObj>("post", `/workbench/selectPage`, {
    data
  });
};

/** 数通人大保存 */
export const workbenchSaveOrUpdate = (data: object) => {
  return http.request<ResObj>("post", `/workbench/saveOrUpdate`, {
    data
  });
};

/** 数通人大置顶 */
export const workbenchSetTop = (data: object) => {
  return http.request<ResObj>("post", `/workbench/setTop`, {
    data
  });
};

/** 数通人大删除 */
export const workbenchRemove = (data: object) => {
  return http.request<ResObj>("post", `/workbench/remove`, {
    data
  });
};

/** 数通人大上移下移 */
export const workbenchSort = (data: object) => {
  return http.request<ResObj>("post", `/workbench/sort`, {
    data
  });
};

/** 旁听申请导出 */
export const audienceApplyExcel = (data: object) => {
  return http.request<ResObj>(
    "get",
    `/audienceApply/excel`,
    {
      params: data
    },
    {
      responseType: "blob"
    }
  );
};

/** 用户短信查询 */
export const smsSelectPage = (data: object) => {
  return http.request<ResObj>("post", `/sms/selectPage`, {
    data
  });
};

/** 获取外链地址 */
export const resGetOutLinkUrl = (data: object) => {
  return http.request<ResObj>("get", `/res/getOutLinkUrl`, {
    params: data
  });
};

/** 稿件回收站 */
export const nodeContTrashList = (data: object) => {
  return http.request<ResObj>("post", `/nodeContTrash/list`, {
    data
  });
};

/** 稿件回收站删除 */
export const nodeContTrashDelete = (data: object) => {
  return http.request<ResObj>("post", `/nodeContTrash/delete`, {
    data
  });
};

/** 稿件回收站还原 */
export const nodeContTrashRepublish = (data: object) => {
  return http.request<ResObj>("post", `/nodeContTrash/republish`, {
    data
  });
};

/** 绑定人大号 */
export const clientUserBindRdh = (data: object) => {
  return http.request<ResObj>("post", `/clientUser/bindRdh`, {
    data
  });
};

/** 解绑人大号 */
export const clientUserUnBindRdh = (data: object) => {
  return http.request<ResObj>("post", `/clientUser/unBindRdh`, {
    data
  });
};

/** 文件列表 */
export const ossFileList = (data: object) => {
  return http.request<ResObj>("get", `/ossFile/list`, {
    params: data
  });
};

/** 文件管理删除 */
export const ossFileDel = (data: object) => {
  return http.request<ResObj>("post", `/ossFile/del`, {
    data
  });
};
/** 保存或修改TAB */
export const tabSaveOrUpdate = (data: object) => {
  return http.request<ResObj>("post", `/workbenchTab/saveOrUpdate`, {
    data
  });
};
/** 获取所有TAB */
export const getAllTab = (data: object) => {
  return http.request<ResObj>("post", `/workbenchTab/getAllTab`, {
    data
  });
};
/** 删除TAB */
export const removeTab = (data: object) => {
  return http.request<ResObj>("post", `/workbenchTab/remove`, {
    data
  });
};

/** 移动TAB */
export const workbenchTabSort = (data: object) => {
  return http.request<ResObj>("post", `/workbenchTab/sort`, {
    data
  });
};
/** 置顶TAB */
export const workbenchTabSetTop = (data: object) => {
  return http.request<ResObj>("post", `/workbenchTab/setTop`, {
    data
  });
};
/** 活动栏口下的稿件或作品列表 */
export const activityContNodeList = (data: object) => {
  return http.request<ResObj>("post", `/activity/activityContNodeList`, {
    data
  });
};
/** 活动稿件或作品列表 */
export const activityContList = (data: object) => {
  return http.request<ResObj>("post", `/activity/activityContList`, {
    data
  });
};
/** 保存活动栏口数据 */
export const saveActivityCont = (data: object) => {
  return http.request<ResObj>("post", `/activity/saveActivityCont`, {
    data
  });
};

/** 插播设置 日志 */
export const interleaveLogList = (data: object) => {
  return http.request<ResObj>("post", `/Interleave/interleaveLogList`, {
    data
  });
};

/** www设置 日志 */
export const widgetFormwwInterleaveLogList = (data: object) => {
  return http.request<ResObj>("post", `/Interleave/interleaveWwwLogList`, {
    data
  });
};

/** 创建侧边栏 */
export const promoCreate = (data: object) => {
  return http.request<ResObj>("post", `/pc/promo/create`, {
    data
  });
};

/** 删除侧边栏 */
export const promoDelete = (data: object) => {
  return http.request<ResObj>("post", `/pc/promo/delete`, {
    data
  });
};

/** 获取侧边栏数据*/
export const promoListPc = (data: object) => {
  return http.request<ResObj>("post", `/pc/promo/listPc`, {
    data
  });
};

/** 获取位置列表*/
export const promoListPos = (data: object) => {
  return http.request<ResObj>("post", `/pc/promo/listPos`, {
    data
  });
};

/** 上移下移*/
export const promoChangePos = (data: object) => {
  return http.request<ResObj>("post", `/pc/promo/changePos`, {
    data
  });
};

/** 根据Id获取侧边栏*/
export const promoGetById = (data: object) => {
  return http.request<ResObj>("post", `/pc/promo/getById`, {
    data
  });
};
/** 部门列表 */
export const deptList = (data: object) => {
  return http.request<ResObj>("post", `/group/list`, {
    data
  });
};
/** 创建部门 */
export const deptCreate = (data: object) => {
  return http.request<ResObj>("post", `/dept/create`, {
    data
  });
};
/** 更新部门信息 */
export const deptUpdate = (data: object) => {
  return http.request<ResObj>("post", `/dept/update`, {
    data
  });
};
/** 删除部门 */
export const delDept = (data: object) => {
  return http.request<ResObj>("post", `/dept/delDept`, {
    data
  });
};
/** 成员列表 */
export const memberList = (data: object) => {
  return http.request<ResObj>("post", `/dept/memberList`, {
    data
  });
};
/** 添加成员 */
export const addMember = (data: object) => {
  return http.request<ResObj>("post", `/dept/addMember`, {
    data
  });
};
/** 作品分页列表 */
export const productionList = (data: object) => {
  return http.request<ResObj>("post", `/activity/productionList`, {
    data
  });
};
/** 作品审核 */
export const auditProduction = (data: object) => {
  return http.request<ResObj>("post", `/activity/auditProduction`, {
    data
  });
};
/** 图库列表 */
export const gralleryList = (data: object) => {
  return http.request<ResObj>("post", `/grallery/list`, {
    data
  });
};
/** 获取活动下的富文本 */
export const getActivityContentText = (data: object) => {
  return http.request<ResObj>("post", `/activity/activityContentText`, {
    data
  });
};
/** 成员启用禁用 */
export const memberEnabled = (data: object) => {
  return http.request<ResObj>("post", `/dept/memberEnabled`, {
    data
  });
};
/** 删除活动下的作品 */
export const delProduction = (data: object) => {
  return http.request<ResObj>("post", `/activity/delProduction`, {
    data
  });
};
/** 获取频道栏目列表 */
export const channelNodeList = (data: object) => {
  return http.request<ResObj>("post", `/channelNode/list`, {
    data
  });
};
/** 删除频道 */
export const channelNodeDelete = (data: object) => {
  return http.request<ResObj>("post", `/channelNode/delete`, {
    data
  });
};
/** 发布-取消发布 */
export const channelNodePublish = (data: object) => {
  return http.request<ResObj>("post", `/channelNode/publish`, {
    data
  });
};
/** 清除缓存 */
export const clearCache = (data: object) => {
  return http.request<ResObj>("post", `/invalidate/clearCache`, {
    data
  });
};
/** 删除活动栏口 */
export const delNode = (data: object) => {
  return http.request<ResObj>("post", `/activity/delNode`, {
    data
  });
};
/** 获取部门统计数据 */
export const deptContentStatList = (data: object) => {
  return http.request<ResObj>("post", `/stat/deptContentStatList`, {
    data
  });
};
/**获取部门下用户的统计数据  */
export const deptUserContentStatList = (data: object) => {
  return http.request<ResObj>("post", `/stat/deptUserContentStatList`, {
    data
  });
};

/** 下载统计数据 */
export const downloadStat = (data: object) => {
  return Axios.post(`${VITE_APP_PAI_API}stat/download`, data, {
    headers: {
      token: localStorage.getItem("token")
    },
    responseType: "blob"
  }).then((result: any) => {
    console.log(result, "dresult");
    let tempName = result.headers["content-disposition"]
      .split(";")[1]
      .split("filename=")[1];
    tempName = decodeURI(tempName);
    const url = window.URL.createObjectURL(result.data);
    // 生成一个a标签
    const link = document.createElement("a");
    link.href = url;
    // 生成时间戳
    link.download = tempName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    // const blob = result;
    // const reader = new FileReader();
    // reader.readAsDataURL(blob);
    // reader.onload = e => {
    //   const a: any = document.createElement("a");
    //   let tempName = result.headers["Content-Disposition"]
    //         .split(";")[1]
    //         .split("filename=")[1];
    //       tempName = decodeURI(tempName);

    //   a.download = tempName;
    //   // 后端设置的文件名称在res.headers的 "content-disposition": "form-data; name=\"attachment\"; filename=\"20181211191944.zip\"",
    //   a.href = e.target.result;
    //   document.body.appendChild(a);
    //   a.click();
    //   document.body.removeChild(a);
    // };
  });
};
// 获取频道栏目列表
export const getNodeList = (data: object) => {
  return http.request<ResObj>("post", `/node/list`, {
    data
  });
};

// 创建财经日历
export const calendarCreate = (data: object) => {
  return http.request<ResObj>("post", `/finance/calendar/create`, {
    data
  });
};
// 删除财经日历
export const calendarDel = (data: object) => {
  return http.request<ResObj>("post", `/finance/calendar/del`, {
    data
  });
};
// 获取财经日历详情页
export const calendarId = (data: object) => {
  return http.request<ResObj>("post", `/finance/calendar/id`, {
    data
  });
};
// 发布财经日历
export const calendarPublish = (data: object) => {
  return http.request<ResObj>("post", `/finance/calendar/publish`, {
    data
  });
};
// 搜索财经日历
export const calendarSearch = (data: object) => {
  return http.request<ResObj>("post", `/finance/calendar/search`, {
    data
  });
};
// 撤回
export const calendarUnPublish = (data: object) => {
  return http.request<ResObj>("post", `/finance/calendar/unPublish`, {
    data
  });
};
// 修改财经日历
export const calendarUpdate = (data: object) => {
  return http.request<ResObj>("post", `/finance/calendar/update`, {
    data
  });
};

// 获取开盘必读Tab列表数据
export const readGetTabInfo = (data: object) => {
  return http.request<ResObj>("post", `/opening/read/getTabInfo`, {
    data
  });
};
// 创建开盘必读
export const readSaveOrUpdate = (data: object) => {
  return http.request<ResObj>("post", `/opening/read/saveOrUpdate`, {
    data
  });
};
// 搜索开盘必读
export const readSearch = (data: object) => {
  return http.request<ResObj>("post", `/opening/read/search`, {
    data
  });
};
// 撤回开盘必读
export const readUnPublish = (data: object) => {
  return http.request<ResObj>("post", `/opening/read/unPublish`, {
    data
  });
};
// 发布开盘必读
export const readPublish = (data: object) => {
  return http.request<ResObj>("post", `/opening/read/publish`, {
    data
  });
};
// 删除开盘必读
export const readDel = (data: object) => {
  return http.request<ResObj>("post", `/opening/read/del`, {
    data
  });
};
// 日期
export const calendarIsSave = (data: object) => {
  return http.request<ResObj>("post", `/finance/calendar/isSave`, {
    data
  });
};
